import { cookies } from 'utils/cookie';
import { TOKEN } from 'utils/constants';
import coumputedApiService from './computedApiService';
import { AuditEventDto, ResourceEventDto } from 'types/analytics';

const analyticsServices = {
  authAnalyticsEvent: (data: AuditEventDto) => {
    const token = cookies.get(TOKEN);
    const url = `/api/v1/audit-analytics/auth-audit`;

    return coumputedApiService(token, true).post(url, data);
  },

  resourceAnalyticsEvent: (data: ResourceEventDto) => {
    const token = cookies.get(TOKEN);
    const url = `/api/v1/audit-analytics/resource-event`;

    return coumputedApiService(token, true).post(url, data);
  },
};

export default analyticsServices;
