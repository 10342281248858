import { IDataCountries } from 'interface/countries.interface';

export class MCountries {
  created_at: Date | undefined;
  id: number | undefined;
  name: string | undefined;
  updated_at: Date | undefined;
  constructor(data: IDataCountries) {
    this.setData(data);
  }
  setData(data: IDataCountries) {
    this.id = data?.id;
    this.created_at = data?.attributes?.createdAt;
    this.name = data?.attributes?.name;
    this.updated_at = data?.attributes?.updatedAt;
  }
}
