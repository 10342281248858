import { IFormats } from 'interface/format.interface';

export class MFormat {
  [x: string]: any;
  constructor(dataFormat?: IFormats) {
    this.setData(dataFormat);
  }
  setData(data?: IFormats) {
    this.ext =
      data?.large?.ext ||
      data?.medium?.ext ||
      data?.small?.ext ||
      data?.thumbnail?.ext;
    this.url =
      data?.large?.url ||
      data?.medium?.url ||
      data?.small?.url ||
      data?.thumbnail?.url;
    this.hash =
      data?.large?.hash ||
      data?.medium?.hash ||
      data?.small?.hash ||
      data?.thumbnail?.hash;
    this.mime =
      data?.large?.mime ||
      data?.medium?.mime ||
      data?.small?.mime ||
      data?.thumbnail?.mime;
    this.name =
      data?.large?.name ||
      data?.medium?.name ||
      data?.small?.name ||
      data?.thumbnail?.name;
    this.path =
      data?.large?.path ||
      data?.medium?.path ||
      data?.small?.path ||
      data?.thumbnail?.path;
    this.size =
      data?.large?.size ||
      data?.medium?.size ||
      data?.small?.size ||
      data?.thumbnail?.size;
    this.width =
      data?.large?.width ||
      data?.medium?.width ||
      data?.small?.width ||
      data?.thumbnail?.width;
    this.height =
      data?.large?.height ||
      data?.medium?.height ||
      data?.small?.height ||
      data?.thumbnail?.height;
  }
}
