import { Auth } from 'aws-amplify';
import AWSAlert from 'components/AWSAlert/AWSAlert';
import InputField from 'components/Field/InputField';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import './Login.scss';
import './RegisterForm.scss';
import { ROUTE } from './routes';

const validationSchema = yup.object().shape({
  code: yup.number().required('Verification is a required field'),
  new_password: yup.string().required('New password is a required field'),
});

const OTP = ({
  onChangeRoute,
  visible,
  username,
}: {
  onChangeRoute: (route: string) => void;
  visible: boolean;
  username: string;
}) => {
  return (
    <Formik
      initialValues={{
        code: '',
        new_password: '',
        errorMessage: {
          heading: 'Error',
          variation: 'error',
          message: '',
        },
        passwordType: 'password',
      }}
      onSubmit={(values, { setSubmitting, setFieldValue }) => {
        const { code, new_password } = values;
        setSubmitting(true);
        setTimeout(() => {
          Auth.forgotPasswordSubmit(username, code, new_password)
            .then((data) => {
              setFieldValue('errorMessage', {
                heading: 'Success',
                variation: 'success',
                message: 'Reset password success',
              });
              setTimeout(() => onChangeRoute(ROUTE.LOGIN), 1000);
            })
            .catch((error) => {
              setFieldValue('errorMessage', {
                variation: 'error',
                message: error?.message,
              });
            })
            .finally(() => {
              setSubmitting(false);
            });
        }, 500);
      }}
      validationSchema={validationSchema}
      validateOnBlur
    >
      {(formikProps) => {
        return (
          <>
            {formikProps.values?.errorMessage?.message && (
              <AWSAlert
                // @ts-ignore
                variation={
                  formikProps.values?.errorMessage.variation || 'error'
                }
                heading={formikProps.values?.errorMessage.heading || 'Error'}
                message={formikProps.values?.errorMessage?.message}
              />
            )}
            <Form>
              <div className={`form-container ${visible ? 'active-form' : ''}`}>
                <h1>Reset password</h1>
                <Field
                  component={InputField}
                  label={`Verification code`}
                  name='code'
                />
                <div style={{ paddingTop: '1.5rem' }} />
                <Field
                  component={InputField}
                  label={`New password`}
                  name='new_password'
                  type={formikProps.values.passwordType}
                  isShowPassword
                  onShowPassword={() =>
                    formikProps.setFieldValue(
                      'passwordType',
                      formikProps.values.passwordType !== 'password'
                        ? 'password'
                        : 'text'
                    )
                  }
                />
                <div style={{ paddingTop: '1.5rem' }} />

                <div className='form-register__text'>
                  <div onClick={() => onChangeRoute(ROUTE.LOGIN)}>
                    <span className='sign-in'>Back to Sign In</span>
                  </div>
                  <div>
                    <button
                      className='sign-in-button'
                      type='submit'
                      disabled={formikProps.isSubmitting}
                    >
                      {formikProps.isSubmitting ? (
                        <div className='loader' />
                      ) : (
                        'Submit'
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};
export default OTP;
