import { Component } from 'react';

export default class ClientRender extends Component {
  state = { isMounted: false };

  componentDidMount() {
    this.setState({ isMounted: true });
  }

  render() {
    const { children } = this.props;
    const { isMounted } = this.state;

    return isMounted ? children : null;
  }
}

export function optimisedImageUrl(
  image: any,
  transformations = '',
  resizeOriginalImage = ''
) {
  const path = image?.formats?.thumbnail?.path
    ? `${image?.formats?.thumbnail?.path}/`
    : '';
  const IMAGEKIT_ENDPOINT = `https://ik.imagekit.io/wigramdev/${path}`;
  let transformImage = `${transformations}/`;
  if (image && image.provider === 'aws-s3') {
    const fileHash = image?.hash;
    const fileExt = image?.ext;
    const fileName = `${fileHash}${fileExt}`;
    if (resizeOriginalImage) {
      return `${IMAGEKIT_ENDPOINT}${transformImage}${fileName}${resizeOriginalImage}`;
    } else {
      return `${IMAGEKIT_ENDPOINT}${transformImage}${fileName}`;
    }
  }
  // for old image imagekit.io
  if (image && image.provider === 'strapi-provider-upload-imagekit') {
    if (resizeOriginalImage) {
      return `${image?.url}${resizeOriginalImage}`;
    } else {
      return image?.formats?.url || image?.url;
    }
  }

  const fileHash = image?.formats?.hash || image?.hash;
  const fileExt = image?.formats?.ext || image?.ext;
  const isFileExisted = Boolean(image && fileHash && fileExt);

  if (isFileExisted) {
    const fileName = `${fileHash}${fileExt}`;
    if (resizeOriginalImage) {
      return `${IMAGEKIT_ENDPOINT}${transformImage}${fileName}${resizeOriginalImage}`;
    }
    return `${IMAGEKIT_ENDPOINT}${transformImage}${fileName}`;
  } else {
    return '';
  }
}
