import authApiService from 'services/authApiService';
import { AxiosResponse } from 'axios';
import urls from 'config/urls';
import { IFilterApplied } from 'interface/filtersApplied.inteface';
import { ONBOARDING } from 'utils/constants';
import { handleAuthSignOut } from 'utils/helper';
export interface IBodyScreenSetting {
  id?: string;
  live_screen_settings: string;
  show_onboarding_guide?: string;
}
const updateScreenSetting = ({
  id,
  groupBy,
  filtersApplied,
  showOnboard,
  onSuccess = (res) => {},
}: {
  id: string;
  groupBy: string;
  filtersApplied: IFilterApplied[];
  showOnboard?: any;
  onSuccess?: (res: AxiosResponse<any, any>) => void;
}) => {
  let body: IBodyScreenSetting = {
    live_screen_settings: JSON.stringify({ filtersApplied, groupBy }),
    show_onboarding_guide: showOnboard,
  };
  if (id) {
    body.id = id;
  }
  authApiService()
    .put(urls?.liveScreenSettings, body)
    .then((res) => {
      if (res?.status === 200) {
        onSuccess(res);
        localStorage.setItem('groupBy', JSON.stringify(groupBy));
        localStorage.setItem('filtersApplied', JSON.stringify(filtersApplied));
        localStorage.setItem(ONBOARDING, showOnboard);
        return;
      }
      alert(`Save Filter Error: ${res?.statusText.toString()}`);
    })
    .catch((error: any) => {
      console.log('Catch updateScreenSetting.', { error });

      if (error?.response?.status !== 400) {
        handleAuthSignOut(error);
      }
    });
};

const getScreenSetting = () => {
  authApiService()
    .get(urls?.liveScreenSettings)
    .then((res) => {
      if (res.status === 200) {
        const onBoardingGuideStrapi = res?.data?.body?.show_onboarding_guide;
        localStorage.setItem(ONBOARDING, onBoardingGuideStrapi);
      }
    })
    .catch((err) => {
      if (err?.response?.status === 404) {
        localStorage.setItem(
          ONBOARDING,
          'Longform,MacroWatches,Live,Countrypack'
        );
      }
    });
};

export { updateScreenSetting, getScreenSetting };
