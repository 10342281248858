import { useEffect, useState } from 'react';
import { addDays } from 'date-fns';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css';
import './DateRangePicker.scss';
import moment from 'moment';

export interface IDateRangePicked {
  startDate: Date;
  endDate: Date;
  key: string;
}

const DateRangePicker = ({
  handleClose,
  onChangedDate = () => {},
  initialTime,
}: {
  handleClose: () => void;
  onChangedDate: (value: IDateRangePicked) => void;
  initialTime: IDateRangePicked;
}) => {
  //! State
  const [state, setState] = useState<IDateRangePicked[]>([
    {
      startDate: new Date(2011, 1, 1),
      endDate: addDays(new Date(2022, 1, 1), 0),
      key: 'selection',
    },
  ]);
  //! Function
  const handleChangeDate = (item: any) => {
    setState(item);
  };

  useEffect(() => {
    setState([initialTime]);
  }, [initialTime]);
  //! Render
  return (
    <div className='dateRange'>
      <div className='dateRange-title'>
        <div className='dateRange-title-startDate'>
          <p className='dateRange-title-startDate-year'>
            {moment(state[0].startDate).format('YYYY')}
          </p>
          <p className='dateRange-title-startDate-date'>
            {moment(state[0].startDate).format('ddd, MMM D')}
          </p>
        </div>
        <strong style={{ position: 'absolute', left: '46%', top: '13%' }}>
          To
        </strong>
        <div className='dateRange-title-endDate'>
          <p className='dateRange-title-endDate-year'>
            {moment(state[0].endDate).format('YYYY')}
          </p>
          <p className='dateRange-title-endDate-date'>
            {moment(state[0].endDate).format('ddd, MMM D')}
          </p>
        </div>
      </div>
      <DateRange
        editableDateInputs={true}
        onChange={(item) => handleChangeDate([item.selection])}
        moveRangeOnFirstSelection={true}
        ranges={state}
        months={2}
        direction='horizontal'
      />
      <div className='dateRange-btn'>
        <button className='dateRange-btn-cal' onClick={handleClose}>
          cancel
        </button>
        <button
          className='dateRange-btn-submit'
          onClick={() => {
            handleClose();
            onChangedDate(state[0]);
          }}
        >
          OK
        </button>
      </div>
    </div>
  );
};

export default DateRangePicker;
