/** @jsxImportSource @emotion/react */
import React from 'react';

import { css } from '@emotion/react/macro';
import { ActiveCardContext } from 'ActiveCardContext';
import IKImageComponent from 'components/IKImage/IKImage';
import { ArticleModel } from 'model/article.model';
import moment from 'moment';
import { useContext, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { optimisedImageUrl } from 'Utility';
import countryName from 'utils/countryName';
import TitleDesc from './TitleDesc';
import './ArticleCard.scss';
import { Analytics, Auth } from 'aws-amplify';
import { resourceAnalytics } from 'utils/analytics';
// import sendEvent from '../../../hooks/useAnalyticsRecord';

// Returns an list of <ArticleCard>'s for a given date & theme (theme)
const ArticleCard = ({
  article,
  isMobile,
}: {
  article: ArticleModel;
  isMobile?: boolean;
}) => {
  const { activeCard, setActiveCard } = useContext(ActiveCardContext); //eslint-disable-line
  const [scaleSize, setScaleSize] = useState(1); //eslint-disable-line
  const [moveXY, setMoveXY] = useState({ x: 0, y: 0 }); //eslint-disable-line
  const sendEvent = async () => {
    const currentUserInfo = await Auth.currentUserInfo();
    Analytics.record({
      name: 'Click Article',
      userId: currentUserInfo.username,
      attributes: {
        slug: article.slug,
        articleType: article.article_type,
      },
      immediate: true,
    });

    resourceAnalytics(currentUserInfo, article, 'Live');
  };
  const cardRef = useRef(null);
  if (!article) return null;

  // Countries
  const articleCountriesText = countryName(article.countries || []);

  const articleCountriesCss = css`
    font-size: 0.7rem;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
    font-family: 'AntoniaText', georgia, serif;
  `;
  const articleCountries = (
    <div css={articleCountriesCss}>{articleCountriesText}</div>
  );

  const imageUrl = optimisedImageUrl(article?.image, 'tr:w-1000,h-500,pr-true');

  let articleRendered;
  // Longform
  if (article.article_type === 'longform') {
    // TODO: Move to SCSS file
    const longformCss = css`
      overflow: hidden;
      height: 200px;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      ${activeCard.show &&
      activeCard.id === article.id &&
      `
        pointer-events: none !important;
        z-index: 10;
      `}
      ${!imageUrl &&
      `
        border: 1px solid #A54C1B;
        background-color: black !important;
        color: #527392 !important;
      `}
      transition: 700ms;
      transform: translate(${moveXY.x}px, ${moveXY.y}px) scale(${scaleSize});
      .time-ago {
        position: absolute;
        bottom: 1rem;
        right: 1rem;
      }
    `;
    articleRendered = (
      <Link
        to={{
          pathname: isMobile
            ? `/m/longform/${article.slug}`
            : `/longform/${article.slug}`,
          state: { hasClick: true },
        }}
        onClick={sendEvent}
      >
        <div
          css={longformCss}
          ref={cardRef}
          className='card is-clickable'
          data-id={article.id}
          id={`longform-${article.id}`}
          data-link={`/longform/${article.slug}`}
        >
          <div className='imagekit-img-wrapper'>
            <div className='imagekit-img'>
              <IKImageComponent
                path={`/${
                  imageUrl.split('/')?.[imageUrl.split('/').length - 1]
                }`}
              />
            </div>
          </div>
          <TitleDesc
            description={article.title}
            title={articleCountriesText}
            date={format(article.date)}
          />
        </div>
      </Link>
    );
  }
  // MacroWatches
  else if (article.article_type === 'MacroWatches') {
    // TODO: Move to SCSS file
    const macrowatchCss = css`
      position: relative;
      overflow: hidden;
      padding: 0 !important;
      height: 190px;
      text-align: center;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      display: flex;
      z-index: 1;
      width: 100%;
      ${activeCard.show &&
      activeCard.id === article.id &&
      `
        pointer-events: none !important;
        z-index: 10;
      `}
      ${!imageUrl &&
      `
        border: 1px solid #A54C1B;
        background-color: black !important;
        color: #527392 !important;
      `}
      transition: 700ms;
      transform: translate(${moveXY.x}px, ${moveXY.y}px) scale(${scaleSize});

      .time-ago {
        position: absolute;
        bottom: 1rem;
        right: 1rem;
      }
      .middle-content {
        display: flex;
        flex-direction: row;
        justify-content: center;
        max-width: 85%;
        max-height: 80%;
      }
      .image-top-left {
        position: absolute;
        width: 100%;
        top: 0px;
        left: 0px;
        object-fit: none;
      }
      .middle-text {
        .country-text-container {
          padding-right: 8px;
          background-size: 100px 100px;
          width: fit-content;
          .country-text {
            background-color: var(--wigram-black-0);
            color: var(--wigram-grey-6);
            letter-spacing: 4px;
            width: fit-content;
            padding: 4px 8px;
            font-size: 0.65rem;
            font-weight: 500;
          }
        }
        .title-text {
          background-color: var(--wigram-green-1);
          color: var(--wigram-black-0);
          text-transform: uppercase;
          letter-spacing: 2px;
          padding: 12px;
          font-size: 1rem;
          font-weight: 700;
          line-height: 1.1rem;
          font-family: 'AntoniaH1';
        }
      }
      .bottom-content {
        flex: 1 1 0%;
        height: 100%;
        width: 100%;
        position: relative;
        .image-bottom {
          height: 100%;
          position: absolute;
          width: 30%;
          top: 0px;
          object-fit: none;
          right: 0px;
        }
      }
    `;
    articleRendered = (
      <Link
        to={{
          pathname: isMobile
            ? `/m/macrowatch/${article.slug}`
            : `/macrowatch/${article.slug}`,
          state: { hasClick: true },
        }}
        onClick={sendEvent}
      >
        <div
          css={macrowatchCss}
          ref={cardRef}
          className='card is-clickable'
          data-id={article.id}
          id={`macrowatch-${article.id}`}
          data-link={`/macrowatch/${article.slug}`}
        >
          <div className='imagekit-img-wrapper'>
            <div className='imagekit-img-macrowatch'>
              <IKImageComponent
                path={`/${
                  imageUrl.split('/')?.[imageUrl.split('/').length - 1]
                }`}
              />
            </div>
          </div>
          <TitleDesc
            description={article.title}
            title={articleCountriesText}
            date={format(article.date)}
            macroWatch
          />
        </div>
      </Link>
    );
  }
  // Shortform
  else {
    // TODO: Move to SCSS file
    const shortFormCss = css`
      &.is-clickable {
        line-height: 100%;
        &.important {
          color: var(--wigram-grey-0);
          background-color: var(--wigram-green-1);
        }
      }
    `;

    articleRendered = (
      <Link
        to={{
          pathname: isMobile
            ? `/m/shortform/${article.slug}`
            : `/shortform/${article.slug}`,
          state: { hasClick: true },
        }}
        onClick={sendEvent}
      >
        <div
          css={shortFormCss}
          className={
            'card is-clickable ' + (article.important ? 'important' : '')
          }
          data-id={article.id}
          id={`shortform-${article.id}`}
          data-link={`/shortform/${article.slug}`}
        >
          {articleCountries}
          {article.title}
          <TypeAndTime article={article} isShortform />
        </div>
      </Link>
    );
  }
  return articleRendered;
};

function format(date: string | Date) {
  const currentTime = moment();
  const publishedTime = moment(date);
  const diffMin = currentTime.diff(publishedTime, 'minute');
  const diffHours = currentTime.diff(publishedTime, 'hours');

  if (diffMin < 60) {
    return `${diffMin}m`; // < 1hr use mins / 24m
  } else if (diffHours < 24) {
    return `${diffHours}h`; // < 24hrs use hrs / 5h
  } else {
    return publishedTime.format('D MMM'); // Jun 20
  }
}

// Cards: Add time/day stamp in bottom right corner(<1hr use mins <24hrs use hrs >24hrs use date (Jun 19))
const TypeAndTime = ({
  article,
  isShortform = false,
}: {
  article: any;
  isShortform?: boolean;
}) => {
  const articleTypeText = article.type?.toUpperCase();
  const isLongForm = !articleTypeText;
  const isData = articleTypeText === 'DATA';
  // TODO: Move to SCSS file
  const metaRowCss = css`
    display: flex;
    justify-content: space-between;
    margin-top: ${isShortform ? '0.5rem' : '0'};
    .article-type-text {
      color: ${isData ? '#955C45' : '#4AA842'};
      font-size: 0.7rem;
      font-family: Antonia;
      &.important {
        color: var(--wigram-grey-0);
      }
    }

    .time-ago {
      font-family: Avenir;
      color: ${isLongForm ? '#ffffff' : 'var(--wigram-blue-text)'};
      font-size: 0.7rem;
      &.important {
        color: ${isLongForm ? '#ffffff' : 'var(--wigram-grey-0)'};
      }
    }
  `;

  return (
    <div css={metaRowCss}>
      <div
        className={
          'article-type-text ' + (article.important ? 'important' : '')
        }
      >
        {articleTypeText}
      </div>
      {!article.in_progress && (
        <div className={'time-ago ' + (article.important ? 'important' : '')}>
          {format(article.date)}
        </div>
      )}
    </div>
  );
};

export default React.memo(ArticleCard);
