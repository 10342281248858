import ApiService from './apiService';
import { BASE_URLS } from 'utils/constants';

const getComputedBaseUrl = () => {
  let baseUrl: string = BASE_URLS.COMPUTED;

  if (process.env.REACT_APP_API_URL) {
    let envBaseUrl: string = process.env.REACT_APP_API_URL as string;
    if (!envBaseUrl.endsWith('/')) {
      baseUrl = envBaseUrl + '/';
    } else {
      baseUrl = envBaseUrl;
    }
  }

  return baseUrl;
};

const coumputedApiService = (
  token?: string,
  useDefaultHeader: boolean = true
) => ApiService(getComputedBaseUrl(), token, useDefaultHeader);

export default coumputedApiService;
