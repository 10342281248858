import { IItemImage } from 'interface/image.interface';
import { MFormat } from './format.model';

export class MImage {
  [x: string]: any;
  id: number | undefined;
  constructor(dataImage?: IItemImage) {
    this.setData(dataImage);
  }
  setData(data?: IItemImage) {
    this.id = data?.id;
    this.name = data?.attributes?.name;
    this.caption = data?.attributes?.caption;
    this.width = data?.attributes?.width;
    this.height = data?.attributes?.height;
    this.formats = new MFormat(data?.attributes?.formats);
    this.hash = data?.attributes?.hash;
    this.ext = data?.attributes?.ext;
    this.mime = data?.attributes?.mime;
    this.size = data?.attributes?.size;
    this.url = data?.attributes?.url;
    this.provider = data?.attributes?.provider;
    this.provider_metadata = data?.attributes?.provider_metadata;
    this.createdAt = data?.attributes?.createdAt;
    this.updatedAt = data?.attributes?.updatedAt;
    this.alternativeText = data?.attributes?.alternativeText;
    this.previewUrl = data?.attributes?.previewUrl;
  }
}
