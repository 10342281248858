import React from 'react';
import { IKImage } from 'imagekitio-react';

interface IKProps {
  path: any;
  transformation?: any;
}
const IKImageComponent = ({ path, transformation, ...props }: IKProps) => {
  return (
    <IKImage
      path={path}
      transformation={transformation}
      {...props}
      loading='lazy'
      lqip={{ active: true }}
    />
  );
};

export default IKImageComponent;
