export const getOperatingSystem = () => {
  const userAgent = window.navigator.userAgent;
  let operatingSystem = 'Not known';

  if (userAgent.indexOf('Win') !== -1) {
    operatingSystem = 'Windows OS';
  }
  if (userAgent.indexOf('Mac') !== -1) {
    operatingSystem = 'MacOS';
  }
  if (userAgent.indexOf('X11') !== -1) {
    operatingSystem = 'UNIX OS';
  }
  if (userAgent.indexOf('Linux') !== -1) {
    operatingSystem = 'Linux OS';
  }
  if (/android/i.test(userAgent)) {
    operatingSystem = 'Android';
  }
  if (
    /iPad|iPhone|iPod/.test(userAgent) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
  ) {
    operatingSystem = 'iOS';
  }

  return operatingSystem;
};

export const getBrowser = () => {
  let currentBrowser = 'Not known';
  if (window.navigator.userAgent.indexOf('Chrome') !== -1) {
    currentBrowser = 'Google Chrome';
  } else if (window.navigator.userAgent.indexOf('Firefox') !== -1) {
    currentBrowser = 'Mozilla Firefox';
  } else if (window.navigator.userAgent.indexOf('MSIE') !== -1) {
    currentBrowser = 'Internet Exployer';
  } else if (window.navigator.userAgent.indexOf('Edge') !== -1) {
    currentBrowser = 'Edge';
  } else if (window.navigator.userAgent.indexOf('Safari') !== -1) {
    currentBrowser = 'Safari';
  } else if (window.navigator.userAgent.indexOf('Opera') !== -1) {
    currentBrowser = 'Opera';
  } else if (window.navigator.userAgent.indexOf('Opera') !== -1) {
    currentBrowser = 'YaBrowser';
  }

  return currentBrowser;
};

export const getGeolocation = () => {
  const geolocation = { latitude: 0, longitude: 0 };

  if (window.navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(function (position) {
      geolocation.latitude = position.coords.latitude;
      geolocation.longitude = position.coords.longitude;
    });
  }

  return geolocation;
};
