import { AuditEventDto, AuthAuditEventType } from 'types/analytics';
import {
  getBrowser,
  // getGeolocation,
  getOperatingSystem,
} from 'utils/detectUserAgent';
import analyticsServices from 'services/analytics';
import { ArticleModel } from 'model/article.model';

export const authAnalytics = async (user: any, type: AuthAuditEventType) => {
  const data: AuditEventDto = {
    uid: user?.username || '',
    email: user?.attributes?.email || '',
    browserInformation: getBrowser(),
    deviceOperatingSystem: getOperatingSystem(),
    eventTimestamp: new Date().toISOString(),
    eventType: type,
    // latitude: getGeolocation().latitude,
    // longitude: getGeolocation().longitude,
    fcmToken: '',
    msisdn: '',
    uniqueDeviceIdentification: '',
    eventComment: window?.location?.hostname || '',
  };

  try {
    await analyticsServices.authAnalyticsEvent(data);
  } catch (error) {
    console.log('authAnalytics Error:::', { error });
  }
};

export type ResourceAnalyticsType = 'CLICK' | 'PRINT' | 'DOWNLOAD';

export const resourceAnalytics = async (
  user: any,
  article?: ArticleModel,
  sourceView?: string,
  type?: ResourceAnalyticsType
) => {
  const data = {
    uid: user?.username || '',
    email: user?.attributes?.email || '',
    type: type || 'CLICK',
    device_information: '',
    os_information: getOperatingSystem(),
    browser_information: getBrowser(),
    source_app: window?.location?.hostname || '',
    resource_url: window?.location?.href || '',
    resource_strapi_url: '',
    resource_strapi_id: article?.id || 0,
    sourceIp: null,
    sourceView: sourceView || '',
  };

  try {
    await analyticsServices.resourceAnalyticsEvent(data);
  } catch (error) {
    console.log('resourceAnalytics Error:::', { error });
  }
};
