import { Alert, AlertVariations } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import './AWSAlert.scss';
const AWSAlert = ({
  variation = 'error',
  heading = 'Error',
  message = '',
}: {
  variation?: AlertVariations;
  heading?: string;
  message?: string;
}) => {
  return (
    <Alert
      className='custom-alert'
      isDismissible={false}
      hasIcon={true}
      heading={heading}
      variation={variation}
    >
      {message}
    </Alert>
  );
};

export default AWSAlert;
