import { atom } from 'recoil';

export const loadingLivePageArticlesState = atom({
  key: 'loadingLivePageArticlesState',
  default: true,
});

export const fetchDatesState = atom<{ from: string; to: string }[]>({
  key: 'fetchDatesState',
  default: [],
});

export const dateHasNewDataState = atom<string | undefined>({
  key: 'dateHasNewDataState',
  default: undefined,
});

export const newArticleIdState = atom<number | undefined>({
  key: 'newArticleIdState',
  default: undefined,
});
