import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { CSSTransition } from 'react-transition-group';
import { Grid } from '@material-ui/core';

import './MenuSlideOut.scss';
import { ReactComponent as IconMenu } from 'icons/menu.svg';
import { ReactComponent as IconMenuCollapse } from 'icons/menu-collapse.svg';
import Button from 'components/Button/Button';
import CustomModal from 'components/Modal';
import { handleSignOut } from 'utils/helper';
import { resourceAnalytics } from 'utils/analytics';

function MenuSlideOut(props: any) {
  //! State
  const [user, setUser] = useState(null);
  const [open, setOpen] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const history = useHistory();

  const toggleMenu = () => {
    if (!showMenu) {
      document.body.style.position = 'absolute';
    } else {
      document.body.style.position = 'static';
    }
    setShowMenu(!showMenu);
  };

  const nodeRef = useRef(null);

  useEffect(() => {
    // When navigating to a new page we need to restore scroll back
    return () => {
      document.body.style.position = 'static';
    };
  }, []);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        setUser(user);
      })
      .catch(() => setUser(null));
  }, []);

  //! Fucntion
  async function redirect(url: string) {
    history.push(url, {
      hasClick: true,
    });
    closeModal();

    resourceAnalytics(user, undefined, 'Settings');
  }

  function closeModal() {
    setShowMenu(false);
  }

  function confirmSignOut() {
    setOpen(true);
  }

  function signOut(event: MouseEvent, user: any) {
    event.preventDefault();
    if (user) handleSignOut(user);
  }

  const currentPath = window.location.pathname;

  const menuSmallLink = ({
    path = '',
    title = '',
    onClick,
  }: {
    path: string;
    title: string;
    onClick?: () => void;
  }) => {
    return (
      <div
        className='web-link'
        onClick={() => (onClick ? onClick() : redirect(`/${path}`))}
      >
        <div className={currentPath === `/${path}` ? 'active' : ''}>
          {title}
        </div>
      </div>
    );
  };

  const menuMainLink = ({
    path = '',
    title = '',
  }: {
    path: string;
    title: string;
  }) => {
    return (
      <div className='web-link' onClick={() => redirect(`/${path}`)}>
        <h1
          className={`large-link ${currentPath === `/${path}` ? 'active' : ''}`}
        >
          {title}
        </h1>
      </div>
    );
  };

  return (
    <>
      <MenuHamburger handleOnClick={toggleMenu} showMenu={showMenu} />

      <CSSTransition
        nodeRef={nodeRef}
        in={showMenu}
        timeout={1000}
        classNames='menu-page'
      >
        <div
          ref={nodeRef}
          className={`menu-page ${showMenu ? 'menu-page-show' : ''}`}
        >
          <div className='section full-height'>
            <div className='container full-height'>
              <div className='columns full-height'>
                <div className='column is-half is-offset-half links-container'>
                  {menuMainLink({ path: '', title: 'Live' })}
                  {menuMainLink({ path: 'longforms', title: 'Long Reads' })}
                  {menuMainLink({ path: 'macrowatch', title: 'Macro Watch' })}
                  <div
                    className='web-link'
                    onClick={() => redirect(`/country-packs`)}
                  >
                    {currentPath === '/country-packs' && (
                      <h1 className='large-link active'>Country Packs</h1>
                    )}
                    {currentPath !== '/country-packs' && (
                      <h1 className='large-link'>Country Packs</h1>
                    )}
                  </div>
                  <div className='small-links'>
                    <div className='columns'>
                      <div className='column'>
                        {menuSmallLink({ path: 'profile', title: 'Profile' })}
                        {menuSmallLink({
                          path: 'contact-us',
                          title: 'Contact Us',
                        })}
                        {menuSmallLink({
                          path: '',
                          title: 'Sign out',
                          onClick: confirmSignOut,
                        })}
                      </div>
                      <div className='column'>
                        {menuSmallLink({
                          path: 'report-bug',
                          title: 'Report bug',
                        })}
                        {menuSmallLink({ path: 'legals', title: 'Legals' })}
                      </div>
                    </div>

                    {/* <div className='column'> */}

                    {/*  */}
                    {/*  */}
                    <CustomModal open={open} handleClose={() => setOpen(false)}>
                      <div className={'sign-out-wrap'}>
                        <div className={'sign-out'}>Sign Out</div>
                        <div className={'confirm-sign-out'}>
                          Are you sure you want to sign out?
                        </div>
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <Button
                                width={'full'}
                                buttonType={'dark'}
                                onClick={(event: any) => {
                                  signOut(event, user);
                                }}
                              >
                                Yes
                              </Button>
                            </Grid>
                            <Grid item xs={6}>
                              <Button
                                width={'full'}
                                onClick={() => setOpen(false)}
                              >
                                No
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </div>
                    </CustomModal>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
      </CSSTransition>
    </>
  );
}

function MenuHamburger({
  handleOnClick,
  showMenu,
}: {
  handleOnClick: () => void;
  showMenu: boolean;
}) {
  return (
    <div className='menu-hamburger' id='menu-hamburger' onClick={handleOnClick}>
      {!showMenu && <IconMenu />}
      {showMenu && <IconMenuCollapse />}
    </div>
  );
}

export default MenuSlideOut;
