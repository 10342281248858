import moment from 'moment';
import { CognitoUser } from 'amazon-cognito-identity-js';

import { cookies } from 'utils/cookie';
import { TOKEN } from 'utils/constants';
import { Auth } from 'aws-amplify';
import { authAnalytics } from './analytics';

export interface IBodyRefreshToken {
  refreshToken: string;
}

const dateUTCToLocal = ({
  date,
  format = 'DD MMM YY',
}: {
  date: Date;
  format?: string;
}) => {
  const dateUTC = date ? moment.utc(date) : moment();
  const dateLocal = dateUTC.local();
  const dateConvert = dateLocal.format(format);
  return dateConvert;
};

const getMaxHeightOfElement = ({ data = [] }: { data: Array<any> }) => {
  return data.map((date: any) => {
    const idTime = moment(date).format('DD/MM/YYYY');
    const allNodeSize = document.querySelectorAll(`div[id='${idTime}']`);

    let max = 0;
    (allNodeSize || []).forEach((el: Element) => {
      max = el.clientHeight - max > 0 ? el.clientHeight : max;
    });
    return max;
  });
};
const resetMaxHeightOfElement = async ({ data = [] }: { data: Array<any> }) => {
  data.forEach((date: any) => {
    const idTime = moment(date).format('DD/MM/YYYY');
    const allNodeSize = document.querySelectorAll(`div[id='${idTime}']`);
    (allNodeSize || []).forEach((el: Element) => {
      el.setAttribute('style', 'height: auto');
    });
  });
};

const arraymove = (arr: Array<any>, fromIndex: number, toIndex: number) => {
  let newArr = [...arr];
  let element = newArr[fromIndex];
  newArr.splice(fromIndex, 1);
  newArr.splice(toIndex, 0, element);
  return newArr;
};

const highlightString = (searchWord: string, searchText: string) => {
  const re = new RegExp(searchText, 'gi'); // global, insensitive
  const newText = searchWord.replace(re, `<b>$&</b>`);
  return newText;
};

const handleSignOut = (user: CognitoUser | null) => {
  if (user) {
    user.signOut(async () => {
      await authAnalytics(user, 'LOGOUT');
      cookies.remove(TOKEN, { path: '/' });
      window.location.reload();
    });
  }
};

const handleAuthSignOut = async (err: any) => {
  const token = cookies.get(TOKEN);
  if (!token) {
    await Auth.signOut();
  }
  // if (err?.response?.status === 401) {
  //   const currentUserInfo = await Auth.currentUserInfo();

  //   await authAnalytics(currentUserInfo, 'REFRESH');
  //   window.location.reload();
  // }
};

export {
  dateUTCToLocal,
  getMaxHeightOfElement,
  arraymove,
  resetMaxHeightOfElement,
  highlightString,
  handleSignOut,
  handleAuthSignOut,
};
