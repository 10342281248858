import React from 'react';
import './Intro.scss';

interface IIntro {
  title: string;
  description: string;
}

const Intro: React.FunctionComponent<IIntro> = ({ title, description }) => {
  return (
    <div className='intro-container'>
      <h3>{title}</h3>
      <p>{description}</p>
    </div>
  );
};

export default Intro;
