import { IDataSearch } from 'interface/dataSearch.interface';
import { isArray } from 'lodash';
import { highlightString } from 'utils/helper';
import { MImage } from './image.model';

export class MDataSearch {
  [x: string]: any;
  id: string | undefined;
  constructor(dataSearch?: IDataSearch, searchText?: string) {
    this.setData(dataSearch, searchText);
  }
  setData(data?: IDataSearch, searchText?: string) {
    this.id = data?.id;
    this.title = data?.title;
    this.photographer = data?.photographer;
    this.description = data?.description;
    this.summary = data?.summary;
    this.detail = data?.detail;
    this.date = data?.date;
    this.publishedAt = data?.publishedAt;
    this.createdAt = data?.createdAt;
    this.updatedAt = data?.updatedAt;
    this.slug = data?.slug;
    this.in_progress = data?.in_progress;
    this.tags_backup = data?.tags_backup;
    this.country = isArray(data?.country)
      ? (data?.country_list || []).map((el: string, index: number) => {
          if (index === 0) {
            return el;
          } else if ((data?.country || []).length - 1 === index) {
            el = ` & ${el}`;
          } else {
            el = `, ${el}`;
          }
          return el;
        })
      : [data?.country];
    this.country_list = isArray(data?.country_list)
      ? (data?.country_list || []).map((el: string, index: number) => {
          if (index === 0) {
            return el;
          } else if ((data?.country_list || []).length - 1 === index) {
            el = ` & ${el}`;
          } else {
            el = `, ${el}`;
          }
          return el;
        })
      : [data?.country_list];
    this.authors = data?.authors;
    this.tag_list = data?.tag_list;
    this.image = new MImage({ id: data?.image?.id, attributes: data?.image });
    this.type = data?.type;
    this.markedSearch = `<div>${highlightString(
      data?.title || '',
      searchText || ''
    )}</div>`;
  }
  static parseJson = (data: IDataSearch[], searchText?: string) => {
    if (isArray(data)) {
      return data.map((e) => new MDataSearch(e, searchText));
    }
    return [];
  };
}
