export const ArrowUp = ({ fill }: { fill?: string }) => {
  return (
    <svg
      width='25'
      height='26'
      viewBox='0 0 25 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='25'
        y='13'
        width='2.20971'
        height='17.6777'
        transform='rotate(135 25 13)'
        fill={fill || 'white'}
      />
      <rect
        x='1.5625'
        y='14.5625'
        width='2.20971'
        height='17.6777'
        transform='rotate(-135 1.5625 14.5625)'
        fill={fill || 'white'}
      />
    </svg>
  );
};
