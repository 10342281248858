import { Auth } from 'aws-amplify';
import AWSAlert from 'components/AWSAlert/AWSAlert';
import InputField from 'components/Field/InputField';
import Spinner from 'components/Spinner/Spinner';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import './Login.scss';
import { ROUTE } from './routes';

const validationSchema = yup.object().shape({
  code: yup.string().required('Code is a required field'),
});

const ConfirmSignupForm = ({
  onChangeRoute,
  visible,
  loginUser,
}: {
  onChangeRoute: (route: string) => void;
  visible: boolean;
  loginUser: any;
}) => {
  const createErrorMessage = (message: string) => ({
    message,
    variation: 'error',
    heading: 'Error',
  });
  const createSuccess = (message: string) => ({
    message,
    variation: 'success',
    heading: 'Success',
  });
  return (
    <Formik
      initialValues={{
        code: '',
        errorMessage: {
          message: '',
          variation: 'error',
          heading: 'Error',
        },
      }}
      onSubmit={async (values, { setSubmitting, setFieldValue }) => {
        setSubmitting(true);
        try {
          const username = loginUser?.user?.username || null;
          await Auth.confirmSignUp(username, values.code);
          setFieldValue(
            'errorMessage',
            createSuccess(
              'Your account has been created successfully you can now log in'
            )
          );
          setTimeout(() => onChangeRoute(ROUTE.LOGIN), 1000);
        } catch (error: any) {
          setFieldValue('errorMessage', createErrorMessage(error?.message));
        } finally {
          setSubmitting(false);
        }
      }}
      validationSchema={validationSchema}
      validateOnBlur
    >
      {(formikProps) => {
        const { values } = formikProps;
        return (
          <>
            {values?.errorMessage.message && (
              <AWSAlert
                // @ts-ignore
                variation={values.errorMessage.variation}
                message={values?.errorMessage?.message}
                heading={values?.errorMessage?.heading}
              />
            )}
            <Form>
              <div className={`form-container ${visible ? 'active-form' : ''}`}>
                <h1>Confirm Sign Up</h1>
                <p style={{ paddingBottom: '1rem' }}>
                  Welcome: <strong>{loginUser?.user?.username}</strong>
                </p>
                <Field
                  component={InputField}
                  label='Code'
                  name='code'
                  // type={formikProps.values.passwordType}
                  // isShowPassword
                  // onShowPassword={() =>
                  //   formikProps.setFieldValue(
                  //     'passwordType',
                  //     formikProps.values.passwordType !== 'password'
                  //       ? 'password'
                  //       : 'text'
                  //   )
                  // }
                />
                <button
                  className='sign-in-button'
                  type='submit'
                  disabled={formikProps.isSubmitting}
                  // disabled={disabled}
                >
                  {formikProps.isSubmitting ? <Spinner /> : 'Finish'}
                </button>
                <div className='form-register__text'>
                  <div>
                    <span>Have an account?</span>
                    <span
                      className='sign-in'
                      onClick={() => onChangeRoute(ROUTE.LOGIN)}
                    >
                      Sign in
                    </span>
                  </div>
                </div>
              </div>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};
export default ConfirmSignupForm;
