const urls = {
  liveScreenSettings: '/api/v1/sec/user/screen-settings',
  authAudit: '/api/v1/audit-analytics/auth-audit',
  refreshToken: '/api/v1/sec/auth/refresh-token',
  plotyChart: '/api/v1/data/chart-data/plotly-chart',
  macroWatch: '/macro-watches',
  // filterFormsURL: (page: string | number) =>
  //   `?filters[in_progress][$ne]=true&sort[0]=date:desc&populate=tag_list.tag,authors.author,chart_packs,countries,pdf,chart_image,image,images,country_list.country,chart_list.chart,chart_pack_list.chart_pack&pagination[page]=${page}&pagination[pageSize]=25`,
  filterFormsURL: (from: string, to: string) => {
    return `?filters[in_progress][$ne]=true&sort[0]=date:desc&filters[date][$between]=${from}&filters[date][$between]=${to}&populate=tag_list.tag,authors.author,chart_packs,countries,pdf,chart_image,image,images,country_list.country,chart_list.chart,chart_pack_list.chart_pack&pagination[limit]=-1`;
  },
  filterFromSlug: (slug: string) =>
    `?filters[slug][$eq]=${slug}&populate=tag_list.tag,authors.author,chart_packs,countries,pdf,chart_image,image,images,country_list.country,article_type,chart_list.chart,chart_pack_list.chart_pack`,
  filterFromId: (id: number) =>
    `?filters[id]=${id}&populate=image,images,presentation,pdf`,
  metaDataQuery: `?filters[in_progress][$ne]=true&sort[0]=date:desc&pagination[page]=1&pagination[pageSize]=1`,
};

export default urls;
