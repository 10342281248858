import { useEffect, useMemo, useRef, useState } from 'react';
import authApiService from 'services/authApiService';
import { updateScreenSetting } from 'api/screenSetting';
import { Auth } from 'aws-amplify';
import urls from 'config/urls';
import { IFilterApplied } from 'interface/filtersApplied.inteface';
import { isJsonString } from 'utils/checkString';
import {
  ANALYSIS,
  COUNTRY_PACK,
  GROUP_BY,
  LIVE,
  LONG_FORM,
  MACRO_WATCH,
  ONBOARDING,
} from 'utils/constants';
import { CountryType } from '../../types/country';
import { ThemeType } from '../../types/theme';
import { handleAuthSignOut } from 'utils/helper';

// interface

function useFilters(themes: Array<ThemeType>, countries: Array<CountryType>) {
  // local
  const store = localStorage.getItem('filtersApplied');
  const storedFiltersApplied = store ? JSON.parse(store) : null;
  const storeOnBoarding = localStorage.getItem(ONBOARDING);

  // WA-145 https://wigram.atlassian.net/browse/WA-145
  const groupByLocal = localStorage.getItem('groupBy');
  const groupByStored = groupByLocal ? JSON.parse(groupByLocal) : null;

  const refGroupBy = useRef<any>(groupByStored || 'Weeks');
  const loaded = useRef<any>(false);

  const defaultFiltersApplied = useMemo(
    () =>
      themes
        .sort((a, b) => a.order - b.order)
        .map((theme) => {
          return {
            savedAt: new Date().getTime(),
            themeId: theme.id,
            countries: countries.map((country) => country.id),
            analysis: ANALYSIS,
          };
        }),
    [themes, countries]
  );

  const processGroupBy = (groupBy: string) => {
    return !groupBy || !GROUP_BY.includes(groupBy) ? GROUP_BY[0] : groupBy;
  };

  // state
  const [filtersApplied, setFiltersApplied] = useState<IFilterApplied[]>(
    storedFiltersApplied || defaultFiltersApplied
  );

  const [groupBy, setGroupBy] = useState<string>(processGroupBy(groupByStored));
  const [onBoardingGuide, setOnboardingGuide] = useState<string>(
    storeOnBoarding || ''
  );

  if (storedFiltersApplied) {
    // If any new countries (since last save), they should be added to the users applied filters
    storedFiltersApplied.forEach((column: any) => {
      if (!column.savedAt) {
        // If no savedAt field exists, then default to '01-01-2000'.
        // TODO: This if statement block can be removed after 1st Nov 2021
        column.savedAt = new Date('01-01-2000');
      }
      const newCountries = countries.filter(
        (country) => new Date(country.created_at).getTime() > column.savedAt
      );
      if (newCountries.length > 0) {
        newCountries.forEach((c) => column.countries.push(c.id));
        column.savedAt = new Date().getTime(); // Update savedAt since we made a change
        // console.warn('Adding countries', newCountries);
      }
    });

    // Remove any countries from applied filters that no longer in Strapi
    storedFiltersApplied.forEach((column: any) => {
      const strapiCountryIds = countries.map((c) => c.id);
      column.savedAt = new Date().getTime();
      column.countries = column.countries.filter((countryId: number) =>
        strapiCountryIds.includes(countryId)
      );
    });
  }

  useEffect(() => {
    authApiService()
      .get(urls?.liveScreenSettings)
      .then((res: any) => {
        if (res.status === 200) {
          const onBoardingGuideStrapi = res?.body?.show_onboarding_guide;
          const dataFromServer =
            res?.body?.live_screen_settings &&
            isJsonString(res?.body?.live_screen_settings)
              ? JSON.parse(res?.body?.live_screen_settings)
              : {
                  groupBy: processGroupBy(groupByStored),
                  filtersApplied: defaultFiltersApplied,
                };
          const groupByServer = processGroupBy(dataFromServer?.groupBy);
          // const filtersApplied = dataFromServer?.filtersApplied;
          const strapiCountryIds = countries.map((c) => c.id);
          const strapiThemeIds = themes.map((c) => c.id);
          const includesCountry = (dataFromServer?.filtersApplied || []).map(
            (el: any) => {
              if (strapiThemeIds.includes(el.themeId)) {
                return {
                  ...el,
                  analysis: ANALYSIS,
                  countries: strapiCountryIds,
                };
              }
              return null;
            }
          );

          const filtersApplied: IFilterApplied[] = includesCountry.filter(
            (e: any) => Boolean(e)
          );

          if (
            filtersApplied.length &&
            filtersApplied.every((i) => Boolean(i))
          ) {
            setFiltersApplied(filtersApplied);
          }

          refGroupBy.current = {
            id: res?.body?.id,
            groupBy: groupByServer,
            filtersApplied,
            onBoardingGuide: onBoardingGuideStrapi,
          };
          setGroupBy(groupBy);
          setOnboardingGuide(onBoardingGuideStrapi);
          localStorage.setItem(ONBOARDING, onBoardingGuideStrapi);
          loaded.current = false;
        }
      })
      .catch((err) => {
        console.log('catch GET ScreenSetting', { err }, err.toJSON());

        if (err?.response?.status === 404) {
          setFiltersApplied(defaultFiltersApplied as IFilterApplied[]);
          setOnboardingGuide(
            `${LIVE},${COUNTRY_PACK},${MACRO_WATCH},${LONG_FORM}`
          );
          localStorage.setItem(
            ONBOARDING,
            `${LIVE},${COUNTRY_PACK},${MACRO_WATCH},${LONG_FORM}`
          );
        } else {
          handleAuthSignOut(err);
        }
      })

      .finally(() => {
        loaded.current = true;
      });
  }, [themes, countries, defaultFiltersApplied, groupByStored, groupBy]);

  // Keep local storage in-sync with filtersApplied
  useEffect(() => {
    // WA-146 https://wigram.atlassian.net/browse/WA-146
    if (filtersApplied.length && groupBy && loaded.current) {
      if (groupBy !== refGroupBy?.current?.id) {
        Auth.currentAuthenticatedUser().then((user) => {
          if (user) {
            updateScreenSetting({
              id: refGroupBy?.current?.id,
              groupBy,
              filtersApplied,
              showOnboard: onBoardingGuide, //['live', 'longforms', 'macrowatch', 'countrypack'],
              onSuccess: (res: any) => {
                refGroupBy.current = {
                  ...refGroupBy.current,
                  id: res?.body?.id,
                  groupBy,
                };
              },
            });
          }
          return;
        });
      }
    }
    // eslint-disable-next-line
  }, [
    filtersApplied,
    groupBy,
    onBoardingGuide,
    filtersApplied?.length,
    loaded.current,
    refGroupBy.current,
  ]);

  return [
    filtersApplied,
    setFiltersApplied,
    groupBy,
    setGroupBy,
    onBoardingGuide,
    setOnboardingGuide,
  ] as const;
}

export default useFilters;
