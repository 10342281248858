/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react/macro';
import Button from 'components/Button/Button';
import { ReactComponent as CloseIcon } from 'icons/close.svg';
import { ReactComponent as RightArrowIcon } from 'icons/right-arrow.svg';

const LiveIntroCard = ({
  title,
  description,
  closeLiveIntro,
  nextLiveIntro,
  visible,
  isMobile = false,
}: {
  title: string;
  description: string;
  closeLiveIntro: () => void;
  nextLiveIntro: () => void;
  visible: boolean;
  isMobile?: boolean;
}) => {
  const dismiss = () => {};

  const liveIntroCardCss = css`
    background-color: #00000000;
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 5006;
    display: flex;
    align-item: center;
    justify-content: center;
    top: 0;
    .live-intro__container {
      margin: auto;
      width: 35%;
      background-color: var(--wigram-green-2);
      padding-right: 1.2%;
      padding-bottom: 1.2%;
      padding-top: 0.4%;
      position: absolute;
      transform: translateY(120vh);
      .close-icon {
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        .sub-title {
          padding-left: 7%;
          font-style: normal;
          font-weight: 600;
          font-size: 2.4rem;
          line-height: 220%;
          color: var(--wigram-black-0);
        }
      }
      .text {
        font-family: Avenir;
        font-style: normal;
        font-weight: normal;
        font-size: 1.2rem;
        line-height: 120%;
        color: var(--wigram-black-0);

        padding-left: 7%;
        padding-right: 12%;
        padding-bottom: 10%;
      }
      .right-arrow-icon {
        display: flex;
        justify-content: flex-end;
        cursor: pointer;
      }
    }
    .live-intro-mobile__container {
      background-color: var(--wigram-green-2);
      width: 100vw;
      // height: 35%;
      position: absolute;
      z-index: 5006;
      bottom: 0;
      .content {
        margin-left: 3.5%;
        margin-right: 3.5%;
        .border {
          display: flex;
          justify-content: center;
          padding-top: 2.4%;
          span {
            width: 59px;
            height: 3px;
            background: rgba(0, 0, 0, 0.3);
            border-radius: 3px;
          }
        }
        .sub-title {
          font-style: normal;
          font-weight: 600;
          font-size: 2.9375rem;
          line-height: 99%;
          color: var(--wigram-black-0);
        }
        .description {
          font-family: Avenir;
          font-style: normal;
          font-weight: normal;
          font-size: 1.1rem;
          line-height: 120%;
          color: var(--wigram-black-0);
          padding-bottom: 10%;
          padding-right: 10%;
        }
        button {
          margin-bottom: 90px;
        }
      }
    }
  `;

  return (
    <div
      css={liveIntroCardCss}
      onClick={dismiss}
      className={visible ? 'active-tutorial-container' : ''}
    >
      {isMobile ? (
        <div
          className={`live-intro-mobile__container ${
            visible ? 'active-ismobile' : ''
          }`}
        >
          <div className='content'>
            <div className='border'>
              <span></span>
            </div>
            <h1 className='sub-title'>{title}</h1>

            <div className='description'>{description}</div>

            <Button
              style={{ borderRadius: 'none' }}
              width={'full'}
              buttonType={'dark'}
              onClick={nextLiveIntro}
            >
              Got it
            </Button>
          </div>
        </div>
      ) : (
        <div
          className={`live-intro__container ${
            visible ? 'active-tutorial' : ''
          }`}
        >
          <div className='close-icon'>
            <h1 className='sub-title'>{title}</h1>
            <CloseIcon height={32} width={32} onClick={closeLiveIntro} />
          </div>
          <div className='text'>{description}</div>
          <div className='right-arrow-icon'>
            <RightArrowIcon
              height={20}
              width={20}
              color='var(--wigram-grey-0)'
              onClick={nextLiveIntro}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default LiveIntroCard;
