import { IDatum } from 'interface/themes.interface';

class MThemes {
  created_at: Date | undefined;
  id: number | undefined;
  name: string | undefined;
  order: Number | undefined;
  updated_at: Date | undefined;
  constructor(data: IDatum) {
    this.setData(data);
  }
  setData(data: IDatum) {
    this.created_at = data.attributes?.createdAt;
    this.id = data.id;
    this.name = data.attributes?.name;
    this.order = data.attributes?.order;
    this.updated_at = data.attributes?.updatedAt;
  }
}

export { MThemes };
