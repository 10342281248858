import { Auth } from 'aws-amplify';
import AWSAlert from 'components/AWSAlert/AWSAlert';
import InputField from 'components/Field/InputField';
import PhoneInput from 'components/Field/PhoneInput';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import { countriesList } from './countries';
import './Login.scss';
import './RegisterForm.scss';
import { ROUTE } from './routes';

const validationSchema = yup.object().shape({
  username: yup.string().required('Username is a required field'),
  email: yup
    .string()
    .email('Invalid email')
    .required('Email is a required field'),
  password: yup.string().required('Password is a required field'),
  phone_number: yup.number().required('Phone Number is a required field'),
});

const RegisterForm = ({
  onChangeRoute,
  visible,
  onSetValueUser,
}: {
  onChangeRoute: (route: string) => void;
  visible: boolean;
  onSetValueUser: (user: any) => void;
}) => {
  return (
    <Formik
      initialValues={{
        username: '',
        password: '',
        passwordType: 'password',
        email: '',
        phone_number: '',
        country_code: countriesList[0].dialCode,
        errorMessage: {
          heading: 'Error',
          variation: 'error',
          message: '',
        },
      }}
      onSubmit={async (values, { setSubmitting, setFieldValue }) => {
        const { username, password, email, phone_number, country_code } =
          values;
        setSubmitting(true);
        try {
          const registerResponse = await Auth.signUp({
            username,
            password,
            attributes: {
              email,
              phone_number: `+${country_code}${phone_number}`,
            },
          });
          setFieldValue('errorMessage', {
            heading: 'Success',
            variation: 'success',
            message: 'Register success',
          });
          onSetValueUser(registerResponse);
          setTimeout(() => onChangeRoute(ROUTE.OTP_SIGNUP), 1000);
        } catch (error: any) {
          setFieldValue('errorMessage', {
            variation: 'error',
            message: error?.message,
          });
        }
      }}
      validationSchema={validationSchema}
      validateOnBlur
    >
      {(formikProps) => {
        return (
          <>
            {formikProps.values?.errorMessage?.message && (
              <AWSAlert
                // @ts-ignore
                variation={
                  formikProps.values?.errorMessage.variation || 'error'
                }
                heading={formikProps.values?.errorMessage.heading || 'Error'}
                message={formikProps.values?.errorMessage?.message}
              />
            )}

            <Form>
              <div className={`form-container ${visible ? 'active-form' : ''}`}>
                <h1>Register</h1>
                <Field
                  component={InputField}
                  label={`Username*`}
                  name='username'
                />
                <div style={{ paddingTop: '1.5rem' }} />
                <Field
                  component={InputField}
                  label='Password*'
                  name='password'
                  type={formikProps.values.passwordType}
                  isShowPassword
                  onShowPassword={() =>
                    formikProps.setFieldValue(
                      'passwordType',
                      formikProps.values.passwordType !== 'password'
                        ? 'password'
                        : 'text'
                    )
                  }
                />
                <div style={{ paddingTop: '1.5rem' }} />
                <Field
                  component={InputField}
                  label='Email Address*'
                  name='email'
                />
                <div style={{ paddingTop: '1.5rem' }} />
                <Field
                  component={PhoneInput}
                  label='Phone Number*'
                  name='phone_number'
                  nameCountry='country_code'
                />
                <div style={{ paddingTop: '1.5rem' }} />
                <div className='form-register__text'>
                  <div>
                    <span>Have an account?</span>
                    <span
                      className='sign-in'
                      onClick={() => onChangeRoute(ROUTE.LOGIN)}
                    >
                      Sign in
                    </span>
                  </div>
                  <div>
                    <button
                      className='sign-in-button'
                      type='submit'
                      disabled={formikProps.isSubmitting}
                    >
                      {formikProps.isSubmitting ? (
                        <div className='loader' />
                      ) : (
                        'Create account'
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};
export default RegisterForm;
