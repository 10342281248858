import { ITutorial } from 'interface/tutorial.interface';
import { Step } from 'intro.js-react';
import { ArticleModel } from 'model/article.model';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { createSteps } from 'utils/tutorialStep';

const livePageTutorial = (longformsId: string, isMobile: boolean) => {
  let listLivePage: ITutorial = {
    introduce: {
      description:
        'Here you will find a real time mosaic of our current workflow. You can customise your feed at any time.',
      title: 'Live',
    },
    step: [
      {
        elementId: 'help-icon',
        description:
          'Get started with tips on how to get the most out of your Live experience.',
        title: 'Tool Tips',
        position: 'right',
        tooltipClass: 'custom-tooltip-class',
      },
      {
        elementId: 'menu-hamburger',
        description:
          'Access the menu at anytime to navigate between live, long reads, macro watch and Country Packs.',
        title: 'Navigate easily',
        position: 'right',
        tooltipClass: 'custom-tooltip-class mt-2',
        highlightClass: 'without-box-shadow highlight-class-live-2',
      },
      {
        elementId: 'header-wrapper-1',
        description:
          'Customise what you view by selecting topics most relevant to you.',
        title: 'Filter Content',
        position: 'bottom',
        tooltipClass: `custom-tooltip-class mt-2 ${isMobile ? '' : 'ml-180px'}`,
        highlightClass: 'without-box-shadow  highlight-class-live-3',
      },
      {
        elementId: 'right-arrow',
        description:
          'Reveal more columns. Scroll right for more content. \nYou can drag coulmns to change the order.',
        title: 'Reveal more columns',
        position: 'bottom',
        tooltipClass: 'custom-tooltip-class mr-2',
        highlightClass: 'without-box-shadow highlight-class-live-4',
      },
      {
        elementId: longformsId,
        description:
          'Clicking a tile will take you to an in depth look at that article.',
        title: 'Click to view content',
        position: 'bottom',
        tooltipPosition: 'top-middle',
        tooltipClass: 'custom-tooltip-class',
        highlightClass: 'without-box-shadow highlight-class-live-5',
      },
    ],
  };

  if (isMobile) {
    listLivePage.step.splice(3, 1);
  }

  return listLivePage;
};

export const useStepLivePage = ({
  articleSortDate,
  isMobile,
}: {
  articleSortDate: ArticleModel[];
  isMobile: boolean;
}) => {
  const [showTutorial, setShowTutorial] = useState<boolean>(false);
  const [step, setStep] = useState<Step[]>([]);
  useEffect(() => {
    const newSort = [...articleSortDate].sort(function (a, b) {
      const newArr = moment(b.date).isSameOrBefore(a.date);
      if (newArr) {
        return -1;
      }
      return 0;
    });
    const domId = `${newSort[0]?.article_type}-${newSort[0]?.id}`;
    setStep(createSteps(livePageTutorial(domId, isMobile).step));
  }, [articleSortDate.length]); //eslint-disable-line

  return [
    step,
    setStep,
    showTutorial,
    setShowTutorial,
    livePageTutorial('', isMobile).introduce,
  ] as const;
};
