import { useEffect, useState } from 'react';

export const useScrollRestore = () => {
  const [prevScroll, setPrevScroll] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const previousScroll = sessionStorage.getItem('scrollYPosition');
    if (previousScroll) {
      const position = JSON.parse(previousScroll);
      setTimeout(() => {
        window.scrollTo(position.x, position.y);
      }, 100);
      sessionStorage.removeItem('scrollYPosition');
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setPrevScroll({ x: window.scrollX, y: window.scrollY });
    };
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      sessionStorage.setItem('scrollYPosition', JSON.stringify(prevScroll));
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScroll]);
};
