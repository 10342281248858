import { useState, useEffect } from 'react';
import useSWR from 'swr';

import cmsApiSerivce from 'services/cmsApiService';
import { MCountries } from 'model/countries.model';
import { Countries } from 'interface/countries.interface';

const useFetchCountries = () => {
  const [countries, setCountries] = useState<any[]>([]);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState(1);

  const { data } = useSWR(
    `/countries?pagination[page]=${currentPage}`,
    (url): Promise<Countries> => cmsApiSerivce().get(url)
  );

  useEffect(() => {
    if (data) {
      const nextData: any = data.data.map((country) => new MCountries(country));

      const totalPage = data.meta.pagination.pageCount;

      setTotalPages(totalPage);
      setCountries((prevData) => [...prevData, ...nextData]);
    }
  }, [data]);

  useEffect(() => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  }, [totalPages, currentPage]);

  return {
    countries,
  };
};

export default useFetchCountries;
