export const countriesList = [
  {
    name: 'Afghanistan (‫افغانستان‬‎)',
    iso2: 'af',
    dialCode: '93',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Albania (Shqipëri)',
    iso2: 'al',
    dialCode: '355',
    priority: 0,
    areaCodes: null,
    currencyCode: 'ALL',
    currencyName: 'lek',
  },
  {
    name: 'Algeria (‫الجزائر‬‎)',
    iso2: 'dz',
    dialCode: '213',
    priority: 0,
    areaCodes: null,
    currencyCode: 'DZD',
    currencyName: 'dinar',
  },
  {
    name: 'American Samoa',
    iso2: 'as',
    dialCode: '1684',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Andorra',
    iso2: 'ad',
    dialCode: '376',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Angola',
    iso2: 'ao',
    dialCode: '244',
    priority: 0,
    areaCodes: null,
    currencyCode: 'AOA',
    currencyName: 'kwanza',
  },
  {
    name: 'Anguilla',
    iso2: 'ai',
    dialCode: '1264',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Antigua and Barbuda',
    iso2: 'ag',
    dialCode: '1268',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Argentina',
    iso2: 'ar',
    dialCode: '54',
    priority: 0,
    areaCodes: null,
    currencyCode: 'ARS',
    currencyName: 'peso',
  },
  {
    name: 'Armenia (Հայաստան)',
    iso2: 'am',
    dialCode: '374',
    priority: 0,
    areaCodes: null,
    currencyCode: 'AMD',
    currencyName: 'dram',
  },
  {
    name: 'Aruba',
    iso2: 'aw',
    dialCode: '297',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Australia',
    iso2: 'au',
    dialCode: '61',
    priority: 0,
    areaCodes: null,
    currencyCode: 'AUD',
    currencyName: 'dollar',
  },
  {
    name: 'Austria (Österreich)',
    iso2: 'at',
    dialCode: '43',
    priority: 0,
    areaCodes: null,
    currencyCode: 'EUR',
    currencyName: 'euro',
  },
  {
    name: 'Azerbaijan (Azərbaycan)',
    iso2: 'az',
    dialCode: '994',
    priority: 0,
    areaCodes: null,
    currencyCode: 'AZN',
    currencyName: 'manat',
  },
  {
    name: 'Bahamas',
    iso2: 'bs',
    dialCode: '1242',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Bahrain (‫البحرين‬‎)',
    iso2: 'bh',
    dialCode: '973',
    priority: 0,
    areaCodes: null,
    currencyCode: 'BHD',
    currencyName: 'dinar',
  },
  {
    name: 'Bangladesh (বাংলাদেশ)',
    iso2: 'bd',
    dialCode: '880',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Barbados',
    iso2: 'bb',
    dialCode: '1246',
    priority: 0,
    areaCodes: null,
    currencyCode: 'BBD',
    currencyName: 'dollar',
  },
  {
    name: 'Belarus (Беларусь)',
    iso2: 'by',
    dialCode: '375',
    priority: 0,
    areaCodes: null,
    currencyCode: 'BYN',
    currencyName: 'rouble',
  },
  {
    name: 'Belgium (België)',
    iso2: 'be',
    dialCode: '32',
    priority: 0,
    areaCodes: null,
    currencyCode: 'EUR',
    currencyName: 'euro',
  },
  {
    name: 'Belize',
    iso2: 'bz',
    dialCode: '501',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Benin (Bénin)',
    iso2: 'bj',
    dialCode: '229',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Bermuda',
    iso2: 'bm',
    dialCode: '1441',
    priority: 0,
    areaCodes: null,
    currencyCode: 'BMD',
    currencyName: 'dollar',
  },
  {
    name: 'Bhutan (འབྲུག)',
    iso2: 'bt',
    dialCode: '975',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Bolivia',
    iso2: 'bo',
    dialCode: '591',
    priority: 0,
    areaCodes: null,
    currencyCode: 'BOB',
    currencyName: 'boliviano',
  },
  {
    name: 'Bosnia and Herzegovina (Босна и Херцеговина)',
    iso2: 'ba',
    dialCode: '387',
    priority: 0,
    areaCodes: null,
    currencyCode: 'BAM',
    currencyName: 'konvertibilna marka',
  },
  {
    name: 'Botswana',
    iso2: 'bw',
    dialCode: '267',
    priority: 0,
    areaCodes: null,
    currencyCode: 'BWP',
    currencyName: 'pula',
  },
  {
    name: 'Brazil (Brasil)',
    iso2: 'br',
    dialCode: '55',
    priority: 0,
    areaCodes: null,
    currencyCode: 'BRL',
    currencyName: 'real',
  },
  {
    name: 'British Indian Ocean Territory',
    iso2: 'io',
    dialCode: '246',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'British Virgin Islands',
    iso2: 'vg',
    dialCode: '1284',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Brunei',
    iso2: 'bn',
    dialCode: '673',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Bulgaria (България)',
    iso2: 'bg',
    dialCode: '359',
    priority: 0,
    areaCodes: null,
    currencyCode: 'BGN',
    currencyName: 'lev',
  },
  {
    name: 'Burkina Faso',
    iso2: 'bf',
    dialCode: '226',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Burundi (Uburundi)',
    iso2: 'bi',
    dialCode: '257',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Cambodia (កម្ពុជា)',
    iso2: 'kh',
    dialCode: '855',
    priority: 0,
    areaCodes: null,
    currencyCode: 'KHR',
    currencyName: 'riel',
  },
  {
    name: 'Cameroon (Cameroun)',
    iso2: 'cm',
    dialCode: '237',
    priority: 0,
    areaCodes: null,
    currencyCode: 'XAF',
    currencyName: 'CFA franc BEAC',
  },
  {
    name: 'Canada',
    iso2: 'ca',
    dialCode: '1',
    priority: 1,
    areaCodes: [
      '204',
      '226',
      '236',
      '249',
      '250',
      '289',
      '306',
      '343',
      '365',
      '387',
      '403',
      '416',
      '418',
      '431',
      '437',
      '438',
      '450',
      '506',
      '514',
      '519',
      '548',
      '579',
      '581',
      '587',
      '604',
      '613',
      '639',
      '647',
      '672',
      '705',
      '709',
      '742',
      '778',
      '780',
      '782',
      '807',
      '819',
      '825',
      '867',
      '873',
      '902',
      '905',
    ],
    currencyCode: 'CAD',
    currencyName: 'dollar',
  },
  {
    name: 'Cape Verde (Kabu Verdi)',
    iso2: 'cv',
    dialCode: '238',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Caribbean Netherlands',
    iso2: 'bq',
    dialCode: '599',
    priority: 1,
    areaCodes: null,
  },
  {
    name: 'Cayman Islands',
    iso2: 'ky',
    dialCode: '1345',
    priority: 0,
    areaCodes: null,
    currencyCode: 'KYD',
    currencyName: 'dollar',
  },
  {
    name: 'Central African Republic (République centrafricaine)',
    iso2: 'cf',
    dialCode: '236',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Chad (Tchad)',
    iso2: 'td',
    dialCode: '235',
    priority: 0,
    areaCodes: null,
    currencyCode: 'XAF',
    currencyName: 'CFA franc BEAC',
  },
  {
    name: 'Chile',
    iso2: 'cl',
    dialCode: '56',
    priority: 0,
    areaCodes: null,
    currencyCode: 'CLP',
    currencyName: 'peso',
  },
  {
    name: 'China (中国)',
    iso2: 'cn',
    dialCode: '86',
    priority: 0,
    areaCodes: null,
    currencyCode: 'CNY',
    currencyName: 'yuan',
  },
  {
    name: 'Christmas Island',
    iso2: 'cx',
    dialCode: '61',
    priority: 2,
    areaCodes: null,
  },
  {
    name: 'Cocos (Keeling) Islands',
    iso2: 'cc',
    dialCode: '61',
    priority: 1,
    areaCodes: null,
  },
  {
    name: 'Colombia',
    iso2: 'co',
    dialCode: '57',
    priority: 0,
    areaCodes: null,
    currencyCode: 'COP',
    currencyName: 'peso',
  },
  {
    name: 'Comoros (‫جزر القمر‬‎)',
    iso2: 'km',
    dialCode: '269',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)',
    iso2: 'cd',
    dialCode: '243',
    priority: 0,
    areaCodes: null,
    currencyCode: 'CDF',
    currencyName: 'franc',
  },
  {
    name: 'Congo (Republic) (Congo-Brazzaville)',
    iso2: 'cg',
    dialCode: '242',
    priority: 0,
    areaCodes: null,
    currencyCode: 'XAF',
    currencyName: 'CFA franc BEAC',
  },
  {
    name: 'Cook Islands',
    iso2: 'ck',
    dialCode: '682',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Costa Rica',
    iso2: 'cr',
    dialCode: '506',
    priority: 0,
    areaCodes: null,
    currencyCode: 'CRC',
    currencyName: 'colon',
  },
  {
    name: 'Côte d’Ivoire',
    iso2: 'ci',
    dialCode: '225',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Croatia (Hrvatska)',
    iso2: 'hr',
    dialCode: '385',
    priority: 0,
    areaCodes: null,
    currencyCode: 'HRK',
    currencyName: 'kuna',
  },
  {
    name: 'Cuba',
    iso2: 'cu',
    dialCode: '53',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Curaçao',
    iso2: 'cw',
    dialCode: '599',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Cyprus (Κύπρος)',
    iso2: 'cy',
    dialCode: '357',
    priority: 0,
    areaCodes: null,
    currencyCode: 'EUR',
    currencyName: 'euro',
  },
  {
    name: 'Czech Republic (Česká republika)',
    iso2: 'cz',
    dialCode: '420',
    priority: 0,
    areaCodes: null,
    currencyCode: 'CZK',
    currencyName: 'koruna',
  },
  {
    name: 'Denmark (Danmark)',
    iso2: 'dk',
    dialCode: '45',
    priority: 0,
    areaCodes: null,
    currencyCode: 'DKK',
    currencyName: 'kroner',
  },
  {
    name: 'Djibouti',
    iso2: 'dj',
    dialCode: '253',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Dominica',
    iso2: 'dm',
    dialCode: '1767',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Dominican Republic (República Dominicana)',
    iso2: 'do',
    dialCode: '1',
    priority: 2,
    areaCodes: ['809', '829', '849'],
    currencyCode: 'DOP',
    currencyName: 'peso',
  },
  {
    name: 'Ecuador',
    iso2: 'ec',
    dialCode: '593',
    priority: 0,
    areaCodes: null,
    currencyCode: 'USD',
    currencyName: 'dollar',
  },
  {
    name: 'Egypt (‫مصر‬‎)',
    iso2: 'eg',
    dialCode: '20',
    priority: 0,
    areaCodes: null,
    currencyCode: 'EGP',
    currencyName: 'pound',
  },
  {
    name: 'El Salvador',
    iso2: 'sv',
    dialCode: '503',
    priority: 0,
    areaCodes: null,
    currencyCode: 'USD',
    currencyName: 'dollar',
  },
  {
    name: 'Equatorial Guinea (Guinea Ecuatorial)',
    iso2: 'gq',
    dialCode: '240',
    priority: 0,
    areaCodes: null,
    currencyCode: 'XAF',
    currencyName: 'CFA franc BEAC',
  },
  {
    name: 'Eritrea',
    iso2: 'er',
    dialCode: '291',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Estonia (Eesti)',
    iso2: 'ee',
    dialCode: '372',
    priority: 0,
    areaCodes: null,
    currencyCode: 'EUR',
    currencyName: 'euro',
  },
  {
    name: 'Ethiopia',
    iso2: 'et',
    dialCode: '251',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Falkland Islands (Islas Malvinas)',
    iso2: 'fk',
    dialCode: '500',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Faroe Islands (Føroyar)',
    iso2: 'fo',
    dialCode: '298',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Fiji',
    iso2: 'fj',
    dialCode: '679',
    priority: 0,
    areaCodes: null,
    currencyCode: 'FJD',
    currencyName: 'dollar',
  },
  {
    name: 'Finland (Suomi)',
    iso2: 'fi',
    dialCode: '358',
    priority: 0,
    areaCodes: null,
    currencyCode: 'EUR',
    currencyName: 'euro',
  },
  {
    name: 'France',
    iso2: 'fr',
    dialCode: '33',
    priority: 0,
    areaCodes: null,
    currencyCode: 'EUR',
    currencyName: 'euro',
  },
  {
    name: 'French Guiana (Guyane française)',
    iso2: 'gf',
    dialCode: '594',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'French Polynesia (Polynésie française)',
    iso2: 'pf',
    dialCode: '689',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Gabon',
    iso2: 'ga',
    dialCode: '241',
    priority: 0,
    areaCodes: null,
    currencyCode: 'XAF',
    currencyName: 'CFA franc BEAC',
  },
  {
    name: 'Gambia',
    iso2: 'gm',
    dialCode: '220',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Georgia (საქართველო)',
    iso2: 'ge',
    dialCode: '995',
    priority: 0,
    areaCodes: null,
    currencyCode: 'GEL',
    currencyName: 'Lari',
  },
  {
    name: 'Germany (Deutschland)',
    iso2: 'de',
    dialCode: '49',
    priority: 0,
    areaCodes: null,
    currencyCode: 'EUR',
    currencyName: 'euro',
  },
  {
    name: 'Ghana (Gaana)',
    iso2: 'gh',
    dialCode: '233',
    priority: 0,
    areaCodes: null,
    currencyCode: 'GHS',
    currencyName: 'cedi',
  },
  {
    name: 'Gibraltar',
    iso2: 'gi',
    dialCode: '350',
    priority: 0,
    areaCodes: null,
    currencyCode: 'GIP',
    currencyName: 'pound',
  },
  {
    name: 'Greece (Ελλάδα)',
    iso2: 'gr',
    dialCode: '30',
    priority: 0,
    areaCodes: null,
    currencyCode: 'EUR',
    currencyName: 'euro',
  },
  {
    name: 'Greenland (Kalaallit Nunaat)',
    iso2: 'gl',
    dialCode: '299',
    priority: 0,
    areaCodes: null,
    currencyCode: 'DKK',
    currencyName: 'Danish krone',
  },
  {
    name: 'Grenada',
    iso2: 'gd',
    dialCode: '1473',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Guadeloupe',
    iso2: 'gp',
    dialCode: '590',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Guam',
    iso2: 'gu',
    dialCode: '1671',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Guatemala',
    iso2: 'gt',
    dialCode: '502',
    priority: 0,
    areaCodes: null,
    currencyCode: 'GTQ',
    currencyName: 'quetzal',
  },
  {
    name: 'Guernsey',
    iso2: 'gg',
    dialCode: '44',
    priority: 1,
    areaCodes: null,
    currencyCode: 'GBP',
    currencyName: 'British pound sterling',
  },
  {
    name: 'Guinea (Guinée)',
    iso2: 'gn',
    dialCode: '224',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Guinea-Bissau (Guiné Bissau)',
    iso2: 'gw',
    dialCode: '245',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Guyana',
    iso2: 'gy',
    dialCode: '592',
    priority: 0,
    areaCodes: null,
    currencyCode: 'GYD',
    currencyName: 'dollar',
  },
  {
    name: 'Haiti',
    iso2: 'ht',
    dialCode: '509',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Honduras',
    iso2: 'hn',
    dialCode: '504',
    priority: 0,
    areaCodes: null,
    currencyCode: 'HNL',
    currencyName: 'lempira',
  },
  {
    name: 'Hong Kong (香港)',
    iso2: 'hk',
    dialCode: '852',
    priority: 0,
    areaCodes: null,
    currencyCode: 'HKD',
    currencyName: 'dollar',
  },
  {
    name: 'Hungary (Magyarország)',
    iso2: 'hu',
    dialCode: '36',
    priority: 0,
    areaCodes: null,
    currencyCode: 'HUF',
    currencyName: 'forint',
  },
  {
    name: 'Iceland (Ísland)',
    iso2: 'is',
    dialCode: '354',
    priority: 0,
    areaCodes: null,
    currencyCode: 'ISK',
    currencyName: 'krona',
  },
  {
    name: 'India (भारत)',
    iso2: 'in',
    dialCode: '91',
    priority: 0,
    areaCodes: null,
    currencyCode: 'INR',
    currencyName: 'rupee',
  },
  {
    name: 'Indonesia',
    iso2: 'id',
    dialCode: '62',
    priority: 0,
    areaCodes: null,
    currencyCode: 'IDR',
    currencyName: 'rupiah',
  },
  {
    name: 'Iran (‫ایران‬‎)',
    iso2: 'ir',
    dialCode: '98',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Iraq (‫العراق‬‎)',
    iso2: 'iq',
    dialCode: '964',
    priority: 0,
    areaCodes: null,
    currencyCode: 'IQD',
    currencyName: 'dinar',
  },
  {
    name: 'Ireland',
    iso2: 'ie',
    dialCode: '353',
    priority: 0,
    areaCodes: null,
    currencyCode: 'EUR',
    currencyName: 'euro',
  },
  {
    name: 'Isle of Man',
    iso2: 'im',
    dialCode: '44',
    priority: 2,
    areaCodes: null,
    currencyCode: 'IMP',
    currencyName: 'pound',
  },
  {
    name: 'Israel (‫ישראל‬‎)',
    iso2: 'il',
    dialCode: '972',
    priority: 0,
    areaCodes: null,
    currencyCode: 'ILS',
    currencyName: 'shekel',
  },
  {
    name: 'Italy (Italia)',
    iso2: 'it',
    dialCode: '39',
    priority: 0,
    areaCodes: null,
    currencyCode: 'EUR',
    currencyName: 'euro',
  },
  {
    name: 'Jamaica',
    iso2: 'jm',
    dialCode: '1876',
    priority: 0,
    areaCodes: null,
    currencyCode: 'JMD',
    currencyName: 'dollar',
  },
  {
    name: 'Japan (日本)',
    iso2: 'jp',
    dialCode: '81',
    priority: 0,
    areaCodes: null,
    currencyCode: 'JPY',
    currencyName: 'yen',
  },
  {
    name: 'Jersey',
    iso2: 'je',
    dialCode: '44',
    priority: 3,
    areaCodes: null,
    currencyCode: 'GBP',
    currencyName: 'British pound sterling',
  },
  {
    name: 'Jordan (‫الأردن‬‎)',
    iso2: 'jo',
    dialCode: '962',
    priority: 0,
    areaCodes: null,
    currencyCode: 'JOD',
    currencyName: 'dinar',
  },
  {
    name: 'Kazakhstan (Казахстан)',
    iso2: 'kz',
    dialCode: '77',
    priority: 1,
    areaCodes: null,
    currencyCode: 'KZT',
    currencyName: 'tenge',
  },
  {
    name: 'Kenya',
    iso2: 'ke',
    dialCode: '254',
    priority: 0,
    areaCodes: null,
    currencyCode: 'KES',
    currencyName: 'shilling',
  },
  {
    name: 'Kiribati',
    iso2: 'ki',
    dialCode: '686',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Kuwait (‫الكويت‬‎)',
    iso2: 'kw',
    dialCode: '965',
    priority: 0,
    areaCodes: null,
    currencyCode: 'KWD',
    currencyName: 'dinar',
  },
  {
    name: 'Kyrgyzstan (Кыргызстан)',
    iso2: 'kg',
    dialCode: '996',
    priority: 0,
    areaCodes: null,
    currencyCode: 'KGS',
    currencyName: 'som',
  },
  {
    name: 'Laos (ລາວ)',
    iso2: 'la',
    dialCode: '856',
    priority: 0,
    areaCodes: null,
    currencyCode: 'LAK',
    currencyName: 'kip',
  },
  {
    name: 'Latvia (Latvija)',
    iso2: 'lv',
    dialCode: '371',
    priority: 0,
    areaCodes: null,
    currencyCode: 'EUR',
    currencyName: 'euro',
  },
  {
    name: 'Lebanon (‫لبنان‬‎)',
    iso2: 'lb',
    dialCode: '961',
    priority: 0,
    areaCodes: null,
    currencyCode: 'LBP',
    currencyName: 'pound',
  },
  {
    name: 'Lesotho',
    iso2: 'ls',
    dialCode: '266',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Liberia',
    iso2: 'lr',
    dialCode: '231',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Libya (‫ليبيا‬‎)',
    iso2: 'ly',
    dialCode: '218',
    priority: 0,
    areaCodes: null,
    currencyCode: 'LYD',
    currencyName: 'dinar',
  },
  {
    name: 'Liechtenstein',
    iso2: 'li',
    dialCode: '423',
    priority: 0,
    areaCodes: null,
    currencyCode: 'CHF',
    currencyName: 'franc',
  },
  {
    name: 'Lithuania (Lietuva)',
    iso2: 'lt',
    dialCode: '370',
    priority: 0,
    areaCodes: null,
    currencyCode: 'EUR',
    currencyName: 'euro',
  },
  {
    name: 'Luxembourg',
    iso2: 'lu',
    dialCode: '352',
    priority: 0,
    areaCodes: null,
    currencyCode: 'EUR',
    currencyName: 'euro',
  },
  {
    name: 'Macau (澳門)',
    iso2: 'mo',
    dialCode: '853',
    priority: 0,
    areaCodes: null,
    currencyCode: 'MOP',
    currencyName: 'patace',
  },
  {
    name: 'Macedonia (FYROM) (Македонија)',
    iso2: 'mk',
    dialCode: '389',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Madagascar (Madagasikara)',
    iso2: 'mg',
    dialCode: '261',
    priority: 0,
    areaCodes: null,
    currencyCode: 'MGA',
    currencyName: 'ariary',
  },
  {
    name: 'Malawi',
    iso2: 'mw',
    dialCode: '265',
    priority: 0,
    areaCodes: null,
    currencyCode: 'MWK',
    currencyName: 'kwacha',
  },
  {
    name: 'Malaysia',
    iso2: 'my',
    dialCode: '60',
    priority: 0,
    areaCodes: null,
    currencyCode: 'MYR',
    currencyName: 'ringgit',
  },
  {
    name: 'Maldives',
    iso2: 'mv',
    dialCode: '960',
    priority: 0,
    areaCodes: null,
    currencyCode: 'MVR',
    currencyName: 'rufiyaa',
  },
  {
    name: 'Mali',
    iso2: 'ml',
    dialCode: '223',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Malta',
    iso2: 'mt',
    dialCode: '356',
    priority: 0,
    areaCodes: null,
    currencyCode: 'EUR',
    currencyName: 'euro',
  },
  {
    name: 'Marshall Islands',
    iso2: 'mh',
    dialCode: '692',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Martinique',
    iso2: 'mq',
    dialCode: '596',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Mauritania (‫موريتانيا‬‎)',
    iso2: 'mr',
    dialCode: '222',
    priority: 0,
    areaCodes: null,
    currencyCode: 'MRU',
    currencyName: 'ouguiya',
  },
  {
    name: 'Mauritius (Moris)',
    iso2: 'mu',
    dialCode: '230',
    priority: 0,
    areaCodes: null,
    currencyCode: 'MUR',
    currencyName: 'rupee',
  },
  {
    name: 'Mayotte',
    iso2: 'yt',
    dialCode: '262',
    priority: 1,
    areaCodes: null,
  },
  {
    name: 'Mexico (México)',
    iso2: 'mx',
    dialCode: '52',
    priority: 0,
    areaCodes: null,
    currencyCode: 'MXN',
    currencyName: 'peso',
  },
  {
    name: 'Micronesia',
    iso2: 'fm',
    dialCode: '691',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Moldova (Republica Moldova)',
    iso2: 'md',
    dialCode: '373',
    priority: 0,
    areaCodes: null,
    currencyCode: 'MDL',
    currencyName: 'leu',
  },
  {
    name: 'Monaco',
    iso2: 'mc',
    dialCode: '377',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Mongolia (Монгол)',
    iso2: 'mn',
    dialCode: '976',
    priority: 0,
    areaCodes: null,
    currencyCode: 'MNT',
    currencyName: 'tugrik',
  },
  {
    name: 'Montenegro (Crna Gora)',
    iso2: 'me',
    dialCode: '382',
    priority: 0,
    areaCodes: null,
    currencyCode: 'EUR',
    currencyName: 'euro',
  },
  {
    name: 'Montserrat',
    iso2: 'ms',
    dialCode: '1664',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Morocco (‫المغرب‬‎)',
    iso2: 'ma',
    dialCode: '212',
    priority: 0,
    areaCodes: null,
    currencyCode: 'MAD',
    currencyName: 'dirham',
  },
  {
    name: 'Mozambique (Moçambique)',
    iso2: 'mz',
    dialCode: '258',
    priority: 0,
    areaCodes: null,
    currencyCode: 'MZN',
    currencyName: 'metical',
  },
  {
    name: 'Myanmar (Burma)',
    iso2: 'mm',
    dialCode: '95',
    priority: 0,
    areaCodes: null,
    currencyCode: 'MMK',
    currencyName: 'kyat',
  },
  {
    name: 'Namibia (Namibië)',
    iso2: 'na',
    dialCode: '264',
    priority: 0,
    areaCodes: null,
    currencyCode: 'NAD',
    currencyName: 'dollar',
  },
  {
    name: 'Nauru',
    iso2: 'nr',
    dialCode: '674',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Nepal (नेपाल)',
    iso2: 'np',
    dialCode: '977',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Netherlands (Nederland)',
    iso2: 'nl',
    dialCode: '31',
    priority: 0,
    areaCodes: null,
    currencyCode: 'EUR',
    currencyName: 'euro',
  },
  {
    name: 'New Caledonia (Nouvelle-Calédonie)',
    iso2: 'nc',
    dialCode: '687',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'New Zealand',
    iso2: 'nz',
    dialCode: '64',
    priority: 0,
    areaCodes: null,
    currencyCode: 'NZD',
    currencyName: 'dollar',
  },
  {
    name: 'Nicaragua',
    iso2: 'ni',
    dialCode: '505',
    priority: 0,
    areaCodes: null,
    currencyCode: 'NIO',
    currencyName: 'cordoba oro',
  },
  {
    name: 'Niger (Nijar)',
    iso2: 'ne',
    dialCode: '227',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Nigeria',
    iso2: 'ng',
    dialCode: '234',
    priority: 0,
    areaCodes: null,
    currencyCode: 'NGN',
    currencyName: 'naira',
  },
  {
    name: 'Niue',
    iso2: 'nu',
    dialCode: '683',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Norfolk Island',
    iso2: 'nf',
    dialCode: '672',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'North Korea (조선 민주주의 인민 공화국)',
    iso2: 'kp',
    dialCode: '850',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Northern Mariana Islands',
    iso2: 'mp',
    dialCode: '1670',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Norway (Norge)',
    iso2: 'no',
    dialCode: '47',
    priority: 0,
    areaCodes: null,
    currencyCode: 'NOK',
    currencyName: 'kroner',
  },
  {
    name: 'Oman (‫عُمان‬‎)',
    iso2: 'om',
    dialCode: '968',
    priority: 0,
    areaCodes: null,
    currencyCode: 'OMR',
    currencyName: 'rial',
  },
  {
    name: 'Pakistan (‫پاکستان‬‎)',
    iso2: 'pk',
    dialCode: '92',
    priority: 0,
    areaCodes: null,
    currencyCode: 'PKR',
    currencyName: 'rupee',
  },
  {
    name: 'Palau',
    iso2: 'pw',
    dialCode: '680',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Palestine (‫فلسطين‬‎)',
    iso2: 'ps',
    dialCode: '970',
    priority: 0,
    areaCodes: null,
    currencyCode: 'USD', // ILS, USD, and JOD
    currencyName: 'dollar', // shekel, dollar, and dinar
  },
  {
    name: 'Panama (Panamá)',
    iso2: 'pa',
    dialCode: '507',
    priority: 0,
    areaCodes: null,
    currencyCode: 'USD', // PAB  and USD
    currencyName: 'dollar', // balboa  and dollar
  },
  {
    name: 'Papua New Guinea',
    iso2: 'pg',
    dialCode: '675',
    priority: 0,
    areaCodes: null,
    currencyCode: 'PGK',
    currencyName: 'kina',
  },
  {
    name: 'Paraguay',
    iso2: 'py',
    dialCode: '595',
    priority: 0,
    areaCodes: null,
    currencyCode: 'PYG',
    currencyName: 'guarani',
  },
  {
    name: 'Peru (Perú)',
    iso2: 'pe',
    dialCode: '51',
    priority: 0,
    areaCodes: null,
    currencyCode: 'PEN',
    currencyName: 'nuevo sol',
  },
  {
    name: 'Philippines',
    iso2: 'ph',
    dialCode: '63',
    priority: 0,
    areaCodes: null,
    currencyCode: 'PHP',
    currencyName: 'peso',
  },
  {
    name: 'Poland (Polska)',
    iso2: 'pl',
    dialCode: '48',
    priority: 0,
    areaCodes: null,
    currencyCode: 'PLN',
    currencyName: 'zloty',
  },
  {
    name: 'Portugal',
    iso2: 'pt',
    dialCode: '351',
    priority: 0,
    areaCodes: null,
    currencyCode: 'EUR',
    currencyName: 'euro',
  },
  {
    name: 'Puerto Rico',
    iso2: 'pr',
    dialCode: '1',
    priority: 3,
    areaCodes: ['787', '939'],
    currencyCode: 'USD',
    currencyName: 'dollar',
  },
  {
    name: 'Qatar (‫قطر‬‎)',
    iso2: 'qa',
    dialCode: '974',
    priority: 0,
    areaCodes: null,
    currencyCode: 'QAR',
    currencyName: 'riyal',
  },
  {
    name: 'Réunion (La Réunion)',
    iso2: 're',
    dialCode: '262',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Romania (România)',
    iso2: 'ro',
    dialCode: '40',
    priority: 0,
    areaCodes: null,
    currencyCode: 'RON',
    currencyName: 'leu',
  },
  {
    name: 'Russia (Россия)',
    iso2: 'ru',
    dialCode: '7',
    priority: 0,
    areaCodes: null,
    currencyCode: 'RUB',
    currencyName: 'ruble',
  },
  {
    name: 'Rwanda',
    iso2: 'rw',
    dialCode: '250',
    priority: 0,
    areaCodes: null,
    currencyCode: 'RWF',
    currencyName: 'franc',
  },
  {
    name: 'Saint Barthélemy (Saint-Barthélemy)',
    iso2: 'bl',
    dialCode: '590',
    priority: 1,
    areaCodes: null,
  },
  {
    name: 'Saint Helena',
    iso2: 'sh',
    dialCode: '290',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Saint Kitts and Nevis',
    iso2: 'kn',
    dialCode: '1869',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Saint Lucia',
    iso2: 'lc',
    dialCode: '1758',
    priority: 0,
    areaCodes: null,
    currencyCode: 'XCD',
    currencyName: 'dollar',
  },
  {
    name: 'Saint Martin (Saint-Martin (partie française))',
    iso2: 'mf',
    dialCode: '590',
    priority: 2,
    areaCodes: null,
  },
  {
    name: 'Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)',
    iso2: 'pm',
    dialCode: '508',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Saint Vincent and the Grenadines',
    iso2: 'vc',
    dialCode: '1784',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Samoa',
    iso2: 'ws',
    dialCode: '685',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'San Marino',
    iso2: 'sm',
    dialCode: '378',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'São Tomé and Príncipe (São Tomé e Príncipe)',
    iso2: 'st',
    dialCode: '239',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Saudi Arabia (‫المملكة العربية السعودية‬‎)',
    iso2: 'sa',
    dialCode: '966',
    priority: 0,
    areaCodes: null,
    currencyCode: 'SAR',
    currencyName: 'riyal',
  },
  {
    name: 'Senegal (Sénégal)',
    iso2: 'sn',
    dialCode: '221',
    priority: 0,
    areaCodes: null,
    currencyCode: 'XOF',
    currencyName: 'CFA franc BCEAO',
  },
  {
    name: 'Serbia (Србија)',
    iso2: 'rs',
    dialCode: '381',
    priority: 0,
    areaCodes: null,
    currencyCode: 'RSD',
    currencyName: 'dinar',
  },
  {
    name: 'Seychelles',
    iso2: 'sc',
    dialCode: '248',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Sierra Leone',
    iso2: 'sl',
    dialCode: '232',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Singapore',
    iso2: 'sg',
    dialCode: '65',
    priority: 0,
    areaCodes: null,
    currencyCode: 'SGD',
    currencyName: 'dollar',
  },
  {
    name: 'Sint Maarten',
    iso2: 'sx',
    dialCode: '1721',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Slovakia (Slovensko)',
    iso2: 'sk',
    dialCode: '421',
    priority: 0,
    areaCodes: null,
    currencyCode: 'EUR',
    currencyName: 'euro',
  },
  {
    name: 'Slovenia (Slovenija)',
    iso2: 'si',
    dialCode: '386',
    priority: 0,
    areaCodes: null,
    currencyCode: 'EUR',
    currencyName: 'euro',
  },
  {
    name: 'Solomon Islands',
    iso2: 'sb',
    dialCode: '677',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Somalia (Soomaaliya)',
    iso2: 'so',
    dialCode: '252',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'South Africa',
    iso2: 'za',
    dialCode: '27',
    priority: 0,
    areaCodes: null,
    currencyCode: 'ZAR',
    currencyName: 'rand',
  },
  {
    name: 'South Korea (대한민국)',
    iso2: 'kr',
    dialCode: '82',
    priority: 0,
    areaCodes: null,
    currencyCode: 'KRW',
    currencyName: 'won',
  },
  {
    name: 'South Sudan (‫جنوب السودان‬‎)',
    iso2: 'ss',
    dialCode: '211',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Spain (España)',
    iso2: 'es',
    dialCode: '34',
    priority: 0,
    areaCodes: null,
    currencyCode: 'EUR',
    currencyName: 'euro',
  },
  {
    name: 'Sri Lanka (ශ්‍රී ලංකාව)',
    iso2: 'lk',
    dialCode: '94',
    priority: 0,
    areaCodes: null,
    currencyCode: 'LKR',
    currencyName: 'rupee',
  },
  {
    name: 'Sudan (‫السودان‬‎)',
    iso2: 'sd',
    dialCode: '249',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Suriname',
    iso2: 'sr',
    dialCode: '597',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Svalbard and Jan Mayen',
    iso2: 'sj',
    dialCode: '47',
    priority: 1,
    areaCodes: null,
  },
  {
    name: 'Swaziland',
    iso2: 'sz',
    dialCode: '268',
    priority: 0,
    areaCodes: null,
    currencyCode: 'SZL',
    currencyName: 'lilangeni',
  },
  {
    name: 'Sweden (Sverige)',
    iso2: 'se',
    dialCode: '46',
    priority: 0,
    areaCodes: null,
    currencyCode: 'SEK',
    currencyName: 'krona',
  },
  {
    name: 'Switzerland (Schweiz)',
    iso2: 'ch',
    dialCode: '41',
    priority: 0,
    areaCodes: null,
    currencyCode: 'CHF',
    currencyName: 'franc',
  },
  {
    name: 'Syria (‫سوريا‬‎)',
    iso2: 'sy',
    dialCode: '963',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Taiwan (台灣)',
    iso2: 'tw',
    dialCode: '886',
    priority: 0,
    areaCodes: null,
    currencyCode: 'TWD',
    currencyName: 'dollar',
  },
  {
    name: 'Tajikistan',
    iso2: 'tj',
    dialCode: '992',
    priority: 0,
    areaCodes: null,
    currencyCode: 'TJS',
    currencyName: 'somoni',
  },
  {
    name: 'Tanzania',
    iso2: 'tz',
    dialCode: '255',
    priority: 0,
    areaCodes: null,
    currencyCode: 'TZS',
    currencyName: 'shilling',
  },
  {
    name: 'Thailand (ไทย)',
    iso2: 'th',
    dialCode: '66',
    priority: 0,
    areaCodes: null,
    currencyCode: 'THB',
    currencyName: 'baht',
  },
  {
    name: 'Timor-Leste',
    iso2: 'tl',
    dialCode: '670',
    priority: 0,
    areaCodes: null,
    currencyCode: 'USD',
    currencyName: 'US dollar',
  },
  {
    name: 'Togo',
    iso2: 'tg',
    dialCode: '228',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Tokelau',
    iso2: 'tk',
    dialCode: '690',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Tonga',
    iso2: 'to',
    dialCode: '676',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Trinidad and Tobago',
    iso2: 'tt',
    dialCode: '1868',
    priority: 0,
    areaCodes: null,
    currencyCode: 'TTD',
    currencyName: 'dollar',
  },
  {
    name: 'Tunisia (‫تونس‬‎)',
    iso2: 'tn',
    dialCode: '216',
    priority: 0,
    areaCodes: null,
    currencyCode: 'TND',
    currencyName: 'dinar',
  },
  {
    name: 'Turkey (Türkiye)',
    iso2: 'tr',
    dialCode: '90',
    priority: 0,
    areaCodes: null,
    currencyCode: 'TRY',
    currencyName: 'lira',
  },
  {
    name: 'Turkmenistan',
    iso2: 'tm',
    dialCode: '993',
    priority: 0,
    areaCodes: null,
    currencyCode: 'TMT',
    currencyName: 'manat',
  },
  {
    name: 'Turks and Caicos Islands',
    iso2: 'tc',
    dialCode: '1649',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Tuvalu',
    iso2: 'tv',
    dialCode: '688',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'U.S. Virgin Islands',
    iso2: 'vi',
    dialCode: '1340',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Uganda',
    iso2: 'ug',
    dialCode: '256',
    priority: 0,
    areaCodes: null,
    currencyCode: 'UGX',
    currencyName: 'shilling',
  },
  {
    name: 'Ukraine (Україна)',
    iso2: 'ua',
    dialCode: '380',
    priority: 0,
    areaCodes: null,
    currencyCode: 'UAH',
    currencyName: 'hryvnia',
  },
  {
    name: 'United Arab Emirates (‫الإمارات العربية المتحدة‬‎)',
    iso2: 'ae',
    dialCode: '971',
    priority: 0,
    areaCodes: null,
    currencyCode: 'AED',
    currencyName: 'dirham',
  },
  {
    name: 'United Kingdom',
    iso2: 'gb',
    dialCode: '44',
    priority: 0,
    areaCodes: null,
    currencyCode: 'GBP',
    currencyName: 'sterling',
  },
  {
    name: 'United States',
    iso2: 'us',
    dialCode: '1',
    priority: 0,
    areaCodes: null,
    currencyCode: 'USD',
    currencyName: 'dollar',
  },
  {
    name: 'Uruguay',
    iso2: 'uy',
    dialCode: '598',
    priority: 0,
    areaCodes: null,
    currencyCode: 'UYU',
    currencyName: 'peso',
  },
  {
    name: 'Uzbekistan (Oʻzbekiston)',
    iso2: 'uz',
    dialCode: '998',
    priority: 0,
    areaCodes: null,
    currencyCode: 'UZS',
    currencyName: 'sum',
  },
  {
    name: 'Vanuatu',
    iso2: 'vu',
    dialCode: '678',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Vatican City (Città del Vaticano)',
    iso2: 'va',
    dialCode: '39',
    priority: 1,
    areaCodes: null,
  },
  {
    name: 'Venezuela',
    iso2: 've',
    dialCode: '58',
    priority: 0,
    areaCodes: null,
    currencyCode: 'VEF',
    currencyName: 'bolivar',
  },
  {
    name: 'Vietnam (Việt Nam)',
    iso2: 'vn',
    dialCode: '84',
    priority: 0,
    areaCodes: null,
    currencyCode: 'VND',
    currencyName: 'dong',
  },
  {
    name: 'Wallis and Futuna',
    iso2: 'wf',
    dialCode: '681',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Western Sahara (‫الصحراء الغربية‬‎)',
    iso2: 'eh',
    dialCode: '212',
    priority: 1,
    areaCodes: null,
  },
  {
    name: 'Yemen (‫اليمن‬‎)',
    iso2: 'ye',
    dialCode: '967',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Zambia',
    iso2: 'zm',
    dialCode: '260',
    priority: 0,
    areaCodes: null,
    currencyCode: 'ZMW',
    currencyName: 'kwacha',
  },
  {
    name: 'Zimbabwe',
    iso2: 'zw',
    dialCode: '263',
    priority: 0,
    areaCodes: null,
    currencyCode: 'ZWD',
    currencyName: 'dollar',
  },
  {
    name: 'Åland Islands',
    iso2: 'ax',
    dialCode: '358',
    priority: 1,
    areaCodes: null,
  },
];
