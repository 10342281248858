import { Auth } from 'aws-amplify';
import axios from 'axios';
import { baseUrl, baseDataSearch, cmsUrl } from '../config';

const AuthorizedAxios = axios.create({
  baseURL: baseUrl,
  timeout: 30000,
});

export const cmsWigram = axios.create({
  baseURL: cmsUrl,
  timeout: 30000,
  headers: {
    accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export const MeiliSearch = axios.create({
  baseURL: baseDataSearch,
  timeout: 30000,
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_MEILI_API_KEY}`,
    'Content-Type': 'application/json',
  },
});
const requestHandler = (request: any) => {
  return request;
};

const responseHandler = (response: any) => {
  if (response.status === 401) {
    // window.location = '/login';
  }

  return response;
};

const errorHandler = (error: any) => {
  return Promise.reject(error);
};

AuthorizedAxios.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);

AuthorizedAxios.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
);

export function setToken(token: string) {
  AuthorizedAxios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

export function removeToken() {
  delete AuthorizedAxios.defaults.headers.common['Authorization'];
}

export function addAuthentication({
  onError = () => {},
}: {
  onError?: (err: any) => void;
}) {
  Auth.currentAuthenticatedUser()
    .then((user) => {
      const token = user?.signInUserSession?.accessToken?.jwtToken;
      AuthorizedAxios.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${token}`;
    })
    .catch((er: any) => onError(er));
}

export default AuthorizedAxios;
