import { useEffect, useState } from 'react';
import { SWRConfig } from 'swr';
import { RecoilRoot } from 'recoil';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { pdfjs } from 'react-pdf';

/* eslint-disable eqeqeq */
import { Analytics } from 'aws-amplify';
import Page from 'components/Page';
import { ScreenSettingContext } from 'ScreenSettingContext';
import { ActiveCardContext } from './ActiveCardContext';
import './global.scss';
import useFetchThemes from 'hooks/refactor/useFetchThemes';
import useFetchCountries from 'hooks/refactor/useFetchCountries';

Analytics.startSession();
Analytics.enable();
Analytics.autoTrack('session', {
  enable: true,
  attributes: {
    attr: 'attr',
    test: 'test',
  },
  provider: 'AWSPinpoint',
});
Analytics.autoTrack('pageView', {
  enable: true,
  eventName: 'pageView',
  attributes: {
    attr: 'attr',
  },
  type: 'multiPageApp',
  provider: 'AWSPinpoint',
  getUrl: () => {
    return window.location.origin + window.location.pathname;
  },
});
// WA-541: https://github.com/wojtekmaj/react-pdf/issues/677
pdfjs.GlobalWorkerOptions.workerSrc =
  process.env.PUBLIC_URL + '/pdf.worker.min.js';

const App: React.FC<{ user: CognitoUser }> = ({ user }) => {
  // Data setup
  const [activeCard, setActiveCard] = useState({
    id: null,
    element: null,
    elementId: null,
    initialScale: 1,
    show: false,
    offsetFromTop: 0,
  });

  const { themes } = useFetchThemes();
  const { countries } = useFetchCountries();

  //! Effect
  useEffect(() => {
    const isMobilePage = (document.location.pathname || '')
      .split('/')
      .includes('m');
    if (window.screen.width < 768 && !isMobilePage) {
      document.location = `/m${document.location.pathname}`;
    }
  }, []);

  //! Render
  return (
    <div className='App'>
      <ActiveCardContext.Provider value={{ activeCard, setActiveCard }}>
        <ScreenSettingContext.Provider
          value={{ themes: themes, countries: countries }}
        >
          <SWRConfig
            value={{
              revalidateOnFocus: false,
            }}
          >
            <RecoilRoot>
              <Page user={user} countries={countries} themes={themes} />
            </RecoilRoot>
          </SWRConfig>
        </ScreenSettingContext.Provider>
      </ActiveCardContext.Provider>
    </div>
  );
};
//@ts-ignore
export default App;
