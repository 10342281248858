import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import useSWR from 'swr';

import { longFormsLoadingState } from '../../recoil/atom/longforms';
import cmsApiSerivce from 'services/cmsApiService';
import { IArticle } from 'interface/article.interface';
import { ArticleModel } from 'model/refactor/article.model';
// import { ArticleModel } from 'model/article.model';
import { ArticleType } from 'types/article';
import { IAritcleListData } from 'interface/common.interface';
import { formatQueryParams } from 'utils/urlQuery';
import { AritcleType } from 'utils/constants';

export default function useFecthListData(
  articleType: AritcleType,
  timeRange: { from: string; to: string } | undefined = undefined
) {
  const setLoadingState = useSetRecoilState(longFormsLoadingState);
  const [listArticlesData, setListArticlesData] = useState<ArticleType[]>([]);
  const [totalArticleItems, setTotalArticleItems] = useState<
    number | undefined
  >(undefined);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const canFetchMore = useMemo(() => {
    // For first render
    if (!totalPages) return true;

    if (currentPage > totalPages) return false;
    else return true;
  }, [currentPage, totalPages]);
  const urlKey = useMemo(() => {
    const params = timeRange
      ? {
          populate: ['image', 'country_list.country', 'tag_list.tag'],
          timeRange: timeRange,
          pagination: {
            limit: -1,
          },
        }
      : {
          populate: ['image', 'country_list.country', 'tag_list.tag'],
          pagination: {
            page: currentPage,
          },
        };

    return `${articleType}${formatQueryParams(params)}`;
  }, [currentPage, timeRange, articleType]);

  const { data: result, isLoading } = useSWR(
    canFetchMore ? urlKey : null,
    (url): Promise<IAritcleListData> => {
      return cmsApiSerivce().get(url);
    }
  );

  const fetchNextPage = useCallback(() => {
    setCurrentPage((prev) => prev + 1);
  }, []);

  useEffect(() => {
    if (result) {
      const nextData: any = result.data.map(
        (article: IArticle) => new ArticleModel(article)
      );

      const totalItems = result.meta.pagination.total;
      const totalPages = result.meta.pagination.pageCount;

      setLoadingState(false);
      setTotalArticleItems(totalItems);
      setTotalPages(totalPages);

      setListArticlesData((prevData) => [...prevData, ...nextData]);
    } else {
      if (canFetchMore) setLoadingState(true);
      else setLoadingState(false);
    }
  }, [result, setLoadingState, canFetchMore]);

  return {
    listArticlesData,
    fetchNextPage,
    totalArticleItems,
    isLoading,
  };
}
