import React from 'react';
import './TitleDesc.scss';

const TitleDesc = ({
  title = '',
  description = '',
  date = '',
  macroWatch = false,
}: {
  title: string;
  description: string | React.ReactElement;
  date?: string;
  macroWatch?: boolean;
}) => {
  return (
    <div className='article-title-container'>
      <div className='article-title'>
        <h3>{title}</h3>
        {macroWatch && <h3 className='macro-watch'>MACRO WATCH</h3>}
      </div>
      <div className='article-flex'>
        <div className='article-description'>
          <h1>{description}</h1>
        </div>
        {Boolean(date) ? (
          <div className='article-date'>
            <span>{date}</span>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default TitleDesc;
