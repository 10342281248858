// for each date / column combination, render a list of article cards
import ArticleCards from 'pages/LivePage/ArticleCards/ArticleCards';
import { ArticleCardType } from '../../../types/articleCard';

const RowArticleCards = ({
  date,
  groupBy,
  shortforms,
  longforms,
  macroWatches,
  filtersApplied,
}: ArticleCardType) => {
  if (!date || !filtersApplied) {
    console.warn("'date' and 'filtersApplied' is required for <Row>..", date);
    return null;
  }
  // Don't display the weekend dates (if there are no articles)
  const isWeekend = date.getDay() === 6 || date.getDay() === 0; // sat = 6, sun = 0
  if (isWeekend) {
    // Only do this check if it's a weekend date...
    const count = articleCount({
      filtersApplied,
      date,
      groupBy,
      longforms,
      macroWatches,
      shortforms,
    });
    if (count === 0) {
      return null; // Don't render anything.
    }
  }

  return (
    <>
      <div className='date-cell'>
        <div className='day-of-month'>{date.getDate()}</div>
        {formattedDate(date)}
      </div>

      {filtersApplied.map((columnFiltersApplied: any, index: number) => {
        return (
          <div className='cards-wrapper' key={index} id='cards-wrapper'>
            {ArticleCards({
              longforms,
              macroWatches,
              shortforms,
              date,
              groupBy,
              columnFiltersApplied,
              filtersApplied,
            })}
          </div>
        );
      })}
    </>
  );
};

// Gets the count of articles for a given date so we can determine if we should show
// the row if it is a weekend.
export function articleCount({
  filtersApplied,
  date,
  groupBy,
  longforms,
  macroWatches,
  shortforms,
}: ArticleCardType) {
  let articleCardsCount = 0;
  filtersApplied.forEach((columnFiltersApplied: any) => {
    articleCardsCount += ArticleCards({
      columnFiltersApplied,
      filtersApplied,
      date,
      groupBy,
      longforms,
      macroWatches,
      shortforms,
    }).length;
  });
  return articleCardsCount;
}

// Use US date format, eg Jul 30
export function formattedDate(date: Date) {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  return months[date.getMonth()].toUpperCase();
}

export default RowArticleCards;
