import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
// AWS Auth
import AuthStateApp from 'AuthStateApp';
import Amplify from 'aws-amplify';
import { IKContext } from 'imagekitio-react';
import 'intro.js/introjs.css';
import awsconfig from './aws-exports';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

Amplify.configure(awsconfig);

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <IKContext
        urlEndpoint='https://ik.imagekit.io/wigramdev'
        publicKey='public_WkN3rSth+8ykj7zfxhT0nUe7ypc='
      >
        <AuthStateApp />
      </IKContext>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
serviceWorkerRegistration.register();
