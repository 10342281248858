import { Auth } from 'aws-amplify';
import AWSAlert from 'components/AWSAlert/AWSAlert';
import InputField from 'components/Field/InputField';
import Spinner from 'components/Spinner/Spinner';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import './Login.scss';
import { ROUTE } from './routes';

const validationSchema = yup.object().shape({
  password: yup.string().required('Password is a required field'),
});

const CreatePasswordForm = ({
  onChangeRoute,
  visible,
  loginUser,
}: {
  onChangeRoute: (route: string) => void;
  visible: boolean;
  loginUser: any;
}) => {
  return (
    <Formik
      initialValues={{
        password: '',
        passwordType: 'password',
        errorMessage: '',
      }}
      onSubmit={async (values, { setSubmitting, setFieldValue }) => {
        setSubmitting(true);
        try {
          const createNewPasswordRespone = await Auth.completeNewPassword(
            loginUser, // the Cognito User Object
            values.password // the new password
          );
          if (createNewPasswordRespone) {
            // const currentUserInfo = await Auth.currentUserInfo();
          }
        } catch (error: any) {
          setFieldValue('errorMessage', error?.message);
        } finally {
          setSubmitting(false);
        }
      }}
      validationSchema={validationSchema}
      validateOnBlur
    >
      {(formikProps) => {
        return (
          <>
            {formikProps.values?.errorMessage && (
              <AWSAlert message={formikProps.values?.errorMessage} />
            )}
            <Form>
              <div className={`form-container ${visible ? 'active-form' : ''}`}>
                <h1>Complete new password</h1>
                <p style={{ paddingBottom: '1rem' }}>
                  Welcome:{' '}
                  <strong>
                    {loginUser?.challengeParam?.userAttributes?.email}
                  </strong>
                </p>
                <Field
                  component={InputField}
                  label='Password'
                  name='password'
                  type={formikProps.values.passwordType}
                  isShowPassword
                  onShowPassword={() =>
                    formikProps.setFieldValue(
                      'passwordType',
                      formikProps.values.passwordType !== 'password'
                        ? 'password'
                        : 'text'
                    )
                  }
                />
                <button
                  className='sign-in-button'
                  type='submit'
                  disabled={formikProps.isSubmitting}
                  // disabled={disabled}
                >
                  {formikProps.isSubmitting ? <Spinner /> : 'Finish'}
                </button>
                <div className='form-register__text'>
                  <div>
                    <span>Have an account?</span>
                    <span
                      className='sign-in'
                      onClick={() => onChangeRoute(ROUTE.LOGIN)}
                    >
                      Sign in
                    </span>
                  </div>
                </div>
              </div>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};
export default CreatePasswordForm;
