import { useEffect, useState } from 'react';
import downIcon from '../../images/downIcon.svg';
import addIcon from '../../images/add.svg';
import removeIcon from '../../images/remove.svg';
import { Grid } from '@material-ui/core';
import { isEmpty } from 'lodash';

const Dropdown = ({
  options,
  name,
  isMobile,
  isAll = false,
  onChangeFilter = () => {},
  initialValue = [],
}: {
  options: Array<string>;
  name: string;
  isMobile?: Boolean;
  isAll?: boolean;
  onChangeFilter?: (value: Array<string>) => void;
  initialValue?: Array<string>;
}) => {
  const [showBlogs, setShowBlogs] = useState(false);
  const [selectedValue, setSelectedValue] = useState<string[]>(
    isMobile ? (isEmpty(initialValue) ? options : initialValue) : options
  );
  useEffect(() => {
    if (isMobile) {
      setSelectedValue(initialValue);
    }
  }, [isMobile, initialValue]);

  //! Function
  const onClickItem = (option: string) => {
    let selectedItem = [];
    if (selectedValue.includes(option)) {
      selectedItem = selectedValue.filter((el) => el !== option);
    } else {
      selectedItem = [...selectedValue, option];
    }
    // call api
    onChangeFilter(selectedItem);
    setSelectedValue(selectedItem);
  };

  const handleDropdownOptionClose = () => {
    setShowBlogs(!showBlogs);
  };
  const onClickSelectAll = () => {
    setSelectedValue(options);
    onChangeFilter(options);
  };
  const onClickClearAll = () => {
    setSelectedValue([]);
    onChangeFilter([]);
  };

  //! Render
  const renderValues = () => {
    if (selectedValue.length > 0 && selectedValue.length < options.length) {
      return selectedValue.join(', ');
    }
    if (selectedValue.length === options.length) {
      return 'All';
    }
    return 'None';
  };

  return (
    <div className='blogs'>
      {!isMobile && (
        <p onClick={handleDropdownOptionClose}>
          {name}: <strong className='value'>{renderValues()}</strong>
          <span>
            <img
              src={downIcon}
              alt=''
              className='type-page'
              style={{ cursor: 'pointer' }}
            />
          </span>
        </p>
      )}
      {isMobile && (
        <div className='all-actions'>
          <strong>{name}</strong>
          <p>
            <span onClick={onClickSelectAll}>Select all</span>
            <span onClick={onClickClearAll}>Clear all</span>
          </p>
        </div>
      )}
      {(showBlogs || isMobile) && (
        <Grid
          spacing={2}
          container
          className='drop-down-blogs'
          style={{ height: name === 'Country' ? '400px' : 'auto' }}
        >
          {options.map((option: string) => {
            const isSelected = selectedValue.includes(option);
            return (
              <Grid
                key={option}
                className='item-blog'
                item
                xs={isMobile ? 6 : 12}
                md={12}
                lg={12}
                onClick={() => onClickItem(option)}
              >
                {isSelected ? <strong>{option}</strong> : <span>{option}</span>}

                {isSelected ? (
                  <img src={removeIcon} alt='' style={{ width: '12px' }} />
                ) : (
                  <img src={addIcon} alt='' style={{ width: '12px' }} />
                )}
              </Grid>
            );
          })}
          {!isMobile && (
            <div className='select-item'>
              <hr />
              <div className='all-actions'>
                <span onClick={onClickSelectAll}>Select all</span>
                <span onClick={onClickClearAll}>Clear all</span>
              </div>
            </div>
          )}
        </Grid>
      )}
    </div>
  );
};

export default Dropdown;
