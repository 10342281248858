import Dropdown from './Dropdown';
import DropdownDate from './DropdownDate';
import DropDownDateMobile from './DropDownDateMobile';

const CommonStyles = {
  Dropdown,
  DropdownDate,
  DropDownDateMobile,
};

export default CommonStyles;
