import { useState, useEffect, RefObject } from 'react';
import { ArrowUp } from '../../icons/ArrowUp';
import './index.scss';

const ButtonScrollToTopComponent = ({
  elRef,
  id,
}: {
  elRef: RefObject<HTMLDivElement>;
  id?: string;
}) => {
  const [buttonIsVisible, setButtonIsVisible] = useState(false);

  const scrollToTopComponent = () => {
    elRef.current?.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    const divEl = elRef.current;
    // Button is displayed after scrolling
    const toggleVisibility = () => {
      if (divEl) {
        if (divEl.scrollTop > 100) {
          setButtonIsVisible(true);
        } else {
          setButtonIsVisible(false);
        }
      }
    };

    divEl?.addEventListener('scroll', toggleVisibility);

    return () => divEl?.removeEventListener('scroll', toggleVisibility);
  }, [elRef]);

  return (
    <div id={id} className='button-scroll-top-component'>
      {
        <div
          className={`button-scroll-top-component-inner ${
            buttonIsVisible ? 'is-show' : ''
          }`}
          onClick={scrollToTopComponent}
        >
          <ArrowUp fill='#222222' />
        </div>
      }
    </div>
  );
};

export default ButtonScrollToTopComponent;
