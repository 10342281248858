import { ErrorMessage } from 'formik';
import './field.scss';
// import { ReactComponent as EyeIcon } from 'images/eye-icon.svg';
import { countriesList } from 'pages/Login/countries';

const PhoneInput = ({
  field,
  form,
  label,
  isShowPassword = false,
  type,
  onShowPassword = () => {},
  nameCountry,
}: {
  field: any;
  form: any;
  label: string;
  isShowPassword: boolean;
  type: string;
  onShowPassword: () => void;
  nameCountry: string;
}) => {
  const { name } = field;
  const { touched, errors, setFieldValue } = form;

  const isInvalid = errors[name] && touched[name];

  //   const eyeComponent = () => {
  //     return isShowPassword ? (
  //       <div className='eye-icon' onClick={onShowPassword}>
  //         <EyeIcon />
  //       </div>
  //     ) : null;
  //   };

  return (
    <div>
      <h3>{label}</h3>
      <div className='input-wraper' style={{ display: 'flex', flex: '1 1' }}>
        {/* {eyeComponent()} */}
        <select onChange={(e) => setFieldValue(nameCountry, e.target.value)}>
          {countriesList.map((item, i) => {
            return (
              <option key={i} value={item.dialCode}>
                +{item.dialCode}
              </option>
            );
          })}
        </select>
        <input
          {...field}
          type={type}
          className={`input-custom ${isInvalid && 'invalid'}`}
          style={isShowPassword ? { paddingRight: 40 } : {}}
        />
      </div>
      <ErrorMessage name={name}>
        {(errorMessage) => {
          return <p className='error-text'>{errorMessage}</p>;
        }}
      </ErrorMessage>
    </div>
  );
};

export default PhoneInput;
