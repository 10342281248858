import { IArticle } from 'interface/article.interface';
import { MCountryList } from '../countryList.model';
import { MImage } from '../image.model';
import { MImages } from '../images.model';
import { MTagList } from '../tagList.model';

export class ArticleModel {
  [x: string]: any;
  id?: number | undefined;

  constructor(dataArticle?: IArticle) {
    this.id = undefined;
    this.setData && this.setData(dataArticle);
  }
  setData?(data?: IArticle) {
    this.id = data?.id;
    this.slug = data?.attributes?.slug;
    // this.type = data?.attributes?.type;
    this.images = data?.attributes?.images?.data
      ? data?.attributes?.images?.data
          ?.map((el) => new MImages(el))
          .filter((e) => e)
      : null;

    this.date = data?.attributes?.date;
    this.title = data?.attributes?.title;
    this.tags = data?.attributes.tag_list?.map((el) => {
      return new MTagList(el);
    });
    this.image = new MImage(data?.attributes?.image?.data);
    //new MImage();
    this.createdAt = data?.attributes?.date;
    this.publishedAt = data?.attributes?.date;
    this.updatedAt = data?.attributes?.date;
    this.countries = (data?.attributes.country_list || []).map(
      (el) => new MCountryList(el)
    );
    this.type = data?.attributes?.type;
  }
}
