import MenuSlideOut from 'components/MenuSlideOut/MenuSlideOut';
import { ArticleModel } from 'model/article.model';
import moment from 'moment';
import { getListOfDaysByLatestDateHasArticle } from 'utils/livepage';
import helpIcon from '../../../images/help.svg';
import searchIcon from '../../../images/search.svg';
import logoIcon from '../../../images/logo-wigram-vertical.svg';
import GroupBy from '../GroupBy/GroupBy';
import { articleCount } from '../RowArticleCards/RowArticleCards';
import './RightSideBar.scss';
import SearchInputBox from '../../../components/SearchInputBox/SearchInputBox';
import { useMemo, useState } from 'react';

const RightSideBar = ({
  data,
  groupBy,
  setGroupBy,
  maxHeightOfElement,
  filtersApplied,
  longforms,
  macroWatches,
  shortforms,
  processHeightItem,
  onShowTutorial = () => {},
  add,
}: {
  data?: any;
  groupBy: any;
  setGroupBy: any;
  maxHeightOfElement: Array<number | string>;
  filtersApplied: any;
  longforms: Array<ArticleModel>;
  macroWatches: Array<ArticleModel>;
  shortforms: Array<ArticleModel>;
  processHeightItem: () => void;
  onShowTutorial?: () => void;
  onShowSearch?: () => void;
  add?: any;
}) => {
  // !State
  const [showResults, setShowResults] = useState(false);
  const handleSearch = () => setShowResults(!showResults);
  const allData = useMemo(
    () => [...longforms, ...macroWatches, ...shortforms],
    [longforms, macroWatches, shortforms]
  );
  const listOfDaysData = useMemo(
    () => getListOfDaysByLatestDateHasArticle(add, groupBy, allData),
    [add, groupBy, allData]
  );

  // !Render
  return (
    <>
      {showResults && (
        <SearchInputBox data={data} onShowSearch={handleSearch} />
      )}
      <div className='right-side' id='right-side'>
        <MenuSlideOut />
        <GroupBy
          groupBy={groupBy}
          setGroupBy={setGroupBy}
          processHeightItem={processHeightItem}
        />
        <div className='group-icon'>
          <img
            onClick={handleSearch}
            src={searchIcon}
            alt=''
            id='search-icon'
            style={{
              cursor: 'pointer',
              width: 'fit-content',
              marginBottom: '-10px',
            }}
          />
          <img
            onClick={onShowTutorial}
            src={helpIcon}
            alt=''
            id='help-icon'
            style={{ cursor: 'pointer' }}
          />
          <img src={logoIcon} alt='' />
        </div>
        <div className='rs-content'>
          {listOfDaysData.map((date: any, index: number) => {
            const isEmptyContent = maxHeightOfElement?.[index] < 33;

            if (!date || !filtersApplied) {
              console.warn(
                "'date' and 'filtersApplied' is required for <Row>..",
                date
              );
              return null;
            }
            // Don't display the weekend dates (if there are no articles)
            const arrayWeekend = [6, 5, 4, 3, 2, 1, 0];

            if (arrayWeekend.includes(date.getDay())) {
              // Only do this check if it's a weekend date...
              const count = articleCount({
                filtersApplied,
                date,
                groupBy,
                longforms,
                macroWatches,
                shortforms,
              });

              if (count === 0) {
                return (
                  <div
                    key={index}
                    className='date-cell'
                    style={{
                      height: isEmptyContent
                        ? 0
                        : maxHeightOfElement?.[index] || 'auto',
                    }}
                    id={moment(date).format('DD/MM/YYYY')}
                  />
                );
              }
            }
            return (
              <div
                key={index}
                className='date-cell'
                style={{
                  height: maxHeightOfElement?.[index] || 'auto',
                  paddingTop: isEmptyContent ? '0' : '1rem',
                }}
                id={moment(date).format('DD/MM/YYYY')}
              >
                <p className='date-cell__day'>{date.getDate()}</p>
                <p className='date-cell__month'>
                  {moment(date).format('MMM YY')}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default RightSideBar;
