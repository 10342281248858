import { cmsWigram } from 'api';
import { useState, useEffect } from 'react';

const useNotification = () => {
  const [error, setError] = useState<any>('');
  const [loading, setLoading] = useState<any>(true);

  useEffect(() => {
    const registrationPush = async () => {
      setLoading(true);
      try {
        const serviceWorker = await navigator.serviceWorker.ready;
        const isExisted = await serviceWorker.pushManager.getSubscription();

        // existed -> don't push to server
        if (!isExisted) {
          // subscribe and return the subscription
          const pushKey = await serviceWorker.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: process.env.REACT_APP_PUSH_PUBLIC_KEY,
          });

          const response = await cmsWigram.post('/user-notification-keys', {
            data: { subscription: pushKey.toJSON() },
          });
          localStorage.setItem('res', JSON.stringify(response));
        }
      } catch (error) {
        setError(error);
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    registrationPush();
  }, []);

  useEffect(() => {
    const checkPushNotiChange = async () => {
      setLoading(true);
      try {
        const serviceWorker = await navigator.serviceWorker.ready;

        const permission = await serviceWorker.pushManager.permissionState({
          userVisibleOnly: true,
        });

        const notiKeyRes = localStorage.getItem('res');

        if (notiKeyRes && permission !== 'granted') {
          const parsedRes = JSON.parse(notiKeyRes);

          const notiKeyId = parsedRes.data?.data?.id;

          if (notiKeyId) {
            await cmsWigram
              .delete(`/user-notification-keys/${notiKeyId}`)
              .then((data) => {
                if (data.status === 200) {
                  localStorage.removeItem('res');
                }
              });
          }
        }
      } catch (error) {
        setError(error);
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    checkPushNotiChange();
  }, []);

  return [loading, error];
};

export default useNotification;
