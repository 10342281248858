/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react/macro';
import Button from 'components/Button/Button';
import { ThemeType } from '../../../types/theme';

// TODO: Move to SCSS file
const ThemeSelectorCss = css`
  padding: 1rem;
  background-color: var(--wigram-green-primary);
  p {
    cursor: pointer;
  }
  h1 {
    color: var(--wigram-grey-0);
    font-size: 24px;
    font-weight: 700;
    margin-top: 14px;
    margin-bottom: 14px;
    cursor: pointer;

    &.disabled {
      cursor: not-allowed;
      color: var(--wigram-green-text-disabled-2);
    }
  }

  position: absolute;
  top: 152px; // TODO: Have wrapper set positioning, not component
  z-index: 10;
  // width: 100%;
  width: 360px;
  @media only screen and (min-width: 768px) {
    top: 86px;
    overflow-y: scroll;
    height: calc(100vh - 86px);
  }

  .buttons-wrapper {
    display: flex;
    justify-content: flex-end;
  }
  .text-link {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 32px;
    line-height: 40px;
    font-weight: 700;
    font-family: AntoniaH3, 'AntoniaText', serif;
    @media only screen and (min-width: 768px) {
      font-size: 23px;
      line-height: 26px;
      font-weight: 700;
      padding-bottom: 5px;
    }
  }
`;

const ThemeSelector = ({
  themes,
  currentThemeId, // Exclude the currently selected theme from the selection list
  disabledThemeIds, // Disable all theme that are currently used from the selection list
  onThemeClick,
  onRemoveClick,
  isMobile,
}: {
  themes?: Array<ThemeType>;
  currentThemeId?: number; // Exclude the currently selected theme from the selection list
  disabledThemeIds?: Array<number>; // Disable all theme that are currently used from the selection list
  onThemeClick: (val: number | undefined) => void;
  onRemoveClick?: () => void;
  isMobile?: boolean;
}) => {
  // disabledThemeIds contains exactly one themeId for each column, so we can use it to determine
  // the number of columns. This is used to disabled the 'Remove' button if column count is 1.
  const numberOfColumns = disabledThemeIds?.length || 0;

  return (
    <div css={ThemeSelectorCss}>
      {isMobile && (
        <p
          onClick={() => onThemeClick(undefined)}
          className={!currentThemeId ? 'text-link disabled' : 'text-link'}
        >
          All
        </p>
      )}
      {themes?.map((theme: ThemeType, i: number) => {
        if (currentThemeId === theme.id) {
          return (
            <p key={i} className='text-link disabled'>
              {theme.name}
            </p>
          );
        } else {
          return (
            <p
              className={'text-link'}
              key={i}
              onClick={() => onThemeClick(theme.id)}
            >
              {theme.name}
            </p>
          );
        }
      })}

      {!isMobile && (
        <div className='buttons-wrapper'>
          <Button
            buttonType='underlined'
            onClick={onRemoveClick}
            disabled={numberOfColumns === 1}
          >
            Remove
          </Button>
        </div>
      )}
    </div>
  );
};

export default ThemeSelector;
