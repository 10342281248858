import { IIntroduce } from 'interface/tutorial.interface';
import { Step, Steps } from 'intro.js-react';
import LiveIntroCard from 'pages/LivePage/LiveIntroCard';
import { FunctionComponent, useEffect, useState } from 'react';
import './Tutorial.scss';
interface ITutorial {
  steps: Step[];
  visible: boolean;
  onCloseTutorial: () => void;
  introduce: IIntroduce;
  isMobile?: boolean;
  onChangeStep?: (step: number) => void;
}

const Tutorial: FunctionComponent<ITutorial> = ({
  steps = [],
  visible = false,
  onCloseTutorial = () => {},
  introduce = {},
  isMobile = false,
  onChangeStep = (step) => {},
}) => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [enable, setEnable] = useState<boolean>(false);

  useEffect(() => {
    setCurrentStep(0);
    // if (visible && !enable) {
    //   document.body.style.position = 'fixed';
    // } else {
    //   document.body.style.position = 'static';
    // }
  }, [visible]);

  const closeTutorial = () => {
    setEnable(false);
    onCloseTutorial();
  };

  const showStep = () => {
    if (steps.length < 1) {
      onCloseTutorial();
      setEnable(false);
      return;
    }
    setEnable(true);
  };

  const onNextStep = (s: number) => {
    setCurrentStep(s);
    onChangeStep(s);
  };

  if (!visible) {
    return null;
  }
  if (!enable) {
    return (
      <LiveIntroCard
        isMobile={isMobile}
        nextLiveIntro={showStep}
        closeLiveIntro={closeTutorial}
        title={introduce.title || ''}
        description={introduce?.description || ''}
        visible={visible}
      />
    );
  }
  return (
    <Steps
      steps={steps}
      enabled={enable && visible}
      initialStep={currentStep}
      onExit={closeTutorial}
      onChange={onNextStep}
      options={{
        skipLabel: '',
        nextLabel: '',
        prevLabel: '',
        doneLabel: '',
      }}
    />
  );
};

export default Tutorial;
