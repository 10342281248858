import { useState, useEffect, useMemo } from 'react';
import moment from 'moment';
import { useRecoilState } from 'recoil';

import useFecthListData from './useFetchListData';
import { AritcleType } from 'utils/constants';
import { cloneDeep, uniqBy } from 'lodash';
import { ArticleType } from 'types/article';
import {
  loadingLivePageArticlesState,
  fetchDatesState,
  dateHasNewDataState,
  newArticleIdState,
} from 'recoil/atom/livepage';

const useFetchLivePage = (loadMoreCallback: () => void) => {
  const [isLoadingLivePage, setLoadingLivePage] = useRecoilState(
    loadingLivePageArticlesState
  );
  const [, setFetchedDates] = useRecoilState(fetchDatesState);
  // Get total items of all types to count the maximum items can be fetched
  const { totalArticleItems: totalLongFormsItems } = useFecthListData(
    AritcleType.LONG_FORMS
  );
  const { totalArticleItems: totalMacroItems } = useFecthListData(
    AritcleType.MACRO_WATCHES
  );
  const { totalArticleItems: totalShortFormItems } = useFecthListData(
    AritcleType.SHORT_FORMS
  );
  const [totalArticleItems, setTotalAritcleItems] = useState(0);
  // Time state
  const [date, setDate] = useState(moment().utc().format());
  // Date received from SW
  const [dateHasNewData, setDateHasNewData] =
    useRecoilState(dateHasNewDataState);
  // ID received from SW
  const [newArticleId, setNewArticleId] = useRecoilState(newArticleIdState);

  const fetchingDate = useMemo(() => {
    return dateHasNewData || date;
  }, [date, dateHasNewData]);

  // Data state by time range
  const { listArticlesData: longformsData, isLoading: isLongformsLoading } =
    useFecthListData(AritcleType.LONG_FORMS, {
      from: moment(fetchingDate)
        .startOf('month')
        .set({ hour: 0, minute: 0, second: 0 })
        .utc(true)
        .format(),
      to: moment(fetchingDate)
        .add(2, 'M')
        .startOf('month')
        .set({ hour: 0, minute: 0, second: 0 })
        .utc(true)
        .format(),
    });

  const { listArticlesData: macrosData, isLoading: isMacroLoading } =
    useFecthListData(AritcleType.MACRO_WATCHES, {
      from: moment(fetchingDate)
        .startOf('month')
        .set({ hour: 0, minute: 0, second: 0 })
        .utc(true)
        .format(),
      to: moment(fetchingDate)
        .add(2, 'M')
        .startOf('month')
        .set({ hour: 0, minute: 0, second: 0 })
        .utc(true)
        .format(),
    });

  const { listArticlesData: shortformsData, isLoading: isShortformsLoading } =
    useFecthListData(AritcleType.SHORT_FORMS, {
      from: moment(fetchingDate)
        .startOf('month')
        .set({ hour: 0, minute: 0, second: 0 })
        .utc(true)
        .format(),
      to: moment(fetchingDate)
        .add(2, 'M')
        .startOf('month')
        .set({ hour: 0, minute: 0, second: 0 })
        .utc(true)
        .format(),
    });

  const [isLoadingAllData, setLoadingAllData] = useState(false);
  const [hasMore, setHasMore] = useState<boolean | undefined>(undefined);
  const [livePageArticles, setLivePageArticles] = useState<{
    longforms: ArticleType[];
    shortfroms: ArticleType[];
    macro: ArticleType[];
  }>({ longforms: [], shortfroms: [], macro: [] });

  const handleGetPrevData = () => {
    if (!isLoadingAllData && hasMore && !isLoadingLivePage && !dateHasNewData) {
      setDate(
        moment(date)
          .subtract(2, 'month')
          .set({ hour: 0, minute: 0, second: 0 })
          .utc(true)
          .format()
      );
      setLoadingLivePage(true);
    }
  };

  useEffect(() => {
    setFetchedDates((prevDates) => [
      ...prevDates,
      {
        from: moment(date)
          .startOf('month')
          .set({ hour: 0, minute: 0, second: 0 })
          .utc(true)
          .format(),
        to: moment(date)
          .add(2, 'M')
          .startOf('month')
          .set({ hour: 0, minute: 0, second: 0 })
          .utc(true)
          .format(),
      },
    ]);
  }, [date, setFetchedDates]);

  useEffect(() => {
    if (totalLongFormsItems && totalMacroItems && totalShortFormItems)
      setTotalAritcleItems(
        totalLongFormsItems + totalMacroItems + totalShortFormItems
      );
  }, [totalLongFormsItems, totalMacroItems, totalShortFormItems]);

  useEffect(() => {
    const totalCurrentItems =
      livePageArticles.longforms.length +
      livePageArticles.macro.length +
      livePageArticles.shortfroms.length;

    if (totalArticleItems && totalCurrentItems < totalArticleItems)
      setHasMore(true);
    else setHasMore(false);
  }, [
    totalArticleItems,
    livePageArticles.longforms,
    livePageArticles.shortfroms,
    livePageArticles.macro,
  ]);

  useEffect(() => {
    if (!isLongformsLoading && !isShortformsLoading && !isMacroLoading) {
      setLoadingAllData(false);
    } else setLoadingAllData(true);
  }, [isLongformsLoading, isShortformsLoading, isMacroLoading]);

  useEffect(() => {
    const allArticles = cloneDeep(livePageArticles);
    if (longformsData?.length)
      allArticles.longforms = uniqBy(
        [...allArticles.longforms, ...longformsData],
        'id'
      );
    if (shortformsData?.length)
      allArticles.shortfroms = uniqBy(
        [...allArticles.shortfroms, ...shortformsData],
        'id'
      );
    if (macrosData?.length)
      allArticles.macro = uniqBy([...allArticles.macro, ...macrosData], 'id');

    if (
      allArticles.longforms.length !== livePageArticles.longforms.length ||
      allArticles.macro.length !== livePageArticles.macro.length ||
      allArticles.shortfroms.length !== livePageArticles.shortfroms.length
    ) {
      setLivePageArticles(allArticles);
      loadMoreCallback && loadMoreCallback();
    }
    setLoadingLivePage(false);
  }, [
    longformsData,
    macrosData,
    shortformsData,
    livePageArticles,
    loadMoreCallback,
    setLoadingLivePage,
  ]);

  useEffect(() => {
    const isNewDataInLongform = livePageArticles.longforms.find(
      (item) => item.id === newArticleId
    );
    const isNewDataInShortform = livePageArticles.shortfroms.find(
      (item) => item.id === newArticleId
    );
    const isNewDataInMacro = livePageArticles.macro.find(
      (item) => item.id === newArticleId
    );

    // If new article received from SW has been on live page article list,
    // reset states
    if (isNewDataInLongform || isNewDataInShortform || isNewDataInMacro) {
      setNewArticleId(undefined);
      setDateHasNewData(undefined);
    }
  }, [livePageArticles, newArticleId, setNewArticleId, setDateHasNewData]);

  return {
    totalArticleItems,
    livePageArticles,
    handleGetPrevData,
    isLoadingAllData,
  };
};

export default useFetchLivePage;
