export const ArrowRight = ({ fill }: { fill?: string }) => {
  return (
    <svg
      width='44'
      height='44'
      viewBox='0 0 44 44'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='16.5'
        y='34'
        width='2.20971'
        height='17.6777'
        transform='rotate(-135 16.5 34)'
        fill='#222222'
      />
      <rect
        x='14.9375'
        y='10.5625'
        width='2.20971'
        height='17.6777'
        transform='rotate(-45 14.9375 10.5625)'
        fill='#222222'
      />
    </svg>
  );
};
