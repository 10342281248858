import { Auth } from 'aws-amplify';
import AWSAlert from 'components/AWSAlert/AWSAlert';
import InputField from 'components/Field/InputField';
import Spinner from 'components/Spinner/Spinner';
import { Field, Form, Formik } from 'formik';
import { isDevEnv } from 'utils/enviroment';
import * as yup from 'yup';
import './Login.scss';
import { ROUTE } from './routes';
import { authAnalytics } from 'utils/analytics';

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email('Invalid email')
    .required('Email is a required field'),
  password: yup.string().required('Password is a required field'),
});

const LoginForm = ({
  onChangeRoute,
  visible,
  onSetValueUser,
}: {
  onChangeRoute: (route: string) => void;
  visible: boolean;
  onSetValueUser: (user: any) => void;
}) => {
  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        passwordType: 'password',
        errorMessage: '',
      }}
      onSubmit={async (values, { setSubmitting, setFieldValue }) => {
        setSubmitting(true);
        try {
          const user = await Auth.signIn(values.email, values.password);
          authAnalytics(user, 'LOGIN');

          if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            onSetValueUser(user);
            onChangeRoute(ROUTE.CREATE_NEW_PASSWORD);
            return;
          }
        } catch (error: any) {
          setFieldValue('errorMessage', error?.message);
        } finally {
          setSubmitting(false);
        }
      }}
      validationSchema={validationSchema}
      validateOnBlur
    >
      {(formikProps) => {
        return (
          <>
            {formikProps.values?.errorMessage && (
              <AWSAlert message={formikProps.values?.errorMessage} />
            )}
            <Form name='form-signin'>
              <div className={`form-container ${visible ? 'active-form' : ''}`}>
                <h1>Sign in</h1>
                <Field component={InputField} label='Email' name='email' />
                <div style={{ paddingTop: '1.5rem' }} />
                <Field
                  component={InputField}
                  label='Password'
                  name='password'
                  type={formikProps.values.passwordType}
                  isShowPassword
                  onShowPassword={() =>
                    formikProps.setFieldValue(
                      'passwordType',
                      formikProps.values.passwordType !== 'password'
                        ? 'password'
                        : 'text'
                    )
                  }
                />
                <button
                  className='sign-in-button'
                  type='submit'
                  name='signin-button'
                  disabled={formikProps.isSubmitting}
                  onClick={(e) => {
                    // console.log(e);
                  }}
                  // disabled={disabled}
                >
                  {formikProps.isSubmitting ? <Spinner /> : 'Login'}
                </button>
                <div
                  className={`button-container ${
                    Boolean(isDevEnv) ? '' : 'justify-center'
                  }`}
                >
                  <div
                    id='forgot-password'
                    className='button-wrap'
                    onClick={() => onChangeRoute(ROUTE.FORGOT_PASSWORD)}
                  >
                    <span>Forgot password</span>
                  </div>
                  {Boolean(isDevEnv) && (
                    <div
                      id='register'
                      className='button-wrap'
                      onClick={() => onChangeRoute(ROUTE.REGISTER)}
                    >
                      <span>Register</span>
                    </div>
                  )}
                </div>
              </div>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};
export default LoginForm;
