import { useState, useEffect } from 'react';
import useSWR from 'swr';

import cmsApiSerivce from 'services/cmsApiService';
import { MThemes } from 'model/themes.model';
import { IThemes } from 'interface/themes.interface';

const useFetchThemes = () => {
  const [themes, setThemes] = useState<any[]>([]);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState(1);

  const { data } = useSWR(
    `/tags?pagination[page]=${currentPage}`,
    (url): Promise<IThemes> => cmsApiSerivce().get(url)
  );

  useEffect(() => {
    if (data) {
      const nextData: any = data.data.map((theme) => new MThemes(theme));

      const totalPage = data.meta.pagination.pageCount;

      setTotalPages(totalPage);
      setThemes((prevData) => [...prevData, ...nextData]);
    }
  }, [data]);

  useEffect(() => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  }, [totalPages, currentPage]);

  return {
    themes,
  };
};

export default useFetchThemes;
