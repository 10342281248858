import Intro from 'components/Intro/Intro';
import { IStepArr } from 'interface/tutorial.interface';
import { Step } from 'intro.js-react';
import { ONBOARDING } from './constants';

const createSteps = (stepArr: IStepArr[] = []) =>
  stepArr.map((el: IStepArr) => {
    let steps: Step = {
      element: `#${el.elementId}`,
      intro: <Intro title={el.title} description={el.description} />,
      position: el.position,
      tooltipClass: el.tooltipClass,
      highlightClass: `without-box-shadow ${el.highlightClass}`,
    };
    if (window.innerWidth < 768) {
      delete steps.element;
    }
    return steps;
  });

export { createSteps };

export const showedOnboarding = (page: string) => {
  const localOnBoarding = localStorage.getItem(ONBOARDING) || '';
  const localOnBoardingArr = localOnBoarding?.split(',') || [];
  const newlocal = localOnBoardingArr.filter((e) => e.trim() !== page).join();
  localStorage.setItem(ONBOARDING, newlocal);
  return newlocal;
};
