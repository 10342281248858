import React, { useEffect, useState } from 'react';
import './Login.scss';
import LoginForm from './LoginForm';
import RegisterForm from './RegisterForm';
import ResetPassword from './ResetPassword';
import { ROUTE } from './routes';
import { ReactComponent as LogoWigram } from 'images/logo-wigram.svg';
import OTP from './OTP';
import CreatePasswordForm from './CreatePasswordForm';
import ConfirmSignupForm from './ConfirmSignupForm';
import { ONBOARDING } from 'utils/constants';

const Login = () => {
  const [check, setCheck] = useState(ROUTE.LOGIN);
  const [valueUser, setValueUser] = useState('');
  const [loginUser, setLoginUser] = useState<any>({});

  const onChangeRoutes = (route: string) => {
    setCheck(route);
  };

  const checkForm = () => {
    let Form = null;
    switch (check) {
      case ROUTE.FORGOT_PASSWORD:
        Form = (
          <ResetPassword
            visible={ROUTE.FORGOT_PASSWORD === check}
            onChangeRoute={onChangeRoutes}
            setValueUser={setValueUser}
          />
        );
        break;
      case ROUTE.OTP:
        Form = (
          <OTP
            visible={ROUTE.OTP === check}
            onChangeRoute={onChangeRoutes}
            username={valueUser}
          />
        );
        break;
      case ROUTE.REGISTER:
        Form = (
          <RegisterForm
            visible={ROUTE.REGISTER === check}
            onChangeRoute={onChangeRoutes}
            onSetValueUser={(user) => setLoginUser(user)}
          />
        );
        break;
      case ROUTE.CREATE_NEW_PASSWORD:
        Form = (
          <CreatePasswordForm
            visible={ROUTE.CREATE_NEW_PASSWORD === check}
            onChangeRoute={onChangeRoutes}
            loginUser={loginUser}
          />
        );
        break;
      case ROUTE.OTP_SIGNUP:
        Form = (
          <ConfirmSignupForm
            visible={ROUTE.OTP_SIGNUP === check}
            onChangeRoute={onChangeRoutes}
            loginUser={loginUser}
          />
        );
        break;
      default:
        Form = (
          <LoginForm
            visible={ROUTE.LOGIN === check}
            onChangeRoute={onChangeRoutes}
            onSetValueUser={(user) => setLoginUser(user)}
          />
        );
        break;
    }
    return Form;
  };

  useEffect(() => {
    localStorage.removeItem(ONBOARDING);
    localStorage.removeItem('filtersApplied');
    localStorage.removeItem('groupBy');
  }, []);

  return (
    <div className='login-container'>
      <div className='left-container'>
        <LogoWigram className='logo' name='logoWigram' />
      </div>
      <div className='right-container'>{checkForm()}</div>
    </div>
  );
};

export default Login;
