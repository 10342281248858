import { MeiliSearch } from 'api';
import { IMeiliFilter } from 'interface/meiliFilter.interface';
import { isEmpty } from 'lodash';
import { MDataSearch } from 'model/dataSearch.model';
import { Dispatch, useEffect, useRef, useState } from 'react';

const useGetDataSearch = (
  filter: IMeiliFilter,
  isMobile: Boolean
): [
  any,
  Dispatch<any>,
  boolean,
  () => void,
  boolean,
  boolean,
  (value: boolean) => void,
  any,
  number
] => {
  //! State
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [dataSearch, setDataSearch] = useState<any>([]);
  const [error, setError] = useState<any>([]);
  const refLoading = useRef(true);
  const pageRef = useRef({
    offset: 0,
    limit: 20,
  });

  const meta = useRef(0);

  //! Function
  const changeLoadingSearch = (value: boolean) => {
    setLoadingSearch(value);
  };

  const createIndexUID = (indexUID: string) => {
    switch (indexUID) {
      case 'Long reads':
        return 'longform';
      case 'Live':
        return 'shortform';
      case 'Macro watch':
        return 'macro-watch';
      case 'Country packs':
        return 'country-pack';
      default:
        return 'live';
    }
  };

  //! Effect
  const getApiSearch = async () => {
    if (
      (!isMobile && isEmpty(filter.blogs)) ||
      isEmpty(filter.countriesFilter)
    ) {
      meta.current = 0;
      setDataSearch([]);
      setLoadingSearch(false);
      return;
    }
    refLoading.current = true;
    try {
      const typeFilter = filter.blogs?.map((e) => {
        return `type='${createIndexUID(e)}'`;
      });
      const countryFilter = filter.countriesFilter?.map((e) => {
        return `country_list='${e}'`;
      });
      const dateFilter = filter.dates?.map((e, i) => {
        if (i === 0) {
          return `date<${e}`;
        }
        return `date>${e}`;
      });
      if (isEmpty(filter.valueInputSearch)) {
        setDataSearch(['']);
      }
      const promiseAll = await MeiliSearch.post(
        `/indexes/articles/search`,
        {
          sort: ['date:desc'],
          q: isEmpty(filter.valueInputSearch) ? '' : filter.valueInputSearch,
          filter: dateFilter?.length
            ? [countryFilter, typeFilter, ...dateFilter]
            : [countryFilter, typeFilter],
          ...pageRef.current,
        },
        {
          params: pageRef.current,
        }
      );
      refLoading.current = false;
      setError('');
      meta.current = promiseAll.data.estimatedTotalHits;

      if (pageRef.current.offset === 0) {
        setDataSearch(
          MDataSearch.parseJson(promiseAll.data.hits, filter.valueInputSearch)
        );
      } else {
        setDataSearch([
          ...dataSearch,
          ...MDataSearch.parseJson(
            promiseAll.data.hits,
            filter.valueInputSearch
          ),
        ]);
      }
    } catch (error: any) {
      refLoading.current = false;
      if (error?.response) {
        setError(error?.response?.data.message);
        setDataSearch([]);
        return;
      }
      setError(error);
      setDataSearch([]);
    } finally {
      changeLoadingSearch(false);
    }
  };

  const refFirstTime = useRef(true);

  useEffect(() => {
    if (refFirstTime.current) {
      refFirstTime.current = false;
      return;
    }
    pageRef.current = {
      offset: 0,
      limit: 20,
    };
    getApiSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const onNextPage = () => {
    if (refLoading.current || meta.current === dataSearch.length - 1) return;
    pageRef.current = {
      offset: pageRef.current.offset + pageRef.current.limit,
      limit: 20,
    };
    getApiSearch();
  };

  const isEnd = meta.current === dataSearch.length || Boolean(error);
  //! Render
  return [
    dataSearch,
    setDataSearch,
    refLoading.current,
    onNextPage,
    isEnd,
    loadingSearch,
    changeLoadingSearch,
    error,
    meta.current,
  ];
};
export default useGetDataSearch;
