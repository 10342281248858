import { QueryParamsType } from '../types/queryParam';

export const formatQueryParams = (
  params: QueryParamsType | undefined = undefined,
  excludeDefaultParam: boolean = false
) => {
  const defaultQueryParam =
    '?filters[in_progress][$ne]=true&filters[date][$notNull]=true&sort[0]=date:desc';

  let optionalParam = [];

  if (params) {
    if (params.pagination) {
      if (params.pagination.page)
        optionalParam.push(`pagination[page]=${params.pagination.page}`);

      if (params.pagination.pageSize)
        optionalParam.push(
          `pagination[pageSize]=${params.pagination.pageSize}`
        );

      if (params.pagination.limit && !params.pagination.page)
        optionalParam.push(`pagination[limit]=${params.pagination.limit}`);
    }

    if (params.timeRange) {
      optionalParam.push(`filters[date][$between]=${params.timeRange.from}`);
      optionalParam.push(`filters[date][$between]=${params.timeRange.to}`);
    }

    if (params.populate && params.populate.length)
      optionalParam.push(`populate=${params.populate.toString()}`);
  }

  if (optionalParam.length) {
    if (excludeDefaultParam) return `?${optionalParam.join('&')}`;

    return `${defaultQueryParam}&${optionalParam.join('&')}`;
  }

  return defaultQueryParam;
};
