import { Dialog, Grid } from '@material-ui/core';
import DateRangePicker from 'components/DateRangePicker/DateRangePicker';
import { addDays } from 'date-fns';
import moment from 'moment';
import { useRef, useState } from 'react';
import downIcon from '../../images/downIcon.svg';

const DropdownDate = ({
  options,
  name,
  isMobile,
  onChangeFilter = () => {},
}: {
  options: Array<string>;
  name?: string;
  isMobile?: Boolean;
  onChangeFilter?: (value: string, data?: any) => void;
}) => {
  const [showBlogs, setShowBlogs] = useState(false);
  const [selectedValue, setSelectedValue] = useState<string | number | null>(
    null
  );
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  //! Function
  const onClickItem = (option: string) => {
    if (option === 'Range') {
      setOpen(true);
      setSelectedValue(option);
      return;
    }
    // call api
    onChangeFilter(option);
    setSelectedValue(option);
  };

  const handleDropdownOptionClose = () => {
    setShowBlogs(!showBlogs);
  };

  const timeRef = useRef({
    startDate: new Date(),
    endDate: addDays(new Date(), 0),
    key: 'selection',
  });
  //! Render
  const renderValues = () => {
    if (!selectedValue || selectedValue === 'Anytime') return 'Anytime';
    if (selectedValue === 'Range') {
      return (
        moment(timeRef.current.startDate).format('YYYY/MM/DD') +
        ' - ' +
        moment(timeRef.current.endDate).format('YYYY/MM/DD')
      );
    }
    return selectedValue;
  };

  return (
    <div
      className='blogs'
      style={{
        display: 'flex',
      }}
    >
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        style={{ zIndex: 10000 }}
      >
        <DateRangePicker
          handleClose={handleClose}
          onChangedDate={(data) => {
            timeRef.current = data;
            onChangeFilter('Range', data);
          }}
          initialTime={timeRef.current}
        />
      </Dialog>
      <p
        onClick={handleDropdownOptionClose}
        style={{ fontFamily: 'Avenir', fontSize: '16px' }}
      >
        {name}:
        <strong className='value' style={{ width: '150px' }}>
          {renderValues()}
        </strong>
        <span>
          <img
            src={downIcon}
            alt=''
            className='type-page'
            style={{ cursor: 'pointer' }}
          />
        </span>
      </p>
      {showBlogs && (
        <Grid
          spacing={2}
          container
          className='drop-down-blogs'
          style={{ height: 'auto' }}
        >
          {options.map((option: string) => {
            return (
              <Grid
                key={option}
                className='item-blog'
                item
                xs={isMobile ? 6 : 12}
                md={12}
                lg={12}
                onClick={() => onClickItem(option)}
                style={{ padding: 0 }}
              >
                {option === selectedValue ? (
                  <strong
                    style={{
                      fontFamily: 'Avenir',
                      fontSize: '16px',
                    }}
                  >
                    {option}
                  </strong>
                ) : (
                  <span
                    style={{
                      fontFamily: 'Avenir',
                      fontSize: '16px',
                    }}
                  >
                    {option}
                  </span>
                )}
                <p style={{ opacity: '0' }}>hi</p>
              </Grid>
            );
          })}
        </Grid>
      )}
    </div>
  );
};

export default DropdownDate;
