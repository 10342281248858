import { ICountryList } from 'interface/countryList.interface';

export class MCountryList {
  id: number | undefined;
  [x: string]: any;
  constructor(dataCountryList?: ICountryList) {
    this.setData(dataCountryList);
  }
  setData(data?: ICountryList) {
    this.id = data?.country?.data?.id;
    this.name = data?.country?.data?.attributes?.name;
    this.createdAt = data?.country?.data?.attributes?.createdAt;
    this.updatedAt = data?.country?.data?.attributes?.updatedAt;
  }
}
