/** @jsxImportSource @emotion/react */
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';

import LoadMoreIcon from 'components/LoadMoreIcon';
import Tutorial from 'components/Tutorial/Tutorial';
import { useStepLivePage } from 'hooks/useStepLivePage';
import { ArrowRight } from 'icons/ArrowRight';
import { ReactComponent as IconPlus } from 'icons/plus.svg';
import { produce } from 'immer';
import { ArticleModel } from 'model/article.model';

import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { LIVE } from 'utils/constants';
import { arraymove, getMaxHeightOfElement } from 'utils/helper';
import { showedOnboarding } from 'utils/tutorialStep';
import { useScrollRestore } from '../../hooks/useScrollRestore';
import { CountryType } from '../../types/country';
import { ThemeType } from '../../types/theme';
import { getListOfDaysByLatestDateHasArticle } from '../../utils/livepage';
import DragItem from './DragItem/DragItem';
import RightSideBar from './RightSideBar/RightSideBar';
import useFilters from './useFilters';
import './LivePage.scss';
import useFetchLivePage from 'hooks/refactor/useFetchLivePage';

const RefactorLivePage = ({
  themes,
  countries,
  add,
  isLoading,
  maxHeightOfElement,
  setMaxHeightOfElement,
  setAdd,
}: {
  themes: Array<ThemeType>;
  countries: Array<CountryType>;
  add: number;
  isLoading: boolean;
  maxHeightOfElement: {
    maxHeight: Array<number | string>;
  };
  setAdd: any;
  setMaxHeightOfElement: any;
}) => {
  const { livePageArticles, handleGetPrevData, isLoadingAllData } =
    useFetchLivePage(() => {
      setMaxHeightOfElement({ maxHeight: [] });
      setAdd && setAdd((prevAdd: any) => (prevAdd += 60));
    });

  //! Data
  const filtersData = {
    themes: themes,
    countries: countries,
    analysis: [
      'Longform',
      'Shortform',
      'News',
      'Data points',
      'Articles',
      'MacroWatches',
    ],
  };

  //! State
  const articleSortDate: ArticleModel[] = useMemo(
    () => [
      ...livePageArticles.longforms,
      ...livePageArticles.shortfroms,
      ...livePageArticles.macro,
    ],
    [livePageArticles]
  );

  const [step, , showTutorial, setShowTutorial, introduce] = useStepLivePage({
    articleSortDate,
    isMobile: false,
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cancelAllFilters, setCancelAllFilters] = useState(false);
  const [endOfView, setEndOfView] = useState(false);

  const [showFiltersIndex, setShowFiltersIndex] = useState<number | null>(null);
  const [showThemeSelectorIndex, setShowThemeSelectorIndex] = useState<
    number | null
  >(null);

  const maxColumnsAllowed = filtersData.themes.length;

  const [
    filtersApplied,
    setFiltersApplied,
    groupBy,
    setGroupBy,
    onBoardingGuide,
    setOnboardingGuide,
  ] = useFilters(themes, countries);

  const listOfDaysData = useMemo(
    () => getListOfDaysByLatestDateHasArticle(add, groupBy, articleSortDate),
    [add, groupBy, articleSortDate]
  );

  // !Effect
  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  useEffect(() => {
    if (onBoardingGuide && onBoardingGuide.includes(LIVE)) {
      setTimeout(() => {
        setShowTutorial(true);
        setOnboardingGuide(showedOnboarding(LIVE));
      }, 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onBoardingGuide, setShowTutorial]);

  useScrollRestore();

  // On page load, check if screen size is small screen (mobile), if so redirect to mobile page.
  useEffect(() => {
    if (window.screen.width < 768) {
      document.location = '/m';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadMore = useCallback(() => {
    if (!document?.scrollingElement?.scrollHeight) return;
    if (
      window.innerHeight +
        document.documentElement.scrollTop -
        (document?.scrollingElement?.scrollHeight || 0) >=
      0
    ) {
      handleGetPrevData();
    }
  }, [handleGetPrevData]);

  useEffect(() => {
    const listenerScroll = () => {
      const livePageElm = document.querySelector('#live-page');
      const right = livePageElm?.getBoundingClientRect()?.right || 0;
      const isEndScrollX = Number(right || 0) - window.innerWidth <= 0;
      // vertical scroll
      const isVerticalScroll =
        window.pageYOffset || document.documentElement.scrollTop;
      if (isEndScrollX) {
        setEndOfView(true);
      } else {
        setEndOfView(false);
      }
      if (isVerticalScroll || window.screen.height > window.innerHeight) {
        loadMore();
      }
    };
    listenerScroll();
    window.addEventListener('scroll', listenerScroll);
    return () => {
      window.removeEventListener('scroll', listenerScroll);
    };
  }, [loadMore]);

  useLayoutEffect(() => {
    // re-calculate height of element
    setMaxHeightOfElement(() => {
      const calculator: any = {
        maxHeight: getMaxHeightOfElement({
          data: listOfDaysData,
        }),
      };
      return calculator;
    });

    return () => {
      // Reset height of elements on unmount
      // to avoid case of first load has no article on both 3 types
      // so that user has to scroll down to long empty UI to load data
      setMaxHeightOfElement([]);
    };
  }, [
    listOfDaysData,
    isLoading,
    filtersApplied,
    filtersApplied.length,
    setMaxHeightOfElement,
  ]);

  //! Function
  // TODO: Find another way to solve this. Move useState variables from RowThemeHeaders to index.tsx?
  const updateCancelAllFilters = (event: any) => {
    // All click events of the page bubble up to this top level event
    // so we need to be selective about which events we respond to.
    if (
      event.target.className === 'date-cell' ||
      event.target.className === 'cards-wrapper' ||
      event.target.className === 'page-wrapper'
    ) {
      // Trigger property change in <RowThemeHeaders> so it hides the filters
      setCancelAllFilters(true);
      setTimeout(() => {
        setCancelAllFilters(false);
      }, 500);
    }
  };

  const scrollRight = () => {
    const livePageElm = document.querySelector('#live-page');
    const innerWidth = 360;
    const left = livePageElm?.getBoundingClientRect()?.left || 0;
    window.scrollTo({
      left: (innerWidth || 0) * 3 - left,
      behavior: 'smooth',
    });
  };

  const getListStyle = (isDraggingOver: any) => ({
    backgroundColor: isDraggingOver ? '#11141B' : '#11141B',
    display: 'flex',
  });

  const changeTheme = (themeId: number | undefined, columnIndex: number) => {
    const filterIncludes = filtersApplied.findIndex(
      (el: any) => el.themeId === themeId
    );
    if (filterIncludes === -1) {
      // Set the column theme
      setFiltersApplied(
        produce((draft: any) => {
          draft[columnIndex].savedAt = new Date().getTime();
          draft[columnIndex].themeId = themeId;
        })
      );
      // Then hide the theme selector
      hideThemeSelector();
      return;
    } else {
      const columnSelected = filtersApplied[columnIndex];
      const columnChanged = filtersApplied[filterIncludes];

      const newFilterApplied = [...filtersApplied];

      newFilterApplied[filterIncludes] = columnSelected;
      newFilterApplied[columnIndex] = columnChanged;
      // Set the column theme
      setFiltersApplied(newFilterApplied);
      // Then hide the theme selector
      hideThemeSelector();
      return;
    }
  };

  const changeFilters = (updatedFiltersApplied: any, columnIndex: number) => {
    setFiltersApplied(
      produce((draft: any) => {
        draft[columnIndex].savedAt = new Date().getTime();
        draft[columnIndex].countries = updatedFiltersApplied.countries;
        draft[columnIndex].analysis = updatedFiltersApplied.analysis;
      })
    );
    hideFilters();
  };

  const hideFilters = () => {
    setShowFiltersIndex(null);
  };
  const hideThemeSelector = () => {
    setShowThemeSelectorIndex(null);
  };

  const removeColumn = (columnIndex: number) => {
    const withOutIndex = filtersApplied.filter(
      (_: any, i: number) => i !== columnIndex
    );
    setMaxHeightOfElement({ maxHeight: [] });
    setFiltersApplied(withOutIndex);
    setShowThemeSelectorIndex(null);
    setShowFiltersIndex(null);
  };

  const onHeadingClick = (columnIndex: number) => {
    if (showThemeSelectorIndex === columnIndex) {
      hideThemeSelector();
    } else {
      hideFilters();
      setShowThemeSelectorIndex(columnIndex);
    }
  };

  const addNewColumn = () => {
    const usedThemeIds = filtersApplied.map((column: any) => column.themeId);
    const unusedThemes = filtersData.themes
      .filter((t: any) => !usedThemeIds.includes(t.id))
      .map((t: any) => t.id);
    // resetMaxHeightOfElement({ data: listOfDays(add, groupBy) });
    if (unusedThemes.length > 0) {
      setMaxHeightOfElement({ maxHeight: [] });
      setFiltersApplied(
        produce((draft: any) => {
          draft.push({
            savedAt: new Date().getTime(),
            themeId: unusedThemes[0], // Choose the first unused theme
            countries: filtersData.countries.map((country: any) => country.id),
            analysis: ['Longform', 'Shortform', 'News', 'MacroWatches'],
          });
        })
      );
    } else {
      console.warn('! New column NOT allowed. All themes have been allocated.');
    }
  };

  // !Render
  return (
    <>
      <div className='live-page' id='live-page'>
        <Tutorial
          isMobile={false}
          steps={step}
          visible={showTutorial}
          onCloseTutorial={() => {
            // setOnboardingGuide(false);
            setShowTutorial(false);
          }}
          introduce={introduce}
          onChangeStep={(s) => {
            if (s === step.length - 1) {
              const lastStep = document
                .getElementById(step[s].element?.replace('#', '') || '')
                ?.getClientRects();
              if (lastStep) {
                setTimeout(() => {
                  window.scrollTo(lastStep?.[0].x - 360, lastStep?.[0].y - 100);
                }, 500);
              }
            }
          }}
        />
        <RightSideBar
          data={countries}
          add={add}
          groupBy={groupBy}
          setGroupBy={setGroupBy}
          maxHeightOfElement={maxHeightOfElement.maxHeight}
          filtersApplied={filtersApplied}
          longforms={livePageArticles.longforms}
          shortforms={livePageArticles.shortfroms}
          macroWatches={livePageArticles.macro}
          processHeightItem={() => setMaxHeightOfElement({ maxHeight: [] })}
          onShowTutorial={() => setShowTutorial(true)}
        />
        <>
          {!endOfView && (
            <span
              onClick={scrollRight}
              className='right-arrow'
              id='right-arrow'
            >
              <ArrowRight />
            </span>
          )}
          <div
            onClick={updateCancelAllFilters}
            className='page-wrapper'
            id='page-wrapper'
          >
            <DragDropContext
              onDragEnd={(result, provided) => {
                if (
                  !result.destination ||
                  result.destination?.index === result.source.index
                ) {
                  return;
                }

                setFiltersApplied(
                  arraymove(
                    filtersApplied,
                    result.source.index,
                    result.destination?.index
                  )
                );
              }}
            >
              <Droppable
                key={'droppable'}
                droppableId='droppable'
                direction='horizontal'
              >
                {(provided, snapshot) => {
                  return (
                    <div
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                      {...provided.droppableProps}
                    >
                      {filtersApplied.map(
                        (columnFiltersApplied: any, index: number) => {
                          const theme = filtersData.themes.find(
                            (t: any) => t.id === columnFiltersApplied.themeId
                          );
                          return (
                            <div key={columnFiltersApplied.themeId}>
                              <DragItem
                                add={add}
                                key={columnFiltersApplied.themeId}
                                draggableId={columnFiltersApplied.themeId.toString()}
                                index={index}
                                filtersData={filtersData}
                                columnFiltersApplied={columnFiltersApplied}
                                filtersApplied={filtersApplied}
                                showThemeSelectorIndex={showThemeSelectorIndex}
                                showFiltersIndex={showFiltersIndex}
                                changeTheme={changeTheme}
                                removeColumn={removeColumn}
                                changeFilters={changeFilters}
                                hideFilters={hideFilters}
                                onHeadingClick={onHeadingClick}
                                theme={theme}
                                groupBy={groupBy}
                                setGroupBy={setGroupBy}
                                longforms={livePageArticles.longforms}
                                shortforms={livePageArticles.shortfroms}
                                macroWatches={livePageArticles.macro}
                                maxHeightOfElement={
                                  maxHeightOfElement.maxHeight
                                }
                              />
                            </div>
                          );
                        }
                      )}
                      {provided.placeholder}
                    </div>
                  );
                }}
              </Droppable>
            </DragDropContext>
          </div>
          {filtersApplied.length < maxColumnsAllowed && (
            <div className='drag-item' style={{ backgroundColor: ' #1c2633' }}>
              <div className='drag-item-container add-button'>
                <div className='header-wrapper'>
                  <div className='header-cell'>
                    <div className='add-cell'>
                      <IconPlus
                        // alt='Add new column'
                        onClick={addNewColumn}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      </div>
      <LoadMoreIcon isLoading={isLoadingAllData} />
    </>
  );
};

export default RefactorLivePage;
