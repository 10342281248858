import React, { useEffect, useState } from 'react';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { Auth, Hub } from 'aws-amplify';

import { cookies } from 'utils/cookie';
import { TOKEN } from 'utils/constants';
import App from 'App';
import Login from 'pages/Login/Login';

const AuthStateApp: React.FunctionComponent = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [user, setUser] = useState<CognitoUser | undefined>();

  const listener = (data: any) => {
    switch (data.payload.event) {
      case 'signIn':
        userLogin();
        break;
      case 'signUp':
        break;
      case 'signOut':
        cookies.remove(TOKEN, { path: '/' });
        break;
      case 'signIn_failure':
        break;
      case 'tokenRefresh':
        break;
      case 'tokenRefresh_failure':
        break;
      case 'configured':
        break;
    }
  };

  Hub.listen('auth', listener);

  const userLogin = async () => {
    try {
      Auth.currentAuthenticatedUser()
        .then((user) => {
          const token = user?.signInUserSession?.accessToken?.jwtToken;
          cookies.set(TOKEN, token, { path: '/' });
        })
        .catch((er: any) => console.log(er));
      const currentUserInfo = await Auth.currentUserInfo();
      if (currentUserInfo) {
        setUser(currentUserInfo);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    userLogin();
    Hub.listen('auth', listener);
    return () => {
      Hub.remove('auth', listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return null;
  }

  return !!user ? <App user={user} /> : <Login />;
};
export default AuthStateApp;
