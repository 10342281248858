import ApiService from './apiService';
import { BASE_URLS } from 'utils/constants';

const getCmsBaseUrl = () => {
  let baseUrl: string = BASE_URLS.CMS;

  if (process.env.REACT_APP_STRAPI_URL) {
    let envBaseUrl: string = process.env.REACT_APP_STRAPI_URL as string;
    if (!envBaseUrl.endsWith('/')) {
      baseUrl = envBaseUrl + '/';
    } else {
      baseUrl = envBaseUrl;
    }
  }

  return baseUrl;
};

const cmsApiSerivce = () => ApiService(getCmsBaseUrl());

export default cmsApiSerivce;
