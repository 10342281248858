import ButtonScrollToTopComponent from 'components/ButtonScrollToTopComponent';
import CommonStyles from 'components/CommonStyles';
import LoadmoreComponent from 'components/LoadmoreComponent';
import Spinner from 'components/Spinner/Spinner';
import useGetDataSearch from 'hooks/useDataSearch';
import closeIcon from 'images/close.svg';
import searchIconMain from 'images/searchMain.svg';
import { IMeiliFilter } from 'interface/meiliFilter.interface';
import { cloneDeep, isEmpty } from 'lodash';
import moment from 'moment';
import BlogsItem from 'pages/LivePage/BlogsItem/BlogsItem';
import { createRef, useEffect, useRef, useState } from 'react';
import './searchInputBox.scss';

export const dates = [
  'Anytime',
  'Yesterday',
  'Last 7 days',
  'Last 30 days',
  'Last 3 months',
  'Last 12 months',
  'Range',
];
export const blogs = ['Live', 'Long reads', 'Macro watch', 'Country packs'];

const SearchIputBox = ({
  onShowSearch,
  data,
}: {
  onShowSearch?: () => void;
  data?: any;
}) => {
  //! State
  const countriesFilter = data?.map((e: any) => {
    return e?.name;
  });
  const [valueInputSearch, setValueInputSearch] = useState('');
  const [filter, setFilter] = useState<IMeiliFilter>({
    // blogs: [],
    // dates: [],
    // countriesFilter: [],
    // valueInputSearch,
    blogs,
    dates: [],
    countriesFilter,
    valueInputSearch,
  });

  const [isExpandSearchBox, setIsExpandSearchBox] = useState(true);
  const ref = createRef<HTMLDivElement>();
  const timeoutRef = useRef<any>(null);
  // handler scroll searchbar
  const scrollHandler = (event: React.UIEvent<HTMLDivElement>) => {
    const scrollTop = event.currentTarget.scrollTop;
    if (scrollTop > 10) {
      setIsExpandSearchBox(false);
    } else {
      setIsExpandSearchBox(true);
    }
  };
  //! HOOK
  const [
    dataSearch,
    ,
    loading,
    onNextPage,
    isEnd,
    loadingSearch,
    changeLoadingSearch,
    error,
    hitsNumber,
  ] = useGetDataSearch(filter, false);

  //! Effect
  useEffect(() => {
    const rightSide = document.getElementById('right-side');
    const rsContentEle: HTMLElement | null | undefined =
      rightSide?.querySelector('.rs-content');
    const groupByEle: HTMLElement | null | undefined =
      rightSide?.querySelector('.group-by');

    if (rsContentEle) rsContentEle.style.opacity = '0';
    if (groupByEle) groupByEle.style.opacity = '0';
    document.body.style.overflow = 'hidden';

    return () => {
      if (rsContentEle) rsContentEle.style.removeProperty('opacity');
      if (groupByEle) groupByEle.style.removeProperty('opacity');
      document.body.style.removeProperty('overflow');
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  //! Function
  const onChangeFilterDate = (e: string, dateValue?: any) => {
    let dates: number[] = [];
    switch (e) {
      case 'Yesterday':
        dates = [
          moment().endOf('D').unix() * 1000,
          moment().startOf('D').subtract(1, 'd').unix() * 1000,
        ];
        break;
      case 'Last 7 days':
        dates = [
          moment().endOf('D').unix() * 1000,
          moment().startOf('D').subtract(7, 'd').unix() * 1000,
        ];
        break;
      case 'Last 30 days':
        dates = [
          moment().endOf('D').unix() * 1000,
          moment().startOf('D').subtract(30, 'd').unix() * 1000,
        ];
        break;
      case 'Last 3 months':
        dates = [
          moment().endOf('D').unix() * 1000,
          moment().startOf('D').subtract(3, 'months').unix() * 1000,
        ];
        break;
      case 'Last 12 months':
        dates = [
          moment().endOf('D').unix() * 1000,
          moment().startOf('D').subtract(12, 'months').unix() * 1000,
        ];
        break;
      case 'Range':
        dates = [
          moment(dateValue.endDate).endOf('D').unix() * 1000,
          moment(dateValue.startDate).unix() * 1000,
        ];
        break;
      case 'Anytime':
      default:
        dates = [];
        break;
    }
    setFilter(cloneDeep({ ...filter, dates }));
  };
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);
  useEffect(() => {
    const doc = document!?.getElementById('search-input-Id');
    const docWrap = document!?.getElementById('wrap-search-input-Id');
    const docBtn = document!?.getElementById('button=Scroll-Id');
    if (doc && docWrap) {
      doc.style.width = `calc(100% - ${
        window.innerWidth - docWrap!?.clientWidth
      }px)`;
    }
    if (docBtn) {
      docBtn.style.right = `${window.innerWidth - docWrap!?.clientWidth}px`;
    }
    window.addEventListener('resize', (e: any) => {
      if (doc && docWrap) {
        doc.style.width = `calc(100% - ${
          window.innerWidth - docWrap!?.clientWidth
        }px)`;
      }
      if (docBtn) {
        docBtn.style.right = `${window.innerWidth - docWrap!?.clientWidth}px`;
      }
    });
  }, [dataSearch]);
  const refd = useRef<any>(null);
  useEffect(() => {}, [refd]);
  //! Render
  return (
    <div
      id='wrap-search-input-Id'
      className={
        isEmpty(dataSearch)
          ? 'wrap-search-input disabled-scroll'
          : 'wrap-search-input'
      }
      ref={ref}
      onScroll={scrollHandler}
    >
      <div
        id='search-input-Id'
        className={`search-input ${isExpandSearchBox ? '' : 'is-small'}`}
      >
        <p>Showing {hitsNumber} results for</p>
        <div className='wrap-box-input'>
          <input
            className='nosubmit'
            type='search'
            onChange={(event) => {
              if (!loadingSearch) changeLoadingSearch(true);

              if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
              }
              timeoutRef.current = setTimeout(() => {
                setFilter({ ...filter, valueInputSearch: event.target.value });
              }, 700);
            }}
          />
          <img src={searchIconMain} alt='' className='search-icon' />
          {valueInputSearch && (
            <img
              src={closeIcon}
              alt=''
              className='input-close-icon'
              onClick={() => setValueInputSearch('')}
            />
          )}
        </div>
        <img
          onClick={onShowSearch}
          src={closeIcon}
          alt=''
          className='close-icon'
        />
        <div className='bottom-wrap'>
          <div className='bottom-wrap-dropdown'>
            <CommonStyles.Dropdown
              onChangeFilter={(blogs) =>
                setFilter(cloneDeep({ ...filter, blogs }))
              }
              options={blogs}
              name='Type'
              isAll
            />
            <CommonStyles.Dropdown
              options={countriesFilter}
              isAll
              name='Country'
              onChangeFilter={(countriesFilter) =>
                setFilter(cloneDeep({ ...filter, countriesFilter }))
              }
            />
            <CommonStyles.DropdownDate
              options={dates}
              onChangeFilter={onChangeFilterDate}
              name='Date'
            />
          </div>
        </div>
      </div>
      <div className='container-page'>
        {((!loading && isEmpty(dataSearch)) || error) && (
          <div
            style={{
              color: 'white',
              width: '100%',
              textAlign: 'center',
              fontSize: 'larger',
            }}
          >
            {error || 'Oops no data here, please try another filter'}
          </div>
        )}
        {loadingSearch ? (
          <div
            style={{
              width: '100%',
              textAlign: 'center',
              fontSize: 'larger',
            }}
          >
            <Spinner />
          </div>
        ) : (
          <>
            <BlogsItem dataSearchList={dataSearch} />
            {!isEnd && <LoadmoreComponent onNextPage={onNextPage} />}
          </>
        )}
      </div>
      <ButtonScrollToTopComponent id='button=Scroll-Id' elRef={ref} />
    </div>
  );
};

export default SearchIputBox;
