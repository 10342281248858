import React, { FC, memo, ReactElement } from 'react';
import Modal from '@material-ui/core/Modal';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    close: {
      display: 'flex',
      width: '100%',
      justifyContent: 'flex-end',
      cursor: 'pointer',
    },
  })
);

const CustomModal: FC<{
  children: ReactElement;
  open: boolean;
  handleClose: () => void;
}> = ({ children, open, handleClose }) => {
  const [modalStyle] = React.useState(getModalStyle);
  const classes = useStyles();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby='simple-modal-title'
      aria-describedby='simple-modal-description'
      style={{ zIndex: 5004 }}
    >
      <div style={modalStyle} className={classes.paper}>
        <div className={classes.close} onClick={handleClose}>
          ✕
        </div>
        {children}
      </div>
    </Modal>
  );
};

export default memo(CustomModal);
