import { ErrorMessage } from 'formik';
import './field.scss';
import { ReactComponent as EyeIcon } from 'images/eye-icon.svg';

const InputField = ({
  field,
  form,
  label,
  isShowPassword = false,
  type,
  onShowPassword = () => {},
}: {
  field: any;
  form: any;
  label: string;
  isShowPassword: boolean;
  type: string;
  onShowPassword: () => void;
}) => {
  const { name } = field;
  const { touched, errors } = form;

  const isInvalid = errors[name] && touched[name];

  const eyeComponent = () => {
    return isShowPassword ? (
      <div className='eye-icon' onClick={onShowPassword}>
        <EyeIcon />
      </div>
    ) : null;
  };

  return (
    <div>
      <h3>{label}</h3>
      <div className='input-wraper'>
        {eyeComponent()}
        {/* <select>
          {countriesList.map((item, i) => {
            return (
              <>
                <option key={i} value={item.dialCode}>
                  {item.dialCode}
                </option>
                <Field
                  component={InputField}
                  label='Phone Number *'
                  name={item.name}
                />
              </>
            );
          })}
        </select> */}
        <input
          {...field}
          type={type}
          className={`input-custom ${isInvalid && 'invalid'}`}
          style={isShowPassword ? { paddingRight: 40 } : {}}
        />
      </div>
      <ErrorMessage name={name}>
        {(errorMessage) => {
          return <p className='error-text'>{errorMessage}</p>;
        }}
      </ErrorMessage>
    </div>
  );
};

export default InputField;
