import './GroupBy.scss';
import { ReactComponent as IconGroupByChevron } from 'icons/group-by-chevron.svg';
import { useState } from 'react';
import { GROUP_BY } from 'utils/constants';

function GroupBy({
  groupBy,
  setGroupBy,
  processHeightItem,
}: {
  groupBy: string;
  setGroupBy: (val: string) => void;
  processHeightItem: () => void;
}) {
  const [showGroupBy, setShowGroupBy] = useState(false);

  function onGroupByClick(groupByType: string) {
    processHeightItem();
    setGroupBy(groupByType);
    setShowGroupBy(false);
  }
  return (
    <div className='group-by' onClick={() => setShowGroupBy(!showGroupBy)}>
      <div className='current-group-by'>{groupBy}</div>
      <IconGroupByChevron />

      {showGroupBy && (
        <GroupByDropdown currentGroupBy={groupBy} onClick={onGroupByClick} />
      )}
    </div>
  );
}

const GroupByDropdown = ({
  currentGroupBy,
  onClick,
}: {
  currentGroupBy: string;
  onClick: (val: string) => void;
}) => {
  return (
    <div className='group-by-dropdown'>
      {GROUP_BY.map((option, i) => (
        <div
          key={i}
          onClick={() => onClick(option)}
          className={option === currentGroupBy ? 'current' : ''}
        >
          {option}
        </div>
      ))}
    </div>
  );
};

export default GroupBy;
