import { useEffect, useState } from 'react';

export default function useOnScreen(
  ref: any,
  threshold: number | number[] | undefined = [0]
) {
  const [isIntersecting, setIntersecting] = useState(false);
  const options: IntersectionObserverInit = { threshold };
  const observer = new IntersectionObserver(
    ([entry]) => setIntersecting(entry.isIntersecting),
    options
  );

  useEffect(() => {
    observer.observe(ref.current);
    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect();
    };
  }, []); //eslint-disable-line

  return isIntersecting;
}
