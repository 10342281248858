function getComputedBaseUrl(): string {
  var result: string = 'https://api-dev.wigramcap.com/';
  if (process.env.REACT_APP_API_URL) {
    var envBaseUrl: string = process.env.REACT_APP_API_URL as string;
    if (!envBaseUrl.endsWith('/')) {
      result = envBaseUrl + '/';
    } else {
      result = envBaseUrl;
    }
  }
  console.log('-> Computed API base URL: ' + result);
  return result;
}
function getMeiliSearch(): string {
  let result: string = 'https://search-dev.wigramcap.com/';
  if (process.env.REACT_APP_MEILI_HOST) {
    let envBaseUrl: string = process.env.REACT_APP_MEILI_HOST as string;
    if (!envBaseUrl.endsWith('/')) {
      result = envBaseUrl + '/';
    } else {
      result = envBaseUrl;
    }
  }
  console.log('-> Computed API base URL: ' + result);
  return result;
}
function getCmsBaseUrl(): string {
  let result: string = 'https://cms-dev.wigramcap.com/api';
  if (process.env.REACT_APP_STRAPI_URL) {
    let envBaseUrl: string = process.env.REACT_APP_STRAPI_URL as string;
    if (!envBaseUrl.endsWith('/')) {
      result = envBaseUrl + '/';
    } else {
      result = envBaseUrl;
    }
  }
  console.log('-> Computed API base URL: ' + result);
  return result;
}

export const baseDataSearch = getMeiliSearch();
export const baseUrl = getComputedBaseUrl();
export const cmsUrl = getCmsBaseUrl();
