import { Auth } from 'aws-amplify';
import AWSAlert from 'components/AWSAlert/AWSAlert';
import InputField from 'components/Field/InputField';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import './Login.scss';
import './RegisterForm.scss';
import { ROUTE } from './routes';

const validationSchema = yup.object().shape({
  username: yup.string().required('Username is a required field'),
});

const ResetPassword = ({
  onChangeRoute,
  visible,
  setValueUser,
}: {
  onChangeRoute: (route: string) => void;
  visible: boolean;
  setValueUser: any;
}) => {
  return (
    <Formik
      initialValues={{
        username: '',
        errorMessage: {
          heading: 'Error',
          variation: 'error',
          message: '',
        },
      }}
      onSubmit={(values, { setSubmitting, setFieldValue }) => {
        const { username } = values;
        const error1 = 'User password cannot be reset in the current state.';
        const error2 = 'Cannot reset password as account has not been set up.';

        setSubmitting(true);
        setTimeout(() => {
          Auth.forgotPassword(username)
            .then((data) => {
              setFieldValue('errorMessage', {
                heading: 'Success',
                variation: 'success',
                message: 'Reset password success',
              });
              setValueUser(username);
              setTimeout(() => onChangeRoute(ROUTE.OTP), 1000);
            })
            .catch((error) => {
              setFieldValue('errorMessage', {
                variation: 'error',
                message: error.message === error1 ? error2 : error?.message,
              });
            })

            .finally(() => {
              setSubmitting(false);
            });
        }, 500);
      }}
      validationSchema={validationSchema}
      validateOnBlur
    >
      {(formikProps) => {
        return (
          <>
            {formikProps.values?.errorMessage?.message && (
              <AWSAlert
                // @ts-ignore
                variation={
                  formikProps.values?.errorMessage.variation || 'error'
                }
                heading={formikProps.values?.errorMessage.heading || 'Error'}
                message={formikProps.values?.errorMessage?.message}
              />
            )}
            <Form>
              <div className={`form-container ${visible ? 'active-form' : ''}`}>
                <h1>Reset password</h1>
                <Field
                  component={InputField}
                  label={`Username*`}
                  name='username'
                />
                <div style={{ paddingTop: '1.5rem' }} />

                <div className='form-register__text'>
                  <div onClick={() => onChangeRoute(ROUTE.LOGIN)}>
                    <span className='sign-in'>Back to Sign In</span>
                  </div>
                  <div>
                    <button
                      className='sign-in-button'
                      type='submit'
                      disabled={formikProps.isSubmitting}
                    >
                      {formikProps.isSubmitting ? (
                        <div className='loader' />
                      ) : (
                        'Send code'
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};
export default ResetPassword;
