export const LONG_FORM = 'Longform';
export const SHORT_FORM = 'Shortform';
export const NEWS = 'News';
export const MACRO_WATCH = 'MacroWatches';
export const LIVE = 'Live';
export const COUNTRY_PACK = 'Countrypack';
export const ANALYSIS: Array<string> = [
  LONG_FORM,
  SHORT_FORM,
  NEWS,
  MACRO_WATCH,
];
export const TEXT_SHORT_FORM = 'SHORT_FORM';
export const TEXT_MACRO_WATCH = 'MACRO_WATCH';
export const TEXT_LONG_FORM = 'LONG_FORM';
export const TEXT_COUNTRY_PACK = 'COUNTRY_PACK';

// group by
export const WEEKS = 'Weeks';
export const MONTHS = 'Months';
export const GROUP_BY: Array<string> = [WEEKS, MONTHS];
export const ONBOARDING = 'ONBOARDING';

// default base urls
export const BASE_URLS = {
  CMS: 'https://cms-dev.wigramcap.com/api',
  MEILI_SEARCH: 'https://search-dev.wigramcap.com/',
  COMPUTED: 'https://api-dev.wigramcap.com',
};

export enum AritcleType {
  LONG_FORMS = 'longforms',
  SHORT_FORMS = 'shortforms',
  MACRO_WATCHES = 'macro-watches',
}
export const TOKEN = 'token';
export const REFRESH_TOKEN = 'refresh_token';
