import { Suspense, lazy, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { useSWRConfig } from 'swr';
import { CognitoUser } from 'amazon-cognito-identity-js';
import moment from 'moment';

import { AritcleType } from 'utils/constants';
import cmsApiSerivce from 'services/cmsApiService';
import {
  fetchDatesState,
  dateHasNewDataState,
  newArticleIdState,
} from 'recoil/atom/livepage';
import { IAritcleData } from 'interface/common.interface';
import LoadingOverlay from 'components/LoadingOverlay';
import useNotification from 'hooks/useNotification';
import RefactorLivePage from 'pages/LivePage/RefactorLivePage';

const LegalsPage = lazy(() => import('pages/LegalsPage/LegalsPage'));
const ProfilePage = lazy(() => import('pages/ProfilePage/ProfilePage'));
const ReportBug = lazy(() => import('pages/ReportBug/ReportBug'));
// const RefactorLivePage = lazy(() => import('pages/LivePage/RefactorLivePage'));
const RefactorCountryPacksPage = lazy(
  () => import('pages/CountryPacksPage/RefactorCountryPacksPage')
);
const RefactorLongformPageWrapper = lazy(
  () => import('pages/LongformPage/RefactorLongformWrapper')
);
const RefactorShortformWrapper = lazy(
  () => import('pages/ShortformPage/RefactorShortformWrapper')
);
const RefactorCountryPackWrapper = lazy(
  () => import('pages/CountryPackPage/RefactorCountryPackWrapper')
);
const RefactorLivePageMobile = lazy(
  () => import('pages/LivePage/RefactorLivePageMobile')
);
const RefactorLongformListPageMobile = lazy(
  () => import('pages/LongformListPage/RefactorLongFormListPageMobile')
);
const RefactorLongformListPage = lazy(
  () => import('pages/LongformListPage/RefactorLongformListPage')
);
const RefactorLongformPrintPageWrapper = lazy(
  () => import('pages/LongformPrintPage/RefactorLongformPrintPage')
);
const PlotlyChart = lazy(() => import('chart/PlotlyChart'));
const ChartPackId = lazy(() => import('chart/ChartPackId'));
const ScrollToTop = lazy(() => import('ScrollToTop'));
const ButtonScrollToTop = lazy(() => import('components/ButtonScrollToTop'));
const ChagePass = lazy(() => import('pages/ProfilePage/ChagePass'));
const ContactUs = lazy(() => import('pages/ContactUs/ContactUs'));
const ClearStorage = lazy(() => import('pages/ClearStorage'));

type PageType = {
  countries: any[];
  themes: any[];
  user: CognitoUser;
};

const Page = ({ countries, user, themes }: PageType) => {
  const { cache, mutate } = useSWRConfig();
  const [add, setAdd] = useState(60);
  const [fetchedDates] = useRecoilState(fetchDatesState);
  const [, setDateHasNewData] = useRecoilState(dateHasNewDataState);
  const [, setNewArticleId] = useRecoilState(newArticleIdState);
  const [loading] = useNotification();

  const [maxHeightOfElement, setMaxHeightOfElement] = useState<{
    maxHeight: Array<number | string>;
  }>({ maxHeight: [] });

  useEffect(() => {
    if (navigator?.serviceWorker) {
      navigator?.serviceWorker.addEventListener('message', (event) => {
        if (
          event.data.msg &&
          ['macro-watch', 'longform', 'shortform'].includes(event.data.msg.type)
        ) {
          let dataType: string = '';

          switch (event.data.msg.type) {
            case 'macro-watch':
              dataType = AritcleType.MACRO_WATCHES;
              break;
            case 'longform':
              dataType = AritcleType.LONG_FORMS;
              break;
            case 'shortform':
              dataType = AritcleType.SHORT_FORMS;
              break;
            default:
              dataType = '';
          }

          if (
            dataType &&
            event.data.msg.metadata &&
            event.data.msg.metadata.id
          ) {
            const fetchArticle = async (): Promise<IAritcleData> => {
              return await cmsApiSerivce().get(
                `${dataType}/${event.data.msg.metadata.id}`
              );
            };

            fetchArticle()
              .then(async (article) => {
                const date = article.data.attributes.date;
                const id = article.data.id;

                const timeRange = fetchedDates.find((item) =>
                  moment(date)
                    .utc()
                    .isBetween(
                      moment(item.from).utc(true),
                      moment(item.to).utc(true)
                    )
                );

                if (timeRange) setDateHasNewData(timeRange?.from);
                setNewArticleId(id);

                return dataType;
              })
              .then((dataType) => {
                if (!(cache instanceof Map)) {
                  throw new Error(
                    'matchMutate requires the cache provider to be a Map instance'
                  );
                }
                const keys: string[] = [];
                const checkWords = [dataType, 'pagination[page]'];
                Array.from(cache.keys()).forEach((key: string) => {
                  if (checkWords.every((word) => key.includes(word))) {
                    keys.push(key);
                  }
                });
                const mutations = keys.map((key) => mutate(key));
                return Promise.all(mutations);
              });
          }
        }
      });
    }
  }, [fetchedDates, setDateHasNewData, setNewArticleId, cache, mutate]);

  const longFormComponent = () => {
    return (
      <ScrollToTop>
        <RefactorLongformListPage countries={countries} />
        <ButtonScrollToTop />
      </ScrollToTop>
    );
  };

  const macroWatchesComponent = () => {
    return (
      <ScrollToTop>
        <RefactorLongformListPage isMacro countries={countries} />
        <ButtonScrollToTop />
      </ScrollToTop>
    );
  };

  const longFormMobileComponent = () => {
    return (
      <ScrollToTop>
        <RefactorLongformListPageMobile countries={countries} />
        <ButtonScrollToTop />
      </ScrollToTop>
    );
  };

  const macroWatchesMobileComponent = () => {
    return (
      <ScrollToTop>
        <RefactorLongformListPageMobile isMacro countries={countries} />
        <ButtonScrollToTop />
      </ScrollToTop>
    );
  };

  return (
    <>
      <Router>
        <Suspense fallback={<LoadingOverlay />}>
          <>
            <Switch>
              <Route exact path='/report-bug'>
                <ReportBug user={user} />
              </Route>
              <Route exact path='/profile'>
                <ProfilePage user={user} />
              </Route>
              <Route exact path='/change-pass'>
                <ChagePass />
              </Route>
              <Route exact path='/legals'>
                <LegalsPage />
              </Route>
              <Route exact path='/contact-us'>
                <ContactUs />
              </Route>
              <Route path='/clear'>
                <ClearStorage />
              </Route>
              {/* Use component prop to force re-render */}
              {/* https://stackoverflow.com/questions/48150567/react-router-difference-between-component-and-render */}
              <Route
                exact
                path='/longforms'
                component={longFormComponent}
              ></Route>
              <Route exact path='/longform/:slug'>
                <ScrollToTop>
                  <RefactorLongformPageWrapper />
                </ScrollToTop>
              </Route>
              <Route exact path='/longformprint/:slug'>
                <RefactorLongformPrintPageWrapper />
              </Route>
              <Route exact path='/shortform/:slug'>
                <ScrollToTop>
                  <RefactorShortformWrapper />
                </ScrollToTop>
              </Route>
              <Route
                exact
                path='/macrowatch'
                component={macroWatchesComponent}
              ></Route>
              <Route exact path='/macrowatch/:slug'>
                <ScrollToTop>
                  <RefactorLongformPageWrapper isMacro />
                </ScrollToTop>
              </Route>
              <Route exact path='/macrowatchprint/:slug'>
                <RefactorLongformPrintPageWrapper isMacro />
              </Route>
              <Route exact path='/country-packs'>
                <RefactorCountryPacksPage countries={countries} />
                <ButtonScrollToTop />
              </Route>
              <Route exact path='/country-pack/:id'>
                <ScrollToTop>
                  <RefactorCountryPackWrapper />
                </ScrollToTop>
              </Route>
              {/* Mobile */}
              <Route exact path='/m/report-bug'>
                <ScrollToTop>
                  <ReportBug isMobile user={user} />
                </ScrollToTop>
              </Route>
              <Route exact path='/m/legals'>
                <ScrollToTop>
                  <LegalsPage isMobile />
                </ScrollToTop>
              </Route>
              <Route exact path='/m/profile'>
                <ScrollToTop>
                  <ProfilePage user={user} isMobile />
                </ScrollToTop>
              </Route>
              <Route exact path='/m/change-pass'>
                <ScrollToTop>
                  <ChagePass isMobile />
                </ScrollToTop>
              </Route>
              <Route exact path='/m/contact-us'>
                <ContactUs isMobile />
              </Route>
              <Route
                exact
                path='/m/longforms'
                component={longFormMobileComponent}
              ></Route>
              <Route exact path='/m/longform/:slug'>
                <ScrollToTop>
                  <RefactorLongformPageWrapper isMobile />
                </ScrollToTop>
              </Route>
              <Route
                exact
                path='/m/macrowatch'
                component={macroWatchesMobileComponent}
              ></Route>
              <Route exact path='/m/macrowatch/:slug'>
                <ScrollToTop>
                  <RefactorLongformPageWrapper isMacro isMobile />
                </ScrollToTop>
              </Route>
              <Route exact path='/m/country-packs'>
                <ScrollToTop>
                  <RefactorCountryPacksPage countries={countries} isMobile />
                  <ButtonScrollToTop />
                </ScrollToTop>
              </Route>
              <Route exact path='/m/shortform/:slug'>
                <ScrollToTop>
                  <RefactorShortformWrapper isMobile />
                </ScrollToTop>
              </Route>
              <Route exact path='/m/country-pack/:id'>
                <ScrollToTop>
                  <RefactorCountryPackWrapper isMobile />
                </ScrollToTop>
              </Route>
              <Route exact path='/m'>
                <RefactorLivePageMobile
                  themes={themes}
                  countries={countries}
                  add={add}
                  setAdd={setAdd}
                />
                <ButtonScrollToTop />
              </Route>
              <Route
                exact
                path='/charts/:id'
                render={(props) => <PlotlyChart id={props.match.params.id} />}
              />
              <Route
                exact
                path='/chart_packs/:id'
                render={(props) => <ChartPackId id={props.match.params.id} />}
              />
              {/* Most general routes last */}
              <Route path='/'>
                <RefactorLivePage
                  themes={themes}
                  countries={countries}
                  add={add}
                  isLoading={false}
                  maxHeightOfElement={maxHeightOfElement}
                  setMaxHeightOfElement={setMaxHeightOfElement}
                  setAdd={setAdd}
                />
                <ButtonScrollToTop />
              </Route>
            </Switch>
          </>
        </Suspense>
      </Router>
      {loading && <LoadingOverlay />}
    </>
  );
};

export default Page;
