import { ArticleModel } from 'model/refactor/article.model';

import ArticleCard from 'pages/LivePage/ArticleCard/ArticleCard';
import { MONTHS, WEEKS } from 'utils/constants';
import { ArticleCardType } from '../../../types/articleCard';

const ArticleCards = ({
  columnFiltersApplied,
  filtersApplied,
  date,
  groupBy,
  longforms,
  macroWatches,
  shortforms,
}: ArticleCardType) => {
  // step 1) Filter articles
  // step 2) add 'article_type' property so we know how to
  //         render the article (as they need to be combined to sort by time)
  // step 3) convert date string to js date object

  let filteredLongforms: Array<ArticleModel> = [];
  let filteredMacroWatches: Array<ArticleModel> = [];

  if (columnFiltersApplied.analysis.includes('Longform')) {
    filteredLongforms =
      longforms
        ?.filter((lf: ArticleModel) =>
          containsTheme(lf, columnFiltersApplied, filtersApplied)
        )
        .filter((lf: ArticleModel) => containsDate(lf, date, groupBy))
        .filter((lf: ArticleModel) =>
          containsCountries(lf, columnFiltersApplied?.countries)
        )
        .map((lf: ArticleModel) => {
          lf.article_type = 'longform';
          lf.date = new Date(lf.date);
          return lf;
        }) || [];
  }

  if (columnFiltersApplied.analysis.includes('MacroWatches')) {
    filteredMacroWatches =
      macroWatches
        ?.filter((mw: ArticleModel) =>
          containsTheme(mw, columnFiltersApplied, filtersApplied)
        )
        .filter((mw: ArticleModel) => containsDate(mw, date, groupBy))
        .filter((mw: ArticleModel) =>
          containsCountries(mw, columnFiltersApplied?.countries)
        )
        .map((mw: ArticleModel) => {
          mw.article_type = 'MacroWatches';
          mw.date = new Date(mw.date);
          return mw;
        }) || [];
  }

  const filteredShortforms =
    shortforms
      ?.filter((sf: ArticleModel) =>
        containsTheme(sf, columnFiltersApplied, filtersApplied)
      )
      .filter((sf: ArticleModel) => containsDate(sf, date, groupBy))
      .filter((sf: ArticleModel) =>
        containsCountries(sf, columnFiltersApplied?.countries)
      )
      .filter((sf: ArticleModel) =>
        columnFiltersApplied.analysis
          .map((t: string) => {
            // The filter values don't match article.type values so we need to transform the values
            // 'Shortform' => 'analysis'
            // 'News' => 'news'
            return t === 'Shortform' ? sf.type : t.toLowerCase();
          })
          .includes(sf.type)
      )
      .map((sf: ArticleModel) => {
        sf.article_type = 'shortform';
        sf.date = new Date(sf.date);
        return sf;
      }) || [];

  const articles = [
    ...filteredLongforms,
    ...filteredShortforms,
    ...filteredMacroWatches,
  ];

  // Sort all articles: show the most recent first
  articles.sort((a, b) => b.date.getTime() - a.date.getTime());

  return articles.map((article, i) => (
    <ArticleCard article={article} key={i} />
  ));
};

function containsTheme(
  article: any,
  columnFiltersApplied: any,
  filtersApplied: any
) {
  if (article && columnFiltersApplied && filtersApplied) {
    // If the article only has one theme, then simply check if it matches the current column theme.
    if (article.tags.length === 1) {
      return article.tags[0].id === columnFiltersApplied.themeId;
    }
    // If an article has multiple themes (eg 'Inflation', 'Policy'), then we should only return it if the first matched
    // column (eg 'Inflation'), provided this column is shown. This avoids duplicates being shown.
    else if (article.tags.length > 1) {
      const visibleThemeIds = filtersApplied.map(
        (column: any) => column.themeId
      );
      const articleThemesVisible = article.tags.filter((t: any) =>
        visibleThemeIds.includes(t.id)
      );
      return articleThemesVisible[0]?.id === columnFiltersApplied.themeId;
    }
  }
  return false;
}

// An article can be associated to many countries
// If it contains at one (or more) of the countries we are filtering by then return it
function containsCountries(article: any, countries: Array<any>) {
  let countryMatch = false;
  countries.forEach((country) => {
    if (article.countries.map((c: any) => c.id).includes(country))
      countryMatch = true;
  });
  return countryMatch;
}

export function containsDate(
  article: any,
  dateToCompare?: Date,
  groupBy?: string,
  ignoreProgress?: boolean
) {
  // If article is in progress, then we don't need to worry about the date, so just return true;
  if (!ignoreProgress && article.in_progress) return true;

  let dateToCompareUp = dateToCompare?.setHours(0, 0, 0, 0);
  let articleDate = new Date(article.date);
  articleDate.setHours(0, 0, 0, 0);

  // Group by 'Days'
  if (groupBy === 'Days') {
    // compares epoch date format, eg: 1619704800000 === 1619704800000
    return articleDate.getTime() === dateToCompareUp;
  }

  // Group by 'Weeks'
  else if (groupBy === WEEKS) {
    const startDate = dateToCompare;
    const endDate = addDays(startDate, 7);
    return (
      startDate &&
      articleDate.getTime() < endDate &&
      articleDate.getTime() >= startDate?.getTime()
    );
  }

  // Group by 'Months'
  else if (groupBy === MONTHS && dateToCompare) {
    const startDate = dateToCompare;

    let y = new Date(startDate).getFullYear();
    let m = new Date(startDate).getMonth();
    // To get the last day of month, we can add 1 to month and set day as 0.
    // It seems a bit strange, but it works
    let endDate = new Date(y, m + 1, 0).getTime();
    return (
      // Because a month can has 31 days, we need to check on day 31 as well
      articleDate.getTime() <= endDate &&
      articleDate.getTime() >= startDate.getTime()
    );
  }

  // Unknown
  else {
    console.error(
      `Invalid groupBy passed to containsDate(). Must be 'Days', 'Weeks' or 'Months' but got ${groupBy}`
    );
  }
}

function addDays(date?: Date, days?: number) {
  if (!date) return 0;
  let result = new Date(date);
  result.setDate(result.getDate() + (days || 0));
  return result.getTime();
}

export default ArticleCards;
