import { ITagList } from 'interface/tagList.interface';

export class MTagList {
  id: number | undefined;
  [x: string]: any;
  constructor(dataTagList?: ITagList) {
    this.setData(dataTagList);
  }
  setData(data?: ITagList) {
    this.id = data?.tag?.data?.id;
    this.name = data?.tag?.data?.attributes?.name;
    this.createdAt = data?.tag?.data?.attributes?.createdAt;
    this.updatedAt = data?.tag?.data?.attributes?.updatedAt;
    this.order = data?.tag?.data?.attributes?.order;
  }
}
