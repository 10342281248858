import { MDataSearch } from 'model/dataSearch.model';
import { Link, useLocation } from 'react-router-dom';
import BlogItem from './BlogItem';
import './BlogItem.scss';

interface IProps {
  dataSearchList: MDataSearch[];
  isMobile?: boolean;
}

const BlogsItem = (props: IProps) => {
  const location = useLocation();
  const { dataSearchList } = props;
  const linkToArticle = (item: MDataSearch) => {
    const addMobile =
      location.pathname.includes('/m') && !location.pathname.includes('macro')
        ? '/m/'
        : '/';
    switch (item.type) {
      case 'country-pack':
        let idPost =
          typeof item.id === 'number'
            ? String(item.id)
            : item.id?.split('-')?.[2];
        return `${addMobile}country-pack/${idPost}`;
      case 'shortform':
        return `${addMobile}shortform/${item.slug}`;
      case 'macro-watch':
        return `${addMobile}macrowatch/${item.slug}`;
      case 'longform':
      default:
        return `${addMobile}longform/${item.slug}`;
    }
  };
  return (
    <>
      {dataSearchList?.map((item) => {
        return (
          <Link key={item.id} to={linkToArticle(item)} target='_blank'>
            <BlogItem
              capital={item?.country_list}
              title={item.type === 'country-pack' ? item?.country : item?.title}
              date={item?.date}
              isMobile={props.isMobile}
              article={item.type}
              img={item?.image}
              detail={item?.summary}
            />
          </Link>
        );
      })}
    </>
  );
};

export default BlogsItem;
