import { CountryType } from 'types/country';

const countryName = (arrayCountry: Array<CountryType> = []) => {
  let name = '';
  arrayCountry.forEach((elm: any, index: number) => {
    if (index === 0) {
      name += `${elm.name}`;
    } else if (arrayCountry.length - 1 === index) {
      name += ` & ${elm.name}`;
    } else {
      name += `, ${elm.name}`;
    }
  });
  return name;
};

export default countryName;
