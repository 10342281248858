import { cookies } from 'utils/cookie';
import { TOKEN } from 'utils/constants';
import coumputedApiService from './computedApiService';

const authApiService = (useDefaultHeader: boolean = true) => {
  const token = cookies.get(TOKEN);
  return coumputedApiService(token, useDefaultHeader);
};

export default authApiService;
