export interface TypeBrackets {
  textContents: any[];
  itemsDetected: any[];
}

export interface ResultDetectTextInBrackets {
  brackets: string;
  curlyBrackets?: string;
}

export const checkAndGetValueBetweenBrackets = (text = '') => {
  const regex = /\[(.*?)\]/g;
  const textFound = text.match(regex);
  if (textFound) {
    const result: TypeBrackets = (textFound || []).reduce(
      (obj: TypeBrackets, el) => {
        const textContent = el.replace('[', '').replace(']', '');
        obj = {
          ...obj,
          textContents: obj?.textContents
            ? [...obj?.textContents, textContent]
            : [textContent],
          itemsDetected: obj?.itemsDetected
            ? [...obj?.itemsDetected, el]
            : [el],
        };

        return obj;
      },
      { textContents: [], itemsDetected: [] }
    );
    return result;
  }
  return;
};

export const checkAndGetValueBetweenCurlyBrackets = (text = '') => {
  const regex = /\{(.*?)\}/g;
  const textFound = text.match(regex);
  if (textFound) {
    const result: TypeBrackets = textFound.reduce(
      (obj: TypeBrackets, el) => {
        const textContent = el.replace('{', '').replace('}', '');
        obj = {
          ...obj,
          textContents: obj?.textContents
            ? [...obj?.textContents, textContent]
            : [textContent],
          itemsDetected: obj?.itemsDetected
            ? [...obj?.itemsDetected, el]
            : [el],
        };

        return obj;
      },
      { textContents: [], itemsDetected: [] }
    );

    return result;
  }
  return;
};

export const detectTextInBracketsAndCurly = (text: string) => {
  const curlyBrackets = checkAndGetValueBetweenCurlyBrackets(text);

  const result = curlyBrackets?.textContents.reduce((arr, text) => {
    const brackets = checkAndGetValueBetweenBrackets(text);
    const itemBracketDetected = brackets?.itemsDetected?.[0] || '';
    const textWithoutBrackets = text.replace(itemBracketDetected, '');

    let tempObj: ResultDetectTextInBrackets = {
      brackets: '',
      curlyBrackets: '',
    };

    tempObj.brackets = brackets?.textContents?.[0] || '';
    tempObj.curlyBrackets = textWithoutBrackets;

    arr = [...arr, tempObj];
    return arr;
  }, []);

  return result;
};

export const isJsonString = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};
