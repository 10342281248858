import Spinner from 'components/Spinner/Spinner';
import useOnScreen from 'hooks/useVisible';
import { useEffect, useRef } from 'react';

const LoadmoreComponent = ({
  onNextPage = () => {},
}: {
  onNextPage?: () => void;
}) => {
  //! defined
  const ref = useRef<any>();

  //! Hooks
  const isVisible = useOnScreen(ref);

  //! Effect
  useEffect(() => {
    if (isVisible) {
      onNextPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  //! Render
  return (
    <div className='spinner' ref={ref}>
      <Spinner />
    </div>
  );
};

export default LoadmoreComponent;
