import { MImage } from 'model/image.model';
import moment from 'moment';
import { optimisedImageUrl } from 'Utility';
import './BlogItem.scss';

const BlogItem = ({
  capital,
  title,
  detail,
  article,
  date,
  img,
  isMobile,
}: {
  capital?: string[];
  title?: string;
  detail?: string;
  article?: string;
  date?: string;
  img?: MImage;
  isMobile?: Boolean;
}) => {
  let nDate = moment(date).format('DD MMM YYYY');
  let nDateM = moment(date).format('D MMM YYYY');
  const image = optimisedImageUrl(img);
  return (
    <>
      {isMobile ? (
        <div className='card wrap-box-mobile is-clickable'>
          <span className='text-country'>{capital}</span>
          <h3 className='text-title'>{title}</h3>
          <div className='wrap-text'>
            <h4 className='text-article'>{article}</h4>
            <span>{nDateM}</span>
          </div>
        </div>
      ) : (
        <>
          <div className='wrap-box'>
            <img src={image} alt='' />
            <div className='wrap-text'>
              <span>{capital}</span>
              <h3>{title}</h3>
              <p>{detail}</p>
            </div>
            <p className='time-article'>
              <span>{article}</span>
              <span>{nDate}</span>
            </p>
          </div>
          <hr />
        </>
      )}
    </>
  );
};

export default BlogItem;
