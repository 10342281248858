import { Dialog, Grid } from '@material-ui/core';
import DateRangePicker from 'components/DateRangePicker/DateRangePicker';
import { addDays } from 'date-fns';
import moment from 'moment';
import { useRef, useState } from 'react';
import downIcon from '../../images/downIcon.svg';

const DropDownDateMobile = ({
  options,
  onChangeFilter = () => {},
}: {
  options: Array<string>;
  onChangeFilter?: (value: string, data?: any) => void;
}) => {
  const [showBlogs, setShowBlogs] = useState(false);
  const [selectedValue, setSelectedValue] = useState<string | number | null>(
    null
  );
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  //! Function
  const onClickItem = (option: string) => {
    if (option === 'Range') {
      setOpen(true);
      setSelectedValue(option);
      handleDropdownOptionClose();
      return;
    }
    // call api
    onChangeFilter(option);
    setSelectedValue(option);
    handleDropdownOptionClose();
  };

  const handleDropdownOptionClose = () => {
    setShowBlogs(!showBlogs);
  };

  const timeRef = useRef({
    startDate: new Date(),
    endDate: addDays(new Date(), 0),
    key: 'selection',
  });
  //! Render
  const renderValues = () => {
    if (!selectedValue || selectedValue === 'Anytime') return 'Anytime';
    if (selectedValue === 'Range') {
      return (
        moment(timeRef.current.startDate).format('YYYY/MM/DD') +
        ' - ' +
        moment(timeRef.current.endDate).format('YYYY/MM/DD')
      );
    }
    return selectedValue;
  };

  return (
    <>
      <div className='reset-date'>
        <strong>Date</strong>
        <p onClick={() => setSelectedValue('Anytime')}>Reset</p>
      </div>
      <div className='blogs-mobile'>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
          style={{ zIndex: 10000 }}
        >
          <DateRangePicker
            handleClose={handleClose}
            onChangedDate={(data) => {
              timeRef.current = data;
              onChangeFilter('Range', data);
            }}
            initialTime={timeRef.current}
          />
        </Dialog>
        <p onClick={handleDropdownOptionClose}>
          <strong className='value'>{renderValues()}</strong>
          <span>
            <img
              src={downIcon}
              alt=''
              className='type-page'
              style={{ cursor: 'pointer' }}
            />
          </span>
        </p>
      </div>
      {showBlogs && (
        <Grid spacing={1} container className='drop-down-blogs-mobile'>
          {options.map((option: string) => {
            return (
              <Grid
                key={option}
                className='item-blog'
                item
                xs={6}
                md={12}
                lg={12}
                onClick={() => onClickItem(option)}
              >
                {option === selectedValue ? (
                  <strong>{option}</strong>
                ) : (
                  <span>{option}</span>
                )}
              </Grid>
            );
          })}
        </Grid>
      )}
    </>
  );
};

export default DropDownDateMobile;
