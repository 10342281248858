import Spinner from 'components/Spinner/Spinner';
import './LoadingOverlay.scss';

const LoadingOverlay = () => {
  return (
    <div className='overlay'>
      <div className='spinner'>
        <Spinner />
      </div>
    </div>
  );
};

export default LoadingOverlay;
