import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { authAnalytics } from 'utils/analytics';
import { TOKEN } from 'utils/constants';
import { cookies } from 'utils/cookie';
import { handleAuthSignOut } from 'utils/helper';

function ApiService(
  baseUrl: string,
  token?: string,
  useDefaultHeader: boolean = true
) {
  const instance = axios.create(
    useDefaultHeader
      ? {
          baseURL: baseUrl,
          timeout: 30000,
          headers: {
            accept: 'application/json',
            'Content-Type': 'application/json',
          },
        }
      : {
          baseURL: baseUrl,
          timeout: 30000,
        }
  );

  if (token) {
    instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }

  //TODO: Config interceptors
  instance.interceptors.response.use(
    (response) => response.data,
    (error) => Promise.reject(error)
  );

  instance.interceptors.request.use((config: any) => {
    return new Promise((resolve, reject) => {
      Auth.currentSession()
        .then((session) => {
          let newToken;
          const idTokenExpire = session.getIdToken().getExpiration();
          const refreshToken = session.getRefreshToken();
          const currentTimeSeconds = Math.round(+new Date()) / 1000;

          if (idTokenExpire < currentTimeSeconds) {
            Auth.currentAuthenticatedUser().then((res) => {
              res.refreshSession(
                refreshToken,
                (err: any, newSession: CognitoUserSession) => {
                  if (err) {
                    handleAuthSignOut(err);
                  } else {
                    newToken = newSession.getIdToken().getJwtToken();
                    cookies.set(TOKEN, newToken, { path: '/' });

                    Auth.currentUserInfo().then((user) => {
                      authAnalytics(user, 'REFRESH');
                    });

                    config.headers.Authorization = `Bearer ${newToken}`;
                    resolve(config);
                  }
                }
              );
            });
          } else {
            if (token) {
              config.headers.Authorization = `Bearer ${token}`;
            }
            resolve(config);
          }
        })
        .catch(() => {
          resolve(config);
        });
    });
  });

  return instance;
}
export default ApiService;
