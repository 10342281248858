import Spinner from 'components/Spinner/Spinner';

const LoadMoreIcon = ({ isLoading = false }) => {
  if (!isLoading) return null;
  return (
    <div
      style={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100vw',
        background: 'var(--wigram-blue-2)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '1rem',
      }}
    >
      <Spinner />
    </div>
  );
};

export default LoadMoreIcon;
