/** @jsxImportSource @emotion/react */
import { ReactComponent as IconUpChevronGreen } from 'icons/up-chevron-green.svg';
import { ArticleModel } from 'model/article.model';
import moment from 'moment';
import React, { useMemo } from 'react';
import {
  Draggable,
  DraggingStyle,
  NotDraggingStyle,
} from 'react-beautiful-dnd';
import { getListOfDaysByLatestDateHasArticle } from 'utils/livepage';
import ArticleCards from '../ArticleCards/ArticleCards';
import Filters from '../Filters/Filters';
import { articleCount } from '../RowArticleCards/RowArticleCards';
import ThemeSelector from '../ThemeSelector/ThemeSelector';
import './DragItem.scss';

// eslint-disable-next-line no-empty-pattern
const DragItem = ({
  // key,
  draggableId,
  index,
  filtersData,
  columnFiltersApplied,
  filtersApplied,
  showThemeSelectorIndex,
  showFiltersIndex,
  changeTheme = () => {},
  removeColumn,
  changeFilters,
  hideFilters = () => {},
  onHeadingClick = () => {},
  theme = {},
  // body
  groupBy = {},
  setGroupBy,
  longforms = [],
  macroWatches = [],
  shortforms = [],
  cssItem,
  maxHeightOfElement = [],
  add,
}: {
  // key: any;
  draggableId: string;
  index: number;
  filtersData: any;
  columnFiltersApplied: any;
  filtersApplied: any;
  showThemeSelectorIndex: number | null;
  showFiltersIndex: number | null;
  changeTheme: (themeId: number | undefined, index: number) => void;
  removeColumn: (index: number) => void;
  changeFilters: (updatedFiltersApplied: any, index: number) => void;
  hideFilters: () => void;
  onHeadingClick: (index: number) => void;
  theme: any;
  // body
  groupBy: any;
  setGroupBy: any;
  longforms: Array<ArticleModel>;
  macroWatches: Array<ArticleModel>;
  shortforms: Array<ArticleModel>;
  cssItem?: any;
  maxHeightOfElement: Array<number | string>;
  add?: any;
}) => {
  //   const grid = 8;

  const allData = useMemo(
    () => [...longforms, ...macroWatches, ...shortforms],
    [longforms, macroWatches, shortforms]
  );
  const listOfDaysData = useMemo(
    () => getListOfDaysByLatestDateHasArticle(add, groupBy, allData),
    [add, groupBy, allData]
  );

  const getItemStyle = (
    isDragging: boolean,
    draggableStyle: DraggingStyle | NotDraggingStyle | undefined
  ) =>
    ({
      width: 360,
      background: isDragging ? '#1c2633' : '#1c2633',
      ...draggableStyle,
    } as any);

  // 11141B

  const headerDragItem = () => {
    return (
      <div className='header-wrapper' id={`header-wrapper-${index}`}>
        {showThemeSelectorIndex === index && (
          <ThemeSelector
            themes={filtersData.themes}
            currentThemeId={columnFiltersApplied.themeId}
            disabledThemeIds={filtersApplied.map((f: any) => f.themeId)}
            onThemeClick={(themeId: number | undefined) =>
              changeTheme(themeId, index)
            }
            onRemoveClick={() => removeColumn(index)}
          />
        )}

        {showFiltersIndex === index && (
          <Filters
            columnFiltersApplied={columnFiltersApplied}
            filtersData={filtersData}
            onApply={(updatedFiltersApplied) =>
              changeFilters(updatedFiltersApplied, index)
            }
            onCancel={hideFilters}
          />
        )}
        <div className='header-cell'>
          <h1 onClick={() => onHeadingClick(index)}>
            {theme.name}
            <span className='svg-wrapper'>
              <IconUpChevronGreen />
            </span>
          </h1>
        </div>
      </div>
    );
  };

  const bodyDragItem = () => {
    return (
      <div className='body-wrapper'>
        {listOfDaysData.map((date: any, index: number) => {
          if (!date || !filtersApplied) {
            console.warn(
              "'date' and 'filtersApplied' is required for <Row>..",
              date
            );
            return null;
          }
          // Don't display the weekend dates (if there are no articles)

          const isWeekend = date.getDay() === 6 || date.getDay() === 0; // sat = 6, sun = 0
          if (isWeekend) {
            // Only do this check if it's a weekend date...
            const count = articleCount({
              filtersApplied,
              date,
              groupBy,
              longforms,
              macroWatches,
              shortforms,
            });

            if (count === 0) {
              return null; // Don't render anything.
            }
          }
          const isEmptyContent = maxHeightOfElement?.[index] < 33;
          return (
            <div
              style={{
                height: isEmptyContent
                  ? 0
                  : maxHeightOfElement?.[index] || 'auto',
                padding: isEmptyContent ? 0 : '1rem 0.7rem 1rem 0.7rem',
              }}
              className='cards-wrapper'
              key={index}
              id={moment(date).format('DD/MM/YYYY')}
            >
              {ArticleCards({
                longforms,
                macroWatches,
                shortforms,
                date,
                groupBy,
                columnFiltersApplied,
                filtersApplied,
              })}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <Draggable key={theme.themeId} draggableId={draggableId} index={index}>
      {(provided, snapshot) => (
        <div className='drag-item'>
          <div className='drag-item-container'>
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={getItemStyle(
                snapshot.isDragging,
                provided.draggableProps.style
              )}
            >
              {headerDragItem()}
              {bodyDragItem()}
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default React.memo(DragItem);
