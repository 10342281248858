import moment from 'moment';
import { MONTHS, WEEKS } from './constants';
import { ArticleModel } from 'model/article.model';

export function listOfDays(
  daysAgo: number,
  groupBy: string,
  currentList: Array<any> = [],
  latestDate?: string
): any {
  if (daysAgo === -1) return currentList.reverse();
  const dateToAdd = moment(latestDate).toDate() || new Date();
  dateToAdd.setDate(dateToAdd.getDate() - daysAgo);

  // const isSaturday = dateToAdd.getDay() === 6; // sat = 6, sun = 0
  const isFirstDayOfWeek = dateToAdd.getDay() === 1; // sat = 6, sun = 0
  const isFirstDayOfMonth = dateToAdd.getDate() === 1; // getDate(), 1 - 31
  // Include every day. We will filter out weekends in <RowArticleCards>
  if (groupBy === 'Days') {
    currentList.push(dateToAdd);
  }
  // Only include Saturdays as we want to group by week Saturday to Saturday
  // The business reason for this is that when clients come into work on Monday and view
  // Wigram they should see any content that was published over the weekend.
  // else if (groupBy === WEEKS && isSaturday) {
  else if (groupBy === WEEKS && isFirstDayOfWeek) {
    currentList.push(dateToAdd);
  }
  // We should only return the first day of the months, eg 1st May, 1st June etc
  else if (groupBy === MONTHS && isFirstDayOfMonth) {
    currentList.push(dateToAdd);
  }
  return listOfDays(
    daysAgo - 1,
    groupBy,
    currentList,
    moment(latestDate).format()
  );
}

export function hasDisplayedTheme(article: any, filtersApplied: Array<any>) {
  return (
    intersect(
      article.tags.map((t: any) => t.id),
      filtersApplied.map((c) => c.themeId)
    ).length > 0
  );
}

// Given 2 arrays, returns the common values.
function intersect(array1: Array<any>, array2: Array<any>) {
  var t;
  if (array2.length > array1.length) {
    t = array2;
    array2 = array1;
    array1 = t;
  } // indexOf to loop over shorter
  return array1.filter(function (e) {
    return array2.indexOf(e) > -1;
  });
}

export function getListOfDaysByLatestDateHasArticle(
  daysAgo: number,
  groupBy: string,
  allData: ArticleModel[]
) {
  const latestDateHasData = allData.reduce((acc: string, cur) => {
    if (!acc) return cur.date;

    if (cur.date && moment(cur.date) && moment(acc)) {
      return moment(cur.date).isAfter(moment(acc)) ? cur.date : acc;
    }

    return acc;
  }, '');

  if (latestDateHasData) {
    return listOfDays(daysAgo, groupBy, [], moment(latestDateHasData).format());
  }

  return listOfDays(daysAgo, groupBy);
}
